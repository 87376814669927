/* legacy WP classes
=====================
*/
.field-name-body{
  img{
    max-width: 100%;
    height: auto;
  }
}
hr{
  clear: both;
  margin: 2em 0;
}
img.aligncenter{
  clear: both;
  float: none;
  max-width: 100%;
  height: auto;
  margin: 1em 0;
}
.LinkBoxFstopAnswers {
  background-color: #6c6c6c;
  padding-right: 5px;
  padding-left: 5px;
  border-style: none;
}
.AboutBox {
  background-color: #EEE;
  padding: 5px;
  text-align: center;
  border-style: none;
  color: #000;
  font-weight: bold;
}
.CommentBoxFstopAnswers {
  padding: 5px;
  border-style: none;
  color: #000;
  background-color: #FFF;
}
.alignleft{
  margin:4px 20px 10px 0 !important;
  display:inline-block !important;
  float:left !important;
}
.content .right{
padding:0 0 3px 20px;
float: right;
}
.content .left{
padding:0 20px 3px 0;
float: left;
}

.content .left img,
.content .right img{
display: block;
}
/*blockquote {
	background: #F9FAFB;
	padding:15px;
	margin:10px 0 0 25px;
	color: #0F1419;
	font-weight: normal;
	font-style: normal;
	text-align: left;
	/ * makes block not to align behind the image if they are in the same line * /
	overflow: auto;
	clear:both;
}
*/
.FStoppAnswers {
	display:table;
	width:100%;
	background-color:#eee;
	border:1px solid  #666666;
	border-spacing:5px;/*cellspacing:poor IE support for  this*/
   /* border-collapse:separate;*/
}

.FStoppAnswersRow1 {
   display: table-row;
}

.FStoppAnswersRow2 {
   display: table-row;
   width: 710;
	   background-color:#ccc;
}
.caption{
  font-size: 11px;
  text-align: center;
  line-height: normal;
  img{
    padding-bottom: 7px;
  }

}
.alignnone,
.clearfix.caption,
.alignnone.size-full{
  display: block;
  float: none;
  clear: both;
}

// small screens
@media only screen and (max-width: $mobile-max-width),
only screen and (max-device-width: $mobile-max-width) {
  .alignleft{
    float:none !important;
    margin: 1em auto !important;
    display: block !important;
  }

}
