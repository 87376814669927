@import "_config.sass";

/*   [data-freestar-ad] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  [data-freestar-ad*="__728x90"],
  [data-freestar-ad*="__970x90"] {
    height: 115px;
  }
  [data-freestar-ad*="__300x250"],
  [data-freestar-ad*="__970x250"] {
    height: 275px;
  }
  [data-freestar-ad*="__336x280"] {
    height: 305px;
  }
  [data-freestar-ad*="__160x600"],
  [data-freestar-ad*="__300x600"] {
    height: 625px;
  }
 */
/*
  body {
  --use: 20px;
  --doNotUse: 0px;
  --fsAncillary: var(--doNotUse);
}
[data-freestar-ad] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  position: relative;
}
[data-freestar-ad*="__fsAncillary"] {
  --fsAncillary: var(--use);
}
[data-freestar-ad*="__728x90"],
[data-freestar-ad*="__970x90"] {
  --height: 90px;
  height: 90px;
  height: calc(var(--height) + var(--fsAncillary)) !important;
}

[data-freestar-ad*="__300x250"],
[data-freestar-ad*="__970x250"] {
  --height: 250px;
  height: 250px;
  height: calc(var(--height) + var(--fsAncillary)) !important;
}

[data-freestar-ad*="__336x280"] {
  --height: 280px;
  height: 280px;
  height: calc(var(--height) + var(--fsAncillary)) !important;
}

[data-freestar-ad*="__160x600"],
[data-freestar-ad*="__300x600"] {
  --height: 600px;
  height: 600px;
  height: calc(var(--height) + var(--fsAncillary)) !important;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {
  [data-freestar-ad*="__300x50"],
  [data-freestar-ad*="__320x50"] {
    --height: 50px;
    height: 50px;
    height: calc(var(--height) + var(--fsAncillary)) !important;
  }

  [data-freestar-ad*="__468x60"] {
    --height: 60px;
    height: 60px;
    height: calc(var(--height) + var(--fsAncillary)) !important;
  }

  [data-freestar-ad*="__300x100"],
  [data-freestar-ad*="__320x100"] {
    --height: 100px;
    height: 100px;
    height: calc(var(--height) + var(--fsAncillary)) !important;
  }

  [data-freestar-ad*="__300x250"] {
    --height: 250px;
    height: 250px;
    height: calc(var(--height) + var(--fsAncillary)) !important;
  }

  [data-freestar-ad*="__336x280"] {
    --height: 280px;
    height: 280px;
    height: calc(var(--height) + var(--fsAncillary)) !important;
  }
}
 */

.ad-unit-wrapper {
  float: none;
  text-align: center;
  max-width: 100%;

  iframe {
    vertical-align: middle;
  }
}

.adunit {
  width: 100%;
  overflow: hidden;
  //background-color: #ff0000;
}

#banner {
  .inner {
    position: relative;
  }

  .block {
    //margin: 0;
    margin: 1em auto !important;
  }

  .ad-unit-wrapper {
    border-bottom: solid 1px #ddd;
    padding-bottom: 1em;
  }
}

#pre-footer {
  background: #eee; //$ui-light;
  .inner {
    //background: $ui-light;
    position: relative;
  }

  //padding: 1em 0;
  .block {
    margin: 1.5em auto !important;
  }

}

#block-fs_helpers-freestar_ATF,
#block-fs_helpers-freestar_BTF {
  width: 100%;
  margin: auto;
  float: none;
  height: 115px; //overflow: hidden;
}

.in_content_ad_wrapper {
  background-color: #f7f7f7;
  padding: 1em;
  margin: 1em 0 0.5em 0;
  clear: both;

  p {
    margin: 0;
    padding: 0;
  }
}

/*#pre-header{
background: $light-black;
}*/

.ad-unit-wrapper {
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  vertical-align: middle;
  /* align-content: space-between; */
  justify-content: space-between;
  width: 100%;
}

/* #block-fs_helpers-freestar_SB2 {
  .adunit {
    height: auto;
  }
} */
.incontent-ad,
#block-fs_helpers-freestar_InContent,
#block-fs_helpers-freestar_Main_1,
#block-fs_helpers-freestar_Main_2,
#block-fs_helpers-freestar_Main_3,
#block-fs_helpers-freestar_Main_4 {
  background-color: #f7f7f7;
  padding: 5px;
  margin-top: 5px; //padding: 2.5em 0 2em 0;

  // Freestars CLS fix is wonky and still shifts sometimes
  // so im putting an absolute height on our own wrapper and hiding the
  // overflow.
  height: 326px;
  overflow: hidden;

  &:before {
    color: #bebebe;
    content: "ADVERTISEMENT";
    display: block;
    font-size: 10px;
    line-height: normal;
    padding-bottom: 5px;
    text-align: center;
  }



  //border-top: solid 1px #eee;

}

.incontent-ad,
#block-fs_helpers-freestar_InContent {
  // float: right;
  // margin-left: 2em;
  // margin-bottom: 1em;
  // margin-top: 10px;
  background-color: #f7f7f7;
  padding: 5px;
  margin: 20px 0;

  // Freestars CLS fix is wonky and still shifts sometimes
  // so im putting an absolute height on our own wrapper and hiding the
  // overflow.
  height: 335px;
  width: 100%;
  float: none;

  .adunit {
    text-align: center;
    flex: 1;
    //width: 100%;
    //overflow: hidden;
  }
}

.incontent-ad-desktop {
  //float: right;
  //max-width: 350px;
  //margin-left: 30px;
}


// Freestars CLS fix is wonky and still shifts sometimes
// so im putting an absolute height on our own wrapper and hiding the
// overflow.
#block-fs_helpers-freestar_SB1 {
  .adunit {
    height: 620px;
    overflow: hidden;
  }
}

#block-fs_helpers-freestar_SB2 {
  .adunit {
    display: block;
  }
}


.node.full .node-content .__fs-ancillary img {
  width: unset !important;
  height: inherit !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  /*     [data-freestar-ad*="__300x50"],
      [data-freestar-ad*="__320x50"] {
        height: 50px;
      }
      [data-freestar-ad*="__468x60"] {
        height: 60px;
      }
      [data-freestar-ad*="__300x100"],
      [data-freestar-ad*="__320x100"] {
        height: 100px;
      }
      [data-freestar-ad*="__300x250"] {
        height: 250px;
      }
      [data-freestar-ad*="__336x280"] {
        height: 280px;
      } */
  .article-index .ad-unit-wrapper {
    margin: 0 -5px;
    height: 345px;
    display: block;
    width: auto;
    max-width: none;
  }
  #block-fs_helpers-freestar_SB1 {
    display: none;
  }
  .region-banner {
    padding: 0 5px;
  }
  #banner {
    display: none;
  }
  #block-fs_helpers-freestar_InContent,
  #block-fs_helpers-freestar_Main_1,
  #block-fs_helpers-freestar_Main_2,
  #block-fs_helpers-freestar_Main_3,
  #block-fs_helpers-freestar_Main_4 {
    /* .adunit {
      margin-top: 10px;
      min-height: 300px;
    } */
  }
  #block-fs_helpers-freestar_ATF,
  #block-fs_helpers-freestar_BTF {
    display: none;
  }

  #banner #block-fs_helpers-freestar_ATF {
    width: auto; //height: auto;
    min-height: auto;
  }
  /* #block-fs_helpers-freestar_InContent {
    margin: 0;
    width: 100%;
    //display: block;
    float: none;
    clear: both;

  } */
}
