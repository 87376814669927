// Custom
// ------
// This file is used to define custom overrides and
// style changes to be used for building your theme.
// If you wish to use the SCSS syntax, please use this.
// Documentation: http://sass-lang.com/docs/yardoc/_index.html
// Import variables and mixins to be used.
@import "_config.sass"; //@import "_layout2.scss";
html {
  scroll-behavior: smooth;
  scroll-padding-top: 90px;
}

//
body {
  margin: 0;
  font: 16px/1.5em $default-family;
  letter-spacing: normal;
  color: $off-black;
  //background-color: #000; ///$ui-dark;
}


body.menu-blocked #menu-background {
  position: fixed;
  top: 0;
  bottom: 0;
  background: #000;
  width: 100%;
  z-index: 70;
  opacity: 0.7; //@include transition(opacity 0.5s ease-in-out);
}


// What is this?
:target:before {
  content: "";
  display: block;
  height: 90px;
  margin: -90px 0 0;
}

/* [id]::before{
  content: "";
  display: block;
  height: 200px;
  margin: -200px 0 0;
} */

#menu-background {
  display: block;
  opacity: 0;
  @include transition(opacity 0.5s ease-out);
}

img {
  vertical-align: middle;
}


html.freezeScroll body,
html.freezeScroll {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
}

#hero {
  position: relative;
  z-index: 10;
}

#page {
  background-color: #fff;
}

.fa-6x {
  font-size: 6em;
}

.xwide #main {
  max-width: 1550px !important;
}

div.tabs {
  position: relative;
  z-index: 30;
}

#main div.tabs {
  position: initial;
}

//a:focus,
a:active {
  outline: none;
  outline-style: none;
}


li p:last-child {
  padding: 0;
  margin: 0;
}

.content {
  .intro {
    font-size: 16px;
    line-height: 1.3em;
    padding: 1em 0;
  }

  .media-element-container {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

div.contextual-links-wrapper {
  a.contextual-links-trigger {
    height: 19px;
  }

  ul.contextual-links {
    font-size: 13px;
    text-align: left;
    box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.3);

    li {
      margin: 0 !important;

      a {
        padding: 5px 10px;
        margin: 0;
      }
    }
  }
}

.user-picture img {
  //@include border-radius(3px);
  width: 100%;
  height: auto;
  overflow: hidden;
  vertical-align: middle;
  @include border-radius(100%);
  background-color: #eee;
}

.fs-oembed-vimeo,
.media-vimeo-video {
  overflow: hidden;
  display: none;
}

#noty_topCenter_layout_container,
#noty_bottomLeft_layout_container {
  li {
    background-image: none !important;
    animation-duration: 0.3s;
    -moz-animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
  }
}

ul#noty_topCenter_layout_container {
  width: 100% !important;
  max-width: 400px;

  li {
    width: 100% !important;
    max-width: 400px;
    border-radius: 0 !important;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    overflow: visible !important;
  }
}

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}


svg.fa-icon {
  width: 1em;
  height: 1em;
  vertical-align: middle; //-.125em;
  &.fa-lg {
    width: 1.25em;
    height: 1.25em;
  }

  &.fa-2x {
    width: 2em;
    height: 2em;
  }

  &.fa-3x {
    width: 3em;
    height: 3em;
  }
}

@keyframes fadeInOut {
  0% {
    //opacity: 0;
    background-color: #fff;
  }
  16% {
    //opacity: 1;
    background-color: $pale-blue;
  }
  84% {
    //opacity: 1;
    background-color: $pale-blue;
  }
  100% {
    //opacity: 0;
    background-color: #fff;
  }
}

.comment.hashTarget {
  //border-left: solid 2px $bright-blue !important;
  background-color: $pale-blue; //#fff;
  //margin-left: -2px; //background-color: $pale-blue; //#ffa;
  //-webkit-transition: all 1s linear;
  /*  -webkit-animation: fadeInOut 6s;
   animation: fadeInOut 6s; */
}

.admin-modal-rating {
  //display: none;
}

.notyMsgTrigger {
  display: none !important;
}

.noty-msg-wrapper {
  text-align: left;
  /*     max-width: 700px;
    width: 100%; */
  .noty-icon {
    font-size: 1.4em;
    float: left; //color: $bright-blue;
  }

  .has-icon {
    margin-left: 25px;
  }
}

picture img {
  //width: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}

.noty_bar {
  border-radius: 4px;
  border: solid 1px #eee;
  @include box-shadow(0, 1px, 0px, 0px, rgba(0, 0, 0, 0.2));
}

.noty_type_info,
.noty_type_alert {
  background-color: $pale-blue;
  border-color: $bright-blue;
}

.noty_type_success {
  background-color: #dff0d8;
  border-color: #99c376;
  color: #468847;
}

.noty_type_warning {
  color: #884400;
  background: #ffefb1;
  border-color: #efe2a1;
}

.noty_type_error {
  background-color: #f2dede;
  border-color: #ef9eaa;
  color: #b94a48;
}

.no-scroll {
  overflow: hidden !important;
}


// wysiwyg editor style
body.cke_ltr,
body.cke_panel_frame,
body.cke_editable {
  background-color: #fff;
  padding: 1em;
  font-size: 0.9em;

  img.wysiwyg-break {
    height: 12px !important;
  }

  ol,
  ul {
    margin: 0 2em;
    padding-bottom: 1em;
    padding-top: 0.3em;

    li {
      margin: 0 0 0.3em 0.5em;
    }
  }

  ul.cke_panel_list {
    margin: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
    clear: both;
  }

  img.align-right {
    float: right;
    clear: none;
    margin: inherit;
    display: inline;
  }

  img.align-left {
    float: left;
    clear: none;
    margin: inherit;
    display: inline;
  }
}

.more-open {
  max-height: 9999px !important;
  @include transition(max-height .70s ease-in-out);
  overflow: visible;
}

.more-hidden {
  overflow: hidden;
}

::-webkit-input-placeholder {
  font-style: italic;
  color: #888;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: #888;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #888;
}

:-ms-input-placeholder {
  font-style: italic;
  color: #888;
}

a:link,
a {
  text-decoration: underline;
  color: $bright-blue;
}

a:visited,
a:hover {
  color: $darker-blue;
}

/* reset default drupal list images/icons */

ul li.collapsed,
ul li.leaf {
  list-style-image: none;
  list-style-type: none;
}

/* tabs are acting funky */

ul.primary li a .tab {
  height: inherit;
}

ul.primary li {
  margin-top: 1em;
}

.breadcrumb {
  //margin-left: $padding/2;
  padding: 1em 0;
  font-size: 14px;
  color: #aaa;
  line-height: 1.5em;
  overflow: auto;
  white-space: nowrap;

  a {
    color: #888;

    &:hover {
      color: $off-black;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: $header-spacing;
  line-height: 1.2em;
  font-family: $header-family;
  font-weight: bold; //800; //-webkit-font-smoothing: antialiased;
}

h1.title {
  font-weight: bold; //900;
  //font-size: 3.3em;
  margin: 0;
  padding: 0; //0.25em calc($padding/2);
  //padding: 0; //0.25em calc($padding/2);
  //padding-left: 10px;
  //padding-right: 10px;
}

.node-type-article {
  h1.title {
    font-size: 2.8em;
    line-height: 1.15em;
  }
}

mark.new {
  background: none repeat scroll 0 0 #FF0000;
  color: #FFFFFF;
  font-size: 10px;
  padding: 1px 3px;
  text-transform: uppercase;
  @include border-radius(2px);
}

/*#content-header h1.title {
  padding-left: 0;
  padding-right: 0;
}*/

.section-title {
  font-size: 16px;
  font-family: $header-family;
  font-weight: 600; //border-bottom: solid 1px #eee;
  line-height: normal; //margin-bottom: 0.5em;
  letter-spacing: $header-spacing;
  color: $bright-blue;
  text-transform: uppercase;
  padding-top: 10px;
}

.no-header {
  .logo {
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
    padding: 2em 3em 0 3em;

    img {
      width: 100%;
      max-width: 100%;
    }
  }
}


.socicons {
  .icon-wrapper {

    //    font-size: 10px;
    /*    width: 1.5em;
        height: 1.5em;*/

    a {
      background: rgba(255, 255, 255, .2);
      border-radius: 100px;
      margin: 0;
      display: block;
      align-content: center;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      padding: 0.25em;
      width: 1.7em;
      height: 1.7em;
      font-size: 2em;
      text-align: center;
      color: #ccc;

      svg {
        fill: #ccc;
      }

      &:hover {
        background: #fff;
        color: #000;

        svg {
          fill: #000;
        }
      }
    }

  }
}

#header,
#footer {
  .socicons {
    float: none;

    .hidden {
      display: none;
      text-indent: -9999;
    }

    .icon-wrapper {
      //      font-size: 34px; //float: left;
      //      padding: 1px;
      display: inline-block;
      padding: 0.1em;

      a {
        background-color: #ddd;
        color: #999;

        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
  }
}


nav div.inner,
header div.inner,
#footer div.inner {
  padding: 0 10px;
}

#main {
  position: relative;
  padding: $padding $padding calc($padding * 2) $padding;
  //z-index: 100;
}

.sidebar-second {
  #content {
    //padding-right: $padding*2;
  }

  .center {
    padding-right: calc($padding * 2);
  }
}

#content-header,
#content-area {
  position: relative;
  padding-top: calc($padding / 2);
}

#content-area {
  .content {
    ///color: #666;
    position: relative;
  }

  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $off-black;
  }
}

#sidebar-first {
  .block-title {
    font-size: 1.2em;
    margin: 0 0 0.3em 0;
    text-transform: uppercase;
    color: $dark-grey;
    border-bottom: solid 1px $light-grey;
  }
}

#content {
  min-height: 300px;
}


.has-breadcrumb #main {
  padding-top: 0;
}

.notSticky {
  position: relative;
  top: 0;
}

.image-widget {
  .image-preview {
    float: left;
    width: 100%; //clear: block;
    padding: 5px;

    img {
      width: 100%;
      height: auto;
    }
  }
}


.original-article {
  width: 105px;
  display: block;
  float: right;
  height: auto;
  position: absolute;
  right: 0;
  bottom: 0;

  svg {
    vertical-align: middle;
  }
}

.vertical-tabs {

}

div.vertical-tabs {
  margin: 4em 0 1em 15em;
  border: 1px solid #ccc;
  position: relative;
  clear: both;
  display: block;
  font-size: 14px;
  line-height: 1.5em;

  ul.vertical-tabs-list {
    line-height: 1.2em;
  }

  .vertical-tabs-pane {
    font-size: 16px;

    .fieldset-wrapper {
      padding-top: 1em;
    }

    fieldset {
      width: 100%;
    }
  }
}

.vertical-tabs-wrapper {
  clear: both;
  display: block;
  float: left;
  width: 100%;
  margin: 0em 0 2em 0;
}

/* throbbers
=======================
*/

.ajax-progress .throbber {
  background: transparent url(../images/throbber.gif) no-repeat 0px -18px;
  float: left;
  /* LTR */
  height: 15px;
  margin: 2px;
  width: 15px;
}

.submit-loading,
.checkout-processing,
.flag-waiting .flag-throbber {
  background: transparent url(../images/throbber.gif) no-repeat 1px -19px !important;
  padding-right: 13px;
}

.loading,
.submit-loading {
  //display: block;
  padding-left: 20px;
  background-position: 1px 1px;
  padding-right: 0; //float: right;
  clear: both;
  margin: 0.7em 0;
  color: #666;
  background: transparent url(../images/throbber.gif) no-repeat 1px -19px !important;
}

.flag-wrapper.flag-waiting {
  .flag-throbber {
    margin-left: 5px;
  }
}

.flag-waiting a {
  /* Give an impression of a disabled link. */
  opacity: 0.5;
  filter: alpha(opacity=50);
  /* IE */
}

html.js input.form-autocomplete {
  background-image: url(../images/throbber3.gif);
  background-position: 100% 12px;
}

html.js input.throbbing {
  background-position: 100% -18px;
}

.quicktabs-tabs .ajax-progress {
  display: none;
}

.quicktabs-wrapper .qtloader {
  font-size: 3em;
  text-align: center;
  padding: 0 0;
  color: #ccc;
  width: 100%;
  display: inline-block;

  div {
    border-color: #ccc transparent #ccc transparent !important;
  }
}

.quicktabs-loading .quicktabs_main {
  height: 30px;
  margin-top: 20px;
  background: url(../images/loading-medium.gif);
  background-repeat: no-repeat;
  background-position: center center;
}


/* header
 ========================
*/

.autocomplete-suggestions {
  border: 1px solid #ccc;
  background: #FFF;
  overflow: auto;
  line-height: 24px;
  font-size: 14px;
  border-top: none;
  @include box-shadow(0, 1px, 0px, 0px, rgba(0, 0, 0, 0.2));
  @include border-radius(0, 0, 5px, 5px);

  i {
    color: #ccc;
  }

  .autocomplete-suggestion {
    padding: 5px 5px;
    white-space: nowrap;
    overflow: hidden;
    color: $bright-blue;
    cursor: pointer;
    border-bottom: solid 1px #F0F0F0;
  }

  .autocomplete-suggestion:last-child {
    border-bottom: none;
  }
}

.autocomplete-selected {
  background: #F0F0F0;
}

.autocomplete-suggestions strong {
  font-weight: normal;
  color: #3399FF;
}

.autocomplete-group {
  padding: 2px 5px;
}

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}

.comments-more-comments {
  .pagination {
    display: none;
  }
}


/* Footer
======================
*/

#page {
  padding-bottom: 9em;
}

#footer {
  overflow: hidden; //background-color: $dark-grey;
  //background-color: $ui-dark;
  background-color: #eee;
  border-top: solid 1px #ddd;
  padding-bottom: 100px;

  div.inner {
    min-height: 300px;
    background-color: #eee; //background-color: $ui-dark;
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  #block-fs_helpers-footer {
    padding: 2em 0;
    text-align: center;
  }

  .copyright {
    color: #666; //$light-grey;
    font-weight: bold;
    margin-top: 20px; // padding-left: 1em;
    text-align: center;
  }

  .social div {
    //text-align: left;
    margin: 0 0 0 5px;
    /*a{
          width: 30px;
        }*/
  }

  .footer-col {
    //float: left;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    padding-bottom: 2em;
    font-size: 14px;
    line-height: 1.5em;
    margin-right: 1.5em; //border-left: solid 1px #66737c;
    //padding-left: 1em;
    &.last {
      //float: right;
      border: none; //text-align: right;
      color: $dark-grey;
      padding-left: 1em;
      margin: 0;
      width: 100%;
      text-align: center;
      font-size: 12px;
    }

    .follow {
      //margin-bottom: 20px;
      // max-width:180px;
      margin-top: 2em;
    }

    ul.menu {
      padding: 0;
      margin: 0;
      float: left;
      margin-right: 2em;
    }

    li {
      padding: 0;
      margin: 0;
    }

    /* h4 {
        color: $off-black;
        font-size: 17px;
        text-transform: uppercase;
        margin-bottom: 3px;
      } */
    a {
      //color: #b8bfc5
      color: #666; //ccc;
      &:hover {
        color: $off-black;
      }
    }
  }
}

.footer-heading {
  color: $off-black;
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 3px;
  font-weight: 800;
  font-family: $header-family;
}

/*#pre-footer{
  background: #6e7880;
}*/

/* Alert messages
=================================
*/

div.alert {
  clear: both;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0;
    padding: 0 0 3px 0;
  }
}

/* Console, style from slate theme. */

div.messages {
  padding: 15px 20px;
  margin: 1em 0;
  color: #003366;
  background: #bbddff;
  border: 1px solid #aaccee;
  @include border-radius(3px);
  @include text-shadow(0 1px 0 rgba(255, 255, 255, 0.5));
  font-size: 16px;
  clear: both;
  line-height: 1.35em;
  text-align: left;

  ul {
    margin: 0 !important;
    padding: 0 !important;
  }

  li {
    list-style: none;
    padding: 2px 4px !important;
  }

  p {
    margin-bottom: 0.4em;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

//a.cancel-block,
a.cancel-message {
  float: right;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

/*a.cancel-block {
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
}*/

div.warning {
  color: #884400;
  background: #ffefb1;
  border-color: #efe2a1;

  a.cancel-message {
    color: #884400;
  }
}

div.error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48 !important;
  background-image: none;

  a.cancel-message {
    color: #b94a48 !important;
  }
}

div.messages.error {
  background-color: #f2dede !important;
}

div.status {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}

div.clientside-error {
  margin: 0.5em 0;

  label {
    color: inherit !important;
    font-weight: normal;
    width: auto;
    float: none;
  }
}

/*
==================
form fields
==================
*/


form a.button {
  font-family: $header-family;
  font-weight: 800;
  font-size: 25px;
  line-height: 3em;
  padding: 0 2em;
  margin: 1em;
}

ul.token-input-list-fstoppers.token-input-focused-fstoppers,
input.form-text:focus,
input.form-text:active,
input[type="text"]:focus,
input[type="text"]:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  /*@include shadow(0, 0, 3px, 0, $accent-color);*/
  background-color: $pale-blue;
  z-index: 1;
  /* For Opera */
  border: solid 1px $bright-blue !important;
  outline: none;
  box-shadow: inherit;
}

.image-widget {
  input:focus,
  input:active {
    border: none;
    background: none;
  }
}

.profile-link a,
a.button,
button,
input[type="reset"],
input[type="submit"],
input[type="button"] {
  -webkit-appearance: none;
  @include border-radius(2px);
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  background: $light-grey;
  border: none;
  font-weight: bold;
  /*  border-color: #ddd #bbb #999;
*/
  cursor: pointer;
  color: #fff; //font: normal 15px/2.3em $default-family;
  font-family: $default-family;
  font-size: 15px;
  font-weight: normal;
  outline: 0;
  overflow: visible;
  padding: 0 0.8em;
  text-shadow: none;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  background: $bright-blue;
  height: auto; //2em;
  line-height: 2em;
  *padding-top: 2px;
  /* IE7 */
  *padding-bottom: 0px;
  /* IE7 */
  &.progress,
  &.disabled,
  &:disabled {
    background: $medium-grey !important;
    color: $dark-grey !important;
    cursor: default !important;
    border-color: $medium-grey !important;
    opacity: 0.6;
  }

  &:focus {
    //border: none;
    outline: none;
  }
}

.profile-link a:hover,
a.button:hover,
button.button:hover,
button:hover,
input.form-submit:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
  background: $darker-blue;
  color: #fff !important;
}


textarea.disabled,
textarea:disabled,
select.progress-disabled,
input[type="text"].disabled,
input[type="text"]:disabled {
  background-color: #eee;
  font-style: italic;
  color: #999;
  pointer-events: none;
}

.profile-link a,
a.button {
  text-decoration: none;
  margin: 0 3px;
  display: inline-block;
}

button:disabled,
input:disabled,
.disabled {
  pointer-events: none;
}

.profile-link a {
  font-size: 11px;
  margin: 3px 0;
  line-height: 2em;
  height: 2em; // padding: 3px 5px;
}

input.field-add-more-submit {
  font-size: 11px !important;
}

.btn,
.button {
  &.green {
    background-color: $green !important;

    &:hover {
      background-color: $dark-green !important;
    }
  }

  &.grey {
    background-color: $grey;
    color: $dark-grey;

    &:hover {
      background-color: $dark-grey;
      color: #fff;
    }
  }
}

input.white,
.button.white {
  font-family: $header-family;
  background-color: #fff;
  border: solid 1px $bright-blue;
  color: $bright-blue;

  &:hover {
    background-color: $bright-blue;
    color: #fff;
  }
}

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  //padding: 0.5em;
  height: auto;
  font-family: $default-family;
}

ul.token-input-list-fstoppers,
input.form-text,
select,
textarea {
  padding: 0.7em;
  border-radius: 2px;
  box-shadow: inset 0 1px 5px -3px #888;
  background-color: #ffffff;
  border: solid 1px #ccc;
  max-width: 100%;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: 0 1px 5px -3px #888;
  background: #fff;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.9 195.74"><path d="M328.03 8.41c-5.26-5.3-12.41-8.28-19.88-8.28s-14.62 2.98-19.88 8.28L168.15 127.97 47.76 8.13C36.78-2.77 19.04-2.71 8.14 8.27c-10.9 10.98-10.84 28.72.14 39.62l140 140.01a27.99 27.99 0 0 0 19.88 7.84c7.34-.03 14.38-2.95 19.6-8.12l140-140.01c10.75-10.81 10.88-28.23.28-39.2Z" style="fill-rule:evenodd"/></svg>') no-repeat right 0.8em center / 0.85em, linear-gradient(0deg, rgba(247, 247, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }
}

select:not([multiple]) {
  padding-right: 2.2em;
}

select[multiple] {
  height: auto;
  box-shadow: inset 0 1px 5px -3px #888;
  background: #fff;

}


.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: solid 1px #ff0000;
}


// Keywords tags fields.

ul.token-input-list-fstoppers {
  padding: 0;
  font-size: 1em;
  line-height: 1.15em;
  min-height: 1.15em;
  width: auto;

  li {
    margin: 5px;

    > * {
      vertical-align: middle;
      display: inline-block;
    }

    p {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    input {
      font-size: 1em;
      line-height: 1.15em;
      min-height: 1.15em;
      margin: 0;
      padding: 0.5em 0.35em 0.5em 0.75em;
      background-color: transparent;
      border: none !important;

      &:focus {
        border: none !important;
      }
    }
  }

  &.error {
    border: solid 1px #ff0000;
  }
}

li.token-input-input-token-fstoppers {
  border: solid 1px transparent;
  padding: 0;
  margin: 0;
}

li.token-input-token-fstoppers {
  font-size: 1em; //border-color: $bright-blue;
  //background-color: $pale-blue;
  border: solid 1px #ccc;
  @include gradient(#eee, #FFF); //@include box-shadow(0, 1px, 1px, 0, rgba(0,0,0,0.1));
  border-radius: 3px;
  //line-height: 25px;
  padding: 0.5em 0.35em 0.5em 0.75em;

  span {
    padding: 0;
    //font-size: 1.8em;
    vertical-align: middle;
    margin: 0 0 0 0.25em;
    color: #999;
    font-size: 1.5em;

    &:hover {
      color: $delete-red;
    }
  }
}

li.token-input-selected-token-fstoppers {
  color: #000;
  //font-weight: bold;
  @include gradient(#ccc, #ddd);
  border: solid 1px #aaa;
}


div.token-input-dropdown-fstoppers {
  ul {
    li.token-input-selected-dropdown-item-fstoppers {
      background-color: $bright-blue;
    }
  }
}


.field-type-addressfield label {
  text-transform: capitalize;
}

div.addressfield-container-inline > div.form-item {
  width: auto;
}

div.addressfield-container-inline.name-block {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1rem;
  width: 100%;

  &:after {
    display: none;
  }

  div.form-item {
    margin: 0;
    /*float: left;
    margin-right: 0;
    width: 50%;
    padding-right: 5px;

    &:last-child {
      padding-right: 0;
      padding-left: 5px;
    }*/
  }
}

/* tables
-------------------------------
*/

table {
  //border: solid 1px #ddd;
  width: 100%;
  background: none;
  font-size: 14px;
  margin: 1em 0;
}

thead th,
th {
  background: #f7f7f7;
  color: #555;
  font-size: 12px;
  font-weight: bold;
  padding: 0.8em 0.5em;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

thead th a:visited,
th a:visited,
thead th a:link,
th a:link {
  color: #888;
  text-decoration: none;
  text-shadow: 0 1px 0px #fff;
}

tr.odd,
tr.even {
  background: none;
  border-bottom: 1px dashed #ddd;
}

tr.odd td,
tr.even td {
  background: none repeat scroll 0 0 transparent;
  color: #666666;
  line-height: normal;
  padding: 0.6em 0.5em;
  vertical-align: middle;
}

tr.odd td.active,
tr.even td.active {
  color: #333;
}

tr:hover td,
tr.even:hover td.active,
tr.odd:hover td.active {
  background: #eee;
  color: #000;
}

tr.odd td.active,
tr.even td.active {
  background: none repeat scroll 0 0 #f4f4f4;
}

td.active {
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

th.active {
  background: none repeat scroll 0 0 #eeeeee;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

/* node form
=======================
*/

#edit-delete {
  background-color: $delete-red;
  color: #fff;
}

.cancel-account {
  float: right;
}

.form-actions,
.edit-actions,
#edit-actions {
  text-align: center;
  clear: both;
  padding: 1em 0 3em 0;
  margin-top: 1em;

  input.form-submit {
    @include green-button();
    font-size: 18px;
    padding: 0 1.4em;
    font-family: $header-family;
    letter-spacing: normal;
    display: inline-block;
    line-height: 2.4em;
    height: auto;
    font-weight: 800;
    min-width: 100px;
    text-transform: uppercase;

    &.cancel {
      @include grey-button(18px, 2.4em);

      &:hover {
        color: #333 !important;
      }
    }
  }

  input#edit-cancel {
    float: right;
    @include red-button();
    font-size: 1em !important;
    line-height: 2em;
    margin-top: 1em;
    min-width: initial;
  }

}


.form-textarea-wrapper {
  .counter {
    margin: 3px 0;
    font-size: 11px;

    &.messages.error {
      border: none;
      background: none;
    }
  }
}

#edit-field-photo {
  //margin-right: 48%;

  .manualcrop-style-button-holder {
    //padding: 10px;
  }
}


/*
User edit/profile forms
========================================
*/

#edit-profile-picture-field-user-avatar-und-0-upload-button {
  display: none !important;
}

#modal-form-wrapper {
  label {
    display: block;
    float: none;

    &.option {
      float: none;
      display: inline;
    }
  }

  h3.form-title {
    border-bottom: solid 1px #eee;
    padding-bottom: 5px;
    font-size: 20px;
    margin-bottom: 10px;
    color: $bright-blue;
  }
}

.form-type-checkbox {
  position: relative;
}

.instruction-wrapper {
  font-size: 14px;
  line-height: 1.3em;
}

/*
.page-user-ban {
  .form-item {
    margin-bottom: 1em; //border: none;
    label {
      display: block;
      float: none;
      width: auto;
      font-size: 14px;
      color: #000 !important;
      padding-bottom: 3px;
      &.option {
        font-size: 14px;
        display: inline;
        .description {
          display: block;
          font-size: 12px;
          font-style: normal;
          margin-left: 1.3em;
        }
      }
    }
    .form-radios,
    .form-checkboxes,
    .form-textarea-wrapper,
    .description {
      margin: 0;
    }
    .form-type-radio {
      padding: 3px 0;
    }
    .form-textarea-wrapper,
    .form-radios,
    .form-checkboxes {
      margin: 0; //0.5em 0;
    }
    .description {
      font-size: 11px;
      padding: 5px 0;
    }
    .item-list {
      margin: 1em 0 0 0;
      font-size: 14px !important;
      color: #000;
    }
  }
  #edit-submit {
    margin: 2em 0;
  }
}
*/


#block-views-comment_teaser-block {
  .indented {
    margin: 0;
  }

  .views-row {
    border: solid 1px #eee;
    margin-bottom: 1em;
    padding: 1em;
  }

  .comment {
    padding: 0;
    margin: 0;
  }
}

#flag-confirm {
  .messages {
    margin: 1em 0;
  }
}

.user-picture-wrapper {
  position: relative;

  a.edit-link {
    display: none;
    position: absolute;
    top: 5px;
    left: 5px;
    background: #fff;
    text-decoration: none;
    font-size: 11px;
    padding: 0 5px;
    line-height: 20px;
    @include border-radius(2px);
    color: #888;
    border: solid 1px #ddd;

    &:hover {
      color: #000;
      border-color: #fff;
      background-color: #fff;
    }
  }

  &:hover a.edit-link {
    display: block;
  }
}


/* Rate widget
==================
*/
div.links .rate-widget {
  margin-right: 5px;
}


.node,
.comment {
  .edited {
    //font-size: 11px;
    cursor: default; //padding-left: 1em;
    color: $medium-grey;
  }

  .response-author {
    color: #aaa;
    //padding-left: 0.4em;

    a {
      color: #aaa;
      text-decoration: none;
    }
  }

  .rate-widget.inprogress {
    opacity: 0.5;
  }

  div.rate-up-down {
    .rate-button,
    span.rate-button {
      float: none;
      padding: 0 5px 0 7px;
      line-height: 2em;
      font-size: 13px;
      vertical-align: middle;
      color: $dark-grey;
      // background-color: $light-grey;
      cursor: default;
      display: inline-block; // @include border-radius(2px);
      // border: solid 1px #eee;
      position: relative;
      @include border-radius(2px);

      &.rate-thumbs-up-down-btn-down {
        //padding-left: 5px;
        //margin-left: 2px;
      }

      &.rate-thumbs-up-down-btn-up {
        //padding-right: 5px;
        //margin-right: 3px;
      }

      &.no-votes {
        color: $grey;
      }
    }

    a.rate-button {
      //color: $medium-grey;
      //float: none;
      //padding: 2px;
      cursor: pointer;
      color: #666; //$bright-blue; //#fff;
      text-decoration: none;
      // background-color: #fff; //#7fcc7f;
      // border: solid 1px #ccc; //$bright-blue;
      //@include border-radius(2px);
      //position: relative;
      &:hover {
        background-color: $pale-blue; //$green;
        color: $bright-blue !important;
        //border: solid 1px $bright-blue;
      }
    }

    a.rate-voted {
      //border-color: $bright-blue;
      //background-color: $bright-blue !important; //$green
      color: $bright-blue;
    }

    li,
    ul {
      margin: 0 !important;
      padding: 0 !important;
      list-style: none;
      border: none !important;
    }

    ul {
      float: left; //background-color: #7fcc7f;
      overflow: hidden; //border: solid 1px #ddd;
      color: #fff;
    }

    ul li {
      //display: inline;
      float: none;
      display: inline-block;
      vertical-align: middle;
    }

    .count {
      line-height: 15px;
      display: inline-block;
      font-size: 11px;
      padding: 1px 2px;
    }

    li.thumb-up {
      .count {
        margin-right: 0;
        display: inline-block;
      }
    }

    li.thumb-down {
      .count {
        display: inline-block;
      }

      i {
        display: inline-block;
      }
    }
  }
}

.float-sharing {
  &.node {
    .liking {
      .form-item {
        margin: 0;
        float: none;
        width: 100%;

        div {
          width: 100%;
        }
      }

      div.rate-up-down {
        float: left;
        margin: 0 0 7px 0;
        padding: 0;
        text-align: center;

        .rate-button {
          margin: 0 !important;
        }

        ul {
          float: none;
          white-space: normal;
        }

        li {
          display: inline-block; //margin: 1px 0 !important;
          width: 37px;
          float: none;
          margin-bottom: 2px !important;

          span.rate-button,
          a.rate-button {
            width: 100%;
            padding: 5px 5px;
          }
        }

        .count,
        i {
          display: block !important;
          vertical-align: middle;
        }
      }
    }
  }
}

/* pagers
========================
*/

.pagination-centered {
  text-align: center;
}

.pagination {
  display: inline-block;
  width: 100%;

  .item-list {
    border: none !important;

    ul {
      @include border-radius(0);
      @include box-shadow(0, 2px, 2px, 0, rgba(0, 0, 0, 0.05));
      display: inline-block; //margin-bottom: 0;
      //margin-left: 0;
      margin: 1em 0; //font-size: 12px;
    }

    li {
      display: inline;
      margin: 0;
      padding: 0;
    }

    .pager li > a,
    .pager li > span {
      @include border-radius(0);
      border: solid 1px #ddd;
      line-height: 34px;
      float: left;
      padding: 0 14px;
      border-right-width: 0;
      text-decoration: none;
      background-color: #fff;
      color: $off-black;
    }

    .pager li.pager-current span,
    .pager li a:hover {
      background-color: $bright-blue;
      color: #fff;
      border-color: $bright-blue;
    }

    //.pager li:not([style*="display:none"]):not([style*="display: none"]):first-child a,
    //.pager li:not([style*="display:none"]):not([style*="display: none"]):first-child span,
    .pager li:first-child a,
    .pager li:first-child span {
      border-left-width: 1px;
      @include border-radius(3px, 0, 0, 3px);
    }

    //.pager li:not([style*="display:none"]):not([style*="display: none"]):last-child a,
    //.pager li:not([style*="display:none"]):not([style*="display: none"]):last-child span,
    .pager li:last-child a,
    .pager li:last-child span {
      border-right-width: 1px;
      @include border-radius(0, 3px, 3px, 0);
    }

    .pager .pager-current,
    .pager .pager-item {
      font-size: 14px;
    }

    .pager .progress-disabled,
    .pager .pager-disabled,
    .pager .disabled > a,
    .pager .disabled > span {
      color: #999999;
    }

    .pager .progress-disabled {
      background-color: #ddd;
      overflow: hidden;
    }

    .pager .next > a,
    .pager .next > span {
      float: left !important;
    }

    .pager li {
      &.pager-first,
      &.pager-last,
      &.pager-next,
      &.pager-previous {
        font-size: 14px;
      }
    }

    .pager .disabled > a,
    .pager .disabled > span {
      background: #eee;
    }

    .ajax-progress {
      display: none;
    }
  }
}


/* basic node stuff
============================
*/

.view-empty,
.views-empty {
  font-size: 1.5em;
  color: #666;
  text-align: center;
  padding: 2em;
  line-height: 1.3em;
  clear: both;

  a.button {
    font-family: $header-family;
    line-height: normal;
    font-size: .85em;
    padding: 0.8em 1.5em;
  }

  i.fa-exclamation-triangle {
    color: #eee; //@include text-shadow(0px -2px 0 rgba(0,0,0,0.3));
    margin-bottom: 20px;
  }

  i.empty-icon {
    color: #ddd;
  }
}

/* Don't show shadows when selecting text */

::-moz-selection {
  background: #5af;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #5af;
  color: #fff;
  text-shadow: none;
}

.submitted {
  font-size: 11px;
  color: #888;
  margin: 0 0 0.5em 0;
  padding: 0;
  line-height: normal;

  span.username {
    font-weight: bold;
    color: $off-black;
  }

  span.author {
    font-size: 12px;

    a {
      color: $bright-blue;
    }
  }

  .date {
    font-style: italic;
    margin-top: 3px;
  }

  a.content-permalink,
  a.comment-permalink {
    text-decoration: none;
    color: #888;
    font-weight: normal;

    &:hover {
      text-decoration: underline;
    }
  }

  .username {
    text-decoration: none;
    font-weight: bold;
    color: $off-black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 80%;
    vertical-align: middle;
  }

  .user-picture {
    width: 28px;
    height: auto;
    margin-right: 5px;
    float: left;

    img {
      //width: 100%;
      //height: auto;
      //vertical-align: middle;
      //@include border-radius(2px);
    }
  }
}


.full {
  .field-name-field-product-category {
    //float: left;
    color: $bright-blue;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 13px;
    font-size: 13px;
  }

  .liking {
    font-family: $header-family;
    font-weight: 800;
    float: none;
    margin-left: 0;
    padding-bottom: 0.6em;
    display: inline-block;
    width: 100%;
    font-size: 14px;

    .flag-wrapper {
      .flag-text {
        padding-right: 10px;
        display: none;
      }

      a {
        text-decoration: none;
        color: #666;
      }
    }

    .form-item {
      margin: 0 0.7em 0 0;
      float: left;
    }

    .item {
      float: left;
    }

    .rate-widget-2 {
      float: right;

      a {
        min-width: 90px;
      }
    }

    .rate-up-down {
      //line-height: 14px;
      font-size: 14px;
      font-weight: normal;

      .rate-button {
        padding: 0 10px;
        line-height: 34px;
      }

      .count {
        font-size: 13px;
        font-weight: normal;
      }
    }

    .rate-like {
      padding-top: 0;
      margin-top: 0;
    }

    .helpers-share {
      //font-weight: normal;
      .share-item a {
        padding: 0;
        display: inline-block;
        line-height: 2em;
        font-weight: normal;

        .icon {
          font-size: 2em;
          float: left;
          display: inline-block;
        }

        .count {
          font-weight: normal;
          padding-right: 5px;
        }
      }

      .facebook.share-item {
        .count {
          // margin-left: -5px;
        }
      }
    }
  }

  &.float-sharing {
    .liking {
      float: left !important;
      width: 40px;
      text-align: center;
      border: none !important;

      .icon {
        margin-right: 0 !important;
        width: 37px;
        text-align: center;
        line-height: 36px;
        padding: 0 5px;
      }

      .item {
        margin: 0 0 2px 0;
        padding: 0;
        border: none;
        width: 100%;
      }

      .flag-text {
        display: none;
      }

      .helpers-share {
        width: 100%;
        text-align: center;
        padding-top: 10px;
      }

      .share-item {
        display: block;
        margin: 0 0 2px 0;
      }

      /* .jssocials-share {
          display: block;
          margin: 0 0 2px 0;
        } */
    }

    .content-wrapper {
      margin-left: 60px;
      margin-right: 15px;
    }
  }

  header .submitted {
    font-size: 13px;
    position: relative;
    margin-bottom: 15px;

    .author-info {
      position: relative;

      .byline {
        font-size: 16px;
        display: block;
        border: none;
        padding: 0 0 5px 0;
      }

      .username {
        max-width: none;
        vertical-align: middle;
        color: #555;
      }

      > span {
        border-right: solid 1px #ccc;
        padding-right: 0.6em;
        padding-left: 0.6em;
        display: inline-block;
        vertical-align: middle;
        margin-top: 0;

        &:last-child {
          border: none;
        }

        &:first-child {
          padding-left: 0;
        }
      }

      .date {
        padding-left: 0;
      }
    }

    .comment-count {
      a {
        color: #888; //font-weight: normal;
        text-decoration: none;
      }
    }

    .sponsored {
      font-weight: bold; //font-size: 11px;
    }
  }

  .share {
    padding-top: 0.5em;
    border-top: solid 1px #eee;
    margin-top: 0.5em;
  }
}


.articleblock {
  clear: both;
  margin: 1em 0;
  padding: 1em;
  background-color: #eee;
  float: left;
  width: 100%;
}

.articlebox {
  clear: both;
  margin: 1.5em 0;
  padding: 0;
  float: left;
  width: 100%;
  border-bottom: solid 1px #eee;
}

.empty-comments,
.comments-forbidden {
  font-size: 1.3em;
  color: #666;
  padding: 1.5em;
  text-align: center;
  line-height: 1.3em;
}

.full {
  .content {
    .media-element.file-default {
      clear: both;
      padding: 0.5em 0;
    }
  }

  .media-youtube-outer-wrapper,
  .media-vimeo-outer-wrapper {
    clear: both;
    padding: 0.5em 0;
    width: auto !important;
    height: auto !important;
  }

  .field-name-field-lead-video {
    padding-bottom: 1em;
  }

  .tag-style {
    padding: 1em 0 0 0;
    margin: 1em 0;
    border-top: solid 1px #eee;
    clear: both;
    line-height: 30px;
    font-size: 13px;

    a {
      background-color: #EEEEEE;
      border-radius: 3px;
      color: $off-black;

      padding: 5px 10px;
      text-decoration: none;
      margin: 0;
      @include border-radius(3px);

      &:hover {
        background-color: $bright-blue;
        color: #fff;
      }
    }

    div.field-item {
      display: inline-block;
      margin: 0 3px;
      float: none;
      font-size: 14px;
      font-style: normal;
    }
  }

  .article-footer {
    padding: 1em 0;
    margin: 1em 0;
    border-top: solid 1px #eee;
    display: inline-block;
    width: 100%;
  }

  .field-name-field-categories {
    margin: 0;
    padding: 0 0 1em 0;
    border: none;
    clear: none;
    float: left;
  }

  .field-sources {
    font-size: 13px;
    display: inline-block;
    background: #eee;
    padding: 4px 5px;
    margin-top: 1px;
    float: right;
  }

  .related-articles {
    padding-top: 1em;
    clear: both;
    padding-bottom: 20px;

    .field-label {
      font-size: 16px; //@include  border-radius(2px);
      padding: 7px 0; //color: #FFF;
      //font-weight: 700;
      font-family: $header-family;
      background: #fff; //text-transform: uppercase;
      line-height: 1em;
      margin-bottom: 10px;
      border-bottom: solid 1px #eee;
      color: $bright-blue;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: $header-spacing;
    }

    .field-items,
    .view-content {
      width: auto;
      display: grid;
      align-items: start;
      justify-content: start;
      align-content: space-around;
      justify-items: start;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 1em 2em;
    }
  }
}

.node.related_block {
  .inner {
    position: relative;
  }

  .field-name-field-lead-image {
    margin: 0;
    padding: 0;
    float: left;
    width: 125px;
    position: relative;

    div {
      width: 100%;
    }
  }

  .content {
    padding: 0 0 0 15px; //top: 60%;
    margin: 0 0 0 125px;
    border: none;
  }

  .title {
    font-size: 18px !important;
    font-family: $header-family;
    font-weight: bold;
    line-height: 1.2em;
    @include multiline-clamp(4);

    a {
      text-decoration: none;
      color: #000;
    }
  }

  .search-excerpt {
    font-size: 0.85em;
    padding-top: 0.5em;
  }

  .play-wrapper {
    top: 7px;
    left: 7px;
  }

  .field-name-field-guest {
    color: #666;
    font-size: 11px;
    font-family: $header-family;
  }

  .field-name-field-dek {
    font-size: 1.1em;
    font-weight: normal;
    font-family: $header-family;
  }

  img {
    width: 100% !important;
    height: auto;
  }

  .category {
    color: $bright-blue !important;
    text-transform: uppercase; //padding-bottom: 2px;
  }

  .meta {
    font-size: 11px;
    line-height: 1em;
    margin-bottom: 3px;
    margin-top: 2px;
  }
}


.rate-like {
  float: left;
  margin-top: 5px;
  padding: 0 10px;
  min-width: 90px;
  text-align: center;

  span,
  a {
    padding: 0 10px;
    background: $green;
    font-weight: normal;
    color: #fff !important;
    @include border-radius(2px);
    line-height: 26px;
    display: block;
  }

  a:hover {
    background: $dark-green;
  }

  span.rate-button {
    background: $medium-grey;
    color: #eee !important;
  }
}

.inprogress .rate-like a {
  background: $medium-grey;
  color: #eee !important;
  cursor: default;
}

.like_count {
  display: none;
}

div.button {
  float: right;
  margin-left: 5px;

  a {
    background-color: $bright-blue;
    color: #fff;
    display: block;
    font-size: 11px;
    line-height: 24px;
    padding: 0 10px;
    text-decoration: none;
    @include border-radius(2px); // @include gradient(#0f6fb9, $bright-blue);
    // @include text-shadow(-1px -1px 0 rgba(0,0,0,0.3));
    // @include box-shadow(0, 1px, 2px, 0, rgba(0,0,0,0.3));
    // border: solid 1px #0d6ab2;
    &:hover {
      background-color: $darker-blue; // @include gradient($darker-blue, #0f6fb9);
    }
  }
}

#node-header {

  .field-name-field-video {
    background: #111;
    text-align: center;
  }

  .rate-widget {
    float: right;
  }

  .rate-like {
    margin-top: 0;
    padding: 0 0 0 13px;
    min-width: 90px;
    text-align: center;

    a {
      min-width: 100px;
    }
  }

  .helpers-share {
    // margin-top: 18px;
    .share-item a {
      padding: 2px;
    }
  }

  .share-buttons {
    padding-bottom: 0.9em;
    padding-top: 0.5em;
  }


  /*.stats {
    float: right;
    margin-top: 5px;
    background: #eee;
    border: solid 1px #ccc;
    @include border-radius(2px);

    a {
      text-decoration: none;
      color: #000;
    }

    a .stat,
    .stat {
      float: left;
      font-size: 21px;
      font-weight: bold;
      padding: 7px 15px 10px 15px;
      line-height: 20px;
      color: #000;
      border-right: solid 1px #ccc;

      a .label,
      .label {
        font-size: 12px;
        color: #666;
        text-transform: uppercase;
        font-weight: normal;
        padding-bottom: 0;
        line-height: normal;
      }
    }
  }*/

  /*.inprogress .rate-like a{
      background: $medium-grey;
      cursor: wait;
   }*/
  .gradient {
    @include trans-black_gradient-top();
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 100px;
  }

  /*&.node-list,
  &.node-video {
    .userinfo-wrapper {
      position: relative;
    }
  }*/

  .userinfo {
    float: left;
    font-family: $header-family;
    padding: 0;
    max-width: 50%;

    .user-picture {
      float: left;
      width: $avatar-width+10;
    }

    .user-details {
      margin-left: $avatar-width + 20px;
    }

    &.no-user {
      .user-details {
        margin-left: 0;
        padding-top: 2em;
      }
    }

    h1 {
      margin: 0;
      padding: 0;
      font-size: 2.1em; //font-family: $default-family;
      letter-spacing: normal;
      font-weight: 600;
      color: #fff;
    }

    div.username {
      float: none;
      margin: 0;
      font-size: 18px;
      padding: 0 0 0.3em 0;
      line-height: 0.8em;

      a.username {
        text-decoration: none;
        color: #666;
        vertical-align: text-bottom;
        padding-right: 3px;
      }

      .flag {
        background-color: $green;
        padding: 4px 7px;
        line-height: 12px;

        &:hover {
          background-color: $dark-green;
        }

        &.unflag-action {
          background-color: #ddd;
          color: $dark-grey; // !important;
          &:hover {
            // background-color: $medium-grey  !important;
            // color: #ccc;
          }
        }
      }
    }
  }

  .flag-link-toggle {
    display: inline-block;
    float: none;
  }

  .field-name-body {
    padding: 1em 0;
    font-size: 1.2em;
    color: #444;
    line-height: 1.4em;
  }

  .field-name-field-photo {
    position: relative;
    padding: 1em 0;

    img {
      max-width: 100%;
      height: auto;
    }

    .field-items {
      position: relative;
    }
  }
}

/* .view-main-image-block {
  .views-field-name {
    font-size: 17px;
    a {
      text-decoration: none;
      color: #666;
    }
  }
  h1.title {
    font-size: 25px;
  }
  .views-field-ops {
    float: right;
  }
  .views-field-picture {
    float: left;
    width: 70px;
    margin-right: 10px;
    img {
      height: auto;
      max-width: 100%;
    }
  }
} */


.views-field-field-video,
.views-field-field-lead-image,
.views-field-field-photo {
  clear: both;
  float: none;
  display: block;
  width: 100%;

  img {
    margin: auto;
    display: block;
    height: auto;
    width: 100%;
    max-width: 100%;
  }
}

.nsfw-image {
  padding: 2em;
  font-size: 1.2em;
  background: #eee;
  margin: 1em 0;
}

a.comment-link-trigger,
a.tab-trigger {
  float: right;
  display: inline-block;
  color: #ddd; // !important; //border: solid 1px #ddd;
  //@include border-radius(3px);
  padding: 5px;
  font-size: 18px;
  line-height: 1em;
  position: absolute;
  top: 23px;
  right: 10px;
  z-index: 5;

  &:hover {
    //background: #eee;
    color: #666; // !important;
  }
}

a.tab-trigger {
  background: rgba(210, 210, 210, 0.3); //#fcfcfc;
  text-align: center;
  padding: 10px;
  border-radius: 100%;
  color: #aaa; //border: solid 1px #f7f7f7;
  &:hover {
    background: rgba(210, 210, 210, 0.5);
    color: #000;
  }
}

#content-inner {
  a.tab-trigger {
    top: 3px;
    right: 3px;
  }
}

a.comment-link-trigger {
  display: block;
  border-radius: 100px;
  line-height: 1em;
  padding: 0;
  width: 30px;
  text-align: center;
  top: 0;
  right: 3px;
}


/*.comment:hover {
  //border-top: solid 1px #eee;
  //border-bottom: solid 1px #eee;
  //background-color: #f7f7f7;
  a.comment-link-trigger {
    display: block;
  }
}*/

.bundle-notice {
  overflow: hidden;
  border-radius: 5px;
  margin-top: 50px;
  clear: both;
  display: inline-block;
  width: 100%;
}

/* .form-item-profile-picture-field-user-avatar-und-0{
    display: none;
  } */

/* a.button.avatar-delete-button{
    display: inline-block;
    float: left;
    position: absolute;
    left: 0;
    //top: 0;
    font-size: 11px;
    margin: 0;
  }
 */

.comment-button-wrapper {
  text-align: center;
  padding: 20px 0 60px 0;
  font-size: 20px;

  a.button {
    font-size: 20px;
    line-height: 3.2em;
    padding: 0 1.3em;
    font-family: $default-family;
    @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.2));

    i {
      margin-right: 10px;
      vertical-align: middle;
    }
  }
}


.node-list.full {
  .comment-wrapper {
    max-width: 800px;
    margin: auto;
  }

  .pins-container {
    padding-top: 1em;
  }

  .userinfo-wrapper {
    float: none;
    display: inline-block;
    width: 100%;
    padding: 10px 0;
  }
}


.article-index {
  .views-row,
  .article-row,
  .row {
    border-bottom: solid 1px #eee;
    padding: 0.5em 0;
  }
}

#block-views-taxonomy_term-block_1 {
  .block-inner {
    padding: 2em 0;
  }
}

// Popular articles by category.
.view-taxonomy-term.view-display-id-block_1 {
  .view-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 1.5em 2.5em;
  }

  .row {
    border: none;
    padding: 0;

    img {
      background-color: #eee;
    }
  }

  .views-field-title {
    h3 {
      margin: 10px 0;
      padding: 0;
      font-size: 1.2em;
      line-height: 1.15em;
      font-weight: bold;
    }

    a {
      text-decoration: none;
      color: #000;
    }
  }
}


.secondary-index {
  h1.title {
    color: $bright-blue;
    font-weight: 600;
    font-size: 29px !important;
    border-bottom: solid 1px #eee;
    padding: 5px 0;
    margin: 10px 0;
  }
}

.field-name-body,
.field-name-comment-body {
  word-wrap: break-word;
  word-break: break-word;
}


.media-element-container {
  text-align: center;
  margin: 0.5em 0;
  clear: both;
  padding: 1em 0;
}

figure {
  margin: auto;
  text-align: center;
}

.node {
  .content {
    color: $body-font-color;

    ul.links {
      margin: 0;

      li {
        margin: 0;
      }
    }
  }

  iframe {
    vertical-align: middle;
  }
}

.node.full {
  // dont show rollover on table cells within an article
  table {
    tr:hover {
      td {
        background: inherit;
      }
    }

    td {
      background: inherit;
    }
  }

  .node-content {
    .wp-caption {
      width: 100% !important;
    }

    img {
      display: block;
      margin: auto;
      clear: both;
    }

    img.align-right {
      float: right;
      clear: none;
      margin: inherit;
      display: inline;
    }

    img.align-left {
      float: left;
      clear: none;
      margin: inherit;
      display: inline;
    }
  }

  figure {
    display: table;
    margin: 0.5em auto;
    text-align: center;

    img.media-element {
      padding: 0 !important;
    }
  }

  figcaption {
    text-align: left;
    font-size: 14px;
    line-height: 1.3em;
    padding: 0.5em 1.2em;
    margin-top: 5px;
    background-color: #f7f7f7;
    display: table-caption;
    caption-side: bottom;

    p {
      margin: 0.5em 0 !important;

      /*&:last-child {
        margin-bottom: 0 !important;
      }*/
    }
  }

  .field-name-field-caption {
    text-align: center; //font-style: italic;
    font-size: 14px;
    padding: 0 0 7px 0;
    line-height: 1em;
  }

  .field-name-field-gear-items {
    .field-name-field-caption {
      text-align: left;
      font-style: normal;
      font-size: 1em;
    }
  }

  .content td,
  .content {
    //font-size: 1.2em;
    //line-height: 1.5em;
    //color: #333;
  }

  .content-block {
    display: inline-block;
    vertical-align: top;

    .label {
      font-size: 12px;
      color: $bright-blue;
      border-bottom: solid 1px #eee;
      text-transform: uppercase;
      margin-bottom: 5px;
      font-weight: bold;
      font-family: $default-family;
    }

    &.tags {
      margin-left: 20px;

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        @include tags();
      }
    }
  }

  .video-wrapper {
    padding: 0.5em 0;
    clear: both;
  }

  center {
    clear: both;
  }

  .field-name-field-gallery {
    clear: both;
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;

    .file {
      float: left;
      width: 33.3%;

      a {
        display: block;
        padding: 2px;
      }

      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }
  }
}

div.media-gallery,
ul.media-gallery {
  margin: 0 !important;
  list-style: none; //clear: both;
  float: left;
  width: 100%;
  padding: 1em 0 !important;

  .lightbox-caption {
    display: none !important;
  }

  .field-item,
  li {
    padding: 0 !important;
    margin: 0 !important;
    list-style: none;
    float: left;
    width: 33.3%;
    display: block;

    a {
      //display: block;
      //padding: 2px;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
}

.content {
  img.media-element {
    max-width: 100%;
    height: auto;
  }
}

.node-image-gallery.embeded {
  clear: none !important;
  display: inline-block;
  width: 100%;

  li {
    a,
    img {
      height: 100% !important;
      width: 100% !important;
    }
  }

  .field-name-body {
    font-size: 0.85em !important;
    text-align: center;
    font-style: italic;
  }
}

.node-image-gallery {
  .token {
    font-family: Courier, Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", monospace;
    font-size: 1.3em;
    padding: 1em;
    display: inline-block;
    background: #eee;
    font-weight: bold;
  }
}

.node .field-name-body .media-gallery {
  @include clearfix();
  padding: 1em 0;

  img.media-element {
    margin: 0 !important;
    padding: 5px !important;
    float: left !important;
    clear: none !important;
    vertical-align: middle;
    width: 32.9%;
    height: 32.9%;
    display: block !important;

    &.file-default {
      margin: 0 !important;
      padding: 5px !important;
      clear: none !important;
      float: left !important;
      width: 33%;
    }
  }
}

.download-button-wrapper {
  text-align: center;
  padding: 10px; //display: none;
}

a.download-button {
  font-size: 16px;
  display: inline-block;
  line-height: 1.9em;
  padding: 0 1em;
  text-decoration: none;
  background-color: #eee;
  border-radius: 3px;
  border: solid 1px #ccc;
  color: #333;

  &:hover {
    background-color: $bright-blue;
    color: #fff;
    border-color: $bright-blue;
  }
}

.play-wrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px; //line-height: normal;
  //i,
  svg {
    color: #fff; //rgba(255, 255, 255, 1); //font-weight: normal;
    fill: currentcolor;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.7));
  }
}

.node {
  /*.share {
    float: right;

    .facebook,
    .twitter {
      float: left;
      width: 90px;
    }

    .facebook {
      //position: absolute;
      //top: 0;
      //left: 90px;
      background: none;
      overflow: hidden;
    }
  }*/

  /*header {
    h2 {
      margin: 0;
    }
  }*/

  .content {
    ol,
    ul {
      margin: 0 2em 1em;

      li {
        margin: 0 0 0.3em 0.5em;
      }
    }

    h2,
    h3,
    h4 {
      margin: 0;
      padding: 0.4em 0;
      /*clear: both;*/
    }
  }

  /*div.links {
    font-size: 11px;
    border-top: solid 1px $light-grey;
    padding: 0.3em 0;

    a {
      color: $off-black;
    }

    li {
      margin: 0;
      display: inline-block;
    }
  }*/

  .photo-wrapper img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  /* .photo-wrapper,
    .field-name-field-lead-video,
    .field-name-field-lead-image,
    .field-name-field-photo {
      img {
        margin: auto; //display: block;
        height: auto !important;
        max-width: none;
        vertical-align: middle;
        width: 100%;
        height: auto;
        //background-color: #eee;
        display: inline-block;
      }
    } */
  .photo-wrapper {
    background-color: #eee;

    .photo {
      background-color: #eee;
    }

    a {
      background-color: #eee;
      display: inline-block;
      height: 100%;
      width: 100%;
    }
  }

  &.gallery_item_uncropped .file a {
    background-color: #eee;
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  /* .field-name-body {
      img {
        width: auto;
      }
    } */
  .field-name-field-lead-image {
    margin: 0.5em 0 2em 0;
  }

  &.node-teaser {
    font-size: 14px;
    margin: 2.5em 0; //padding: 1em 0;
    .no-comments {
      opacity: 0.4;
    }

    .submitted {
      margin: 0; //0.5em 0;
      font-size: 12px;

      span {
        vertical-align: top;
      }
    }

    /* span{
        color: #aaa;
      } */
    .date {
      padding-right: 0.5em;
      color: #999;
    }

    .username {
      color: inherit; //padding-left: 10px;
    }

    .sponsored {
      //float: right;
      //font-style: italic;
      //padding-top: 5px;
      //text-transform: uppercase;
      text-transform: uppercase;
      display: inline-block;
      line-height: 1.7em;
      position: absolute;
      top: 5px;
      right: 5px;
      background: rgba(0, 0, 0, 0.3);
      padding: 0 0.5em;
      border-radius: 3px;
      color: #fff;
      font-size: 11px;

      a {
        //color: #999;
        text-decoration: none;

        &:hover {
          //text-decoration: underline;
        }
      }
    }

    .submitted .sponsored {
      position: relative;
      line-height: inherit;
      /* background: none; */
      /* color: #000; */
      top: inherit;
      padding: 0.3em 0.5em;
      left: inherit;
      right: inherit;
      bottom: inherit;
      font-size: 10px;
      /* padding-right: 0.5em; */
      vertical-align: middle;
      margin-right: 0.3em;
      background: rgba(0, 0, 0, 0.8);
    }

    .lead-image {
      width: 100%;
      max-width: 260px;
      float: left;
      padding-top: 0;
    }

    h2 {
      font-size: 1.7em;
      padding: 0.2em 0;
      //font-weight: 900;
      line-height: 1.1em;

      a {
        text-decoration: none;
        color: $off-black;
      }
    }

    .share {
      float: left;
    }

    .field-name-body {
      // padding-top: 1em;
    }

    .field-name-field-lead-image {
      float: left;
      //margin-right: 0;
      //margin-top: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
      //margin-bottom: 0.5em;
      //border: solid 1px #eee;

      a {
        position: relative;
        display: block;
      }
    }

    &.node-contest,
    &.node-article {
      .content {
        margin-left: 280px;
        //color: #666;
        //font-size: 1.13em;
        //line-height: 1.3em;
      }
    }

    /* .article-comments{
        float: right;
        font-size: 14px;
        padding-left: 10px;
        padding-top: 5px;
        a{
          color: #666;
          text-decoration: none;
        }
        i{
          color: #ccc;
        }
      } */
  }

  .field-name-field-dek {
    font-size: 1.7em;
    line-height: 1.3em;
    color: #000;
    font-family: $header-family;
    font-weight: normal;

    p {
      margin-bottom: 0;
    }
  }

  .meta {
    padding: 0; //font-size: 11px;
    span {
      color: #aaa !important;
    }

    .read-more {
      a {
        background-color: $bright-blue;
        border: solid 1px $bright-blue;
        color: #fff;
        padding: 0 1em;
        font-size: 12px;
        line-height: 2.3em;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0;
        text-decoration: none;
        @include border-radius(2px); //font-family: $header-family;
        display: inline-block;

        &:hover {
          background-color: #fff;
          color: $bright-blue;
        }
      }
    }

    .submission-count,
    .article-comments {
      margin: 0;
      float: right; //line-height: 2.5em;
      //font-size: 12px;
      color: #ccc; //font-family: Heebo;
      padding-left: 0.9em;

      a {
        text-decoration: none;
        color: #666;
      }

      i {
        color: #ccc; //font-size: 1.5em; //margin-right: 3px;
        //vertical-align: middle;
      }
    }
  }
}

.no-gridzy {
  > .field-item {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}

.actions-wrapper {
  font-family: $header-family;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;

  .share-buttons {
    display: none;
  }

  .action-item {
    float: right;
    padding: 0;
    line-height: 60px;
    @include border-radius(2px);
    background-color: rgba(0, 0, 0, 0.35);
    border: none;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #fff;

    a {
      color: #ccc;
      display: inline-block;
      padding: 0 15px;

      &:focus {
        outline: none;
      }
    }

    i {
      font-size: 25px;
      vertical-align: middle;
    }

    .rate-button {
      padding: 0;
    }

    &.fivestar-container {
      padding: 0 10px;
      margin-left: 0;
    }

    &.add-icon {
      .fa-plus {
        font-size: 20px;
        margin-top: -15px;
        margin-right: -5px;
        color: #fff;
        position: relative;
      }
    }
  }

  .count {
    font-family: $header-family;
    font-size: 20px;
    margin-left: 7px;
    vertical-align: middle;
  }

  a {
    text-decoration: none;
  }

  .vote-wrapper {
    float: left;
  }

  .fivestar-wrapper {
    .background-fill {
      overflow: hidden;
      display: inline-block;
      position: absolute;
      z-index: 999;
      top: 0;
      left: 0;

      i {
        color: #ffc600;
      }
    }

    &.community-show .rate-button {
      font-size: 15px;
      width: 17px;
      height: 15px;

      i {
        font-size: inherit;
      }
    }
  }

  .admin-modal-rating {
    .fivestar-wrapper {
      .background-fill {
        i {
          color: $bright-blue;
        }
      }
    }
  }
}

.ajax-comments-edit-cancel,
.ajax-comments-reply-cancel {
  margin: 0 5px;

  &:visited,
  &:link {
    color: #444;
  }
}


.form-type-checkbox.form-disabled {
  label.option {
    color: #888 !important;
  }
}

.form-type-radio,
.form-type-checkbox {
  .description {
    margin-left: 0.5em;
  }
}

.filter-wrapper {
  padding: 0;
}

fieldset.filter-wrapper {
  background-color: #fff;
  font-size: 11px;
  padding: 0.5em 0;
}

/*.page-comment-reply #content-area .content {
  font-size: 13px;
}*/

.comment {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  //padding-top: 10px;
  //padding-left: 5px;
  position: relative;

  &.shadowbanned {
    opacity: 0.5;
    filter: grayscale(1);
    background: #f7f7f7;
  }

  .comment-inner {
    padding: 10px 10px 0 10px;
  }

  .images-wrapper {
    margin-bottom: 10px;
  }

  .content {
    font-size: 14px;
    line-height: 1.5em;

    //padding-bottom: 6px; //z-index: 1;
    position: relative !important;
    color: #666;

    .field-name-comment-body {
      padding: 0 0 5px 0;
    }

    .field-name-field-comment-image {
      .field-item {
        margin: 1em 0;
      }
    }

    p.removed {
      color: #999;
      font-style: italic;
      font-weight: bold;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .contest-entries-link {
    /*margin-left: -40px;
    top: 45px;
    left: 0;*/
    color: #888;
    //font-size: 18px;
    // position: absolute;

    float: left;
    display: inline-block;
    line-height: 1.4em;
    padding-right: 5px;
    font-size: 18px;

  }
}

//.indented {
.comment .contest-entries-link {

  //margin-left: -35px;
  //top: 35px;
}

//}


.node.full {
  .content-col {
    width: 55%;
    float: left;
  }

  .comments-col {
    margin-left: 55%;
    padding: 0 10px;

    .comment-wrapper {
      padding-left: 25px;
      border-left: solid 1px #eee;
      margin-left: 25px;
    }
  }
}

.comments {
  padding: 0 0;
  margin: 2em 0; //display: inline-block;
  //width: 100%;
  .ajax-progress {
    display: none; // .message
  }

  .dim {
    opacity: 0.2;
  }

  /* div.links {
     border: none;
     padding: 0;
     clear: both;
     display: inline-block;
     width: 100%;

     ul.inline,
     ul.links.inline {
       display: inline-block;
       padding-left: 0;
       line-height: 2em; //float: right;
       margin: 0;
       font-size: 12px;
       vertical-align: middle;

       li {
         display: inline-block;
       }
     }
   }*/

  /*.comment .content {
    margin-bottom: 0 !important;
    position: relative !important;
    margin-right: 0 !important;
    float: none !important;
    width: auto !important;
    line-height: 1.4em;
  }*/
}

// @todo - I think lined comments can be removed, we never went this route in prod.
/*.lined {
  .indented {
    background-image: url(../images/dash-light-blue.gif);
    background-repeat: repeat-y;
    background-position: 0 0;
    padding-left: 5px;
    margin-left: 45px;
    border-bottom: solid 5px #fff;

    .indented {
      margin-left: 35px;
    }
  }

  .comment {
    padding: 0;
    margin: 0;
    !* border-left: solid 1px #ccc;
      &:last-child:before{
        border-left: solid 1px #ccc;
      } *!
    .content {
      border-bottom: solid 10px #fff;
      padding-bottom: 0;

      div.links {
        border-bottom: solid 1px #eee;
        padding-bottom: 5px;
      }
    }
  }
}*/

/*.comments-collapsed {
  display: none;
}

a.expand-comments {
  font-size: 11px;
  margin-left: 40px;
  display: inline-block;
  padding: 5px;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 5px;

  .reply-count {
    text-decoration: none;
    color: #888;
    font-style: italic;
    font-weight: normal;
  }

  &:hover {
    background-color: #eee;
  }
}*/

.field-name-field-suspicious {
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  line-height: 1em;
  padding: 0.25em 0;
}

a.read-more,
a.read-less {
  background-image: url(../images/grey-pixel.gif);
  background-position: center center;
  background-repeat: repeat-x;
  text-align: center;
  display: block;
  background-color: #fff;
  font-size: 11px;
  color: $off-black;
  text-decoration: none;
  padding: 5px;

  span {
    background-color: #fff;
    padding: 0 10px;
  }

  i {
    color: $bright-blue;
  }

  &:hover {
    color: $bright-blue;
  }
}

// @todo - is this a reply entity or somethign to do with an actual comment?
/*
.reply .content .show-more {
  background-color: #F7F7F7;

  a {
    background-color: #F7F7F7;
  }
}
*/


.tipsy {
  text-align: left;
  line-height: normal;
  font-size: 12px; //padding: 0.7em;
  //position: relative;
  z-index: 9999999;
}

.tipsy-inner {
  padding: 10px;
  @include border-radius(2px);
  letter-spacing: normal;
}

.comment-header {
  float: none; //clear: both;
}

.comment-header .flag-link-toggle {
  display: block;
  background: $bright-blue;
  @include border-radius(2px);
  color: #fff !important;
  line-height: 21px;
  padding: 0 5px;
  text-decoration: none !important;
  font-size: 11px;
  float: left;
  margin-left: 0;

  &:hover {
    background-color: $darker-blue;
  }
}

.subscribe {
  position: relative;
  float: left;
  white-space: nowrap;

  .flag-throbber {
    display: none;
  }

  i {
    color: $medium-grey;
  }

  .fa {
    cursor: default;
  }
}

.nsfw-toggle {
  float: right;

  .flag-link-toggle {
    background: $medium-grey;
    color: #666 !important;

    &:hover {
      background: $dark-grey;
      color: #fff !important;
    }
  }

  .flag-throbber {
    display: none;
  }
}

// @todo - can this have a better selector?
#content-after #block-views-related_products-block .block-title,
.node .content h3.section-header,
#comment-count {
  font-family: $header-family;
  letter-spacing: $header-spacing;
  border-bottom: solid 1px #eee;
  margin: 1.5em 0;
  line-height: 1em;
  color: $bright-blue !important;
  font-weight: 800;
  font-size: 18px;
  padding: 7px 0;
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
}


div.embeded-image,
p.embeded-image {
  img {
    max-width: 100%;
    height: auto;
  }
}

div.file-video {
  background: #000;
}

.image-meta {
  font-size: 11px;

  strong {
    color: $off-black;
  }
}

.fs-embed {
  border: solid 1px #eee;
  padding: 0.3em 1.2em 1.2em;
  border-radius: 5px;
  margin: 1.5em 0;

  .image-meta {
    font-size: 14px;
    padding: 5px;
    background: #fff;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    border: none;

    .title {
      a {
        text-decoration: none;
        color: #666;
      }
    }
  }

  .user-picture {
    width: 40px;
  }

  .info {
    margin-left: 50px;
    line-height: 1.5em;
  }

  a.username {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    font-size: 16px;
  }

  .photo {
    img {
      margin: 0;
      vertical-align: middle;
    }
  }
}

.comment {
  .fs-embed {
    font-size: 11px;
    padding: 0.2em;

    .info {
      margin-left: 30px;
      line-height: 1.2em;
      font-size: 11px;

      .username {
        font-size: inherit !important;
      }
    }

    .image-meta {
      border: none;
      margin-bottom: 0;
    }

    .user-picture {
      width: 25px !important;
    }
  }
}

#comments-loading {
  background-image: none;
  padding: 1.8em;
  font-size: 18px;
  text-align: center;
}

.comments a.morecomments-button {
  //background-color: $bright-blue;
  background: #fff;
  border: solid 1px #ccc;
  color: #888 !important;
  text-transform: uppercase;
  text-decoration: none; //font-family: $header-family;
  @include box-shadow(none);
  font-size: 1.2em;
  line-height: 3.5em;
  padding: 0 1em;
  font-weight: normal; //border: none;
  border-radius: 5px;
  width: auto;
  margin: 2em auto;
  max-width: 500px;

  &:hover {
    background-color: $darker-blue;
    border-color: $darker-blue;
    color: #fff !important;
  }
}

ul.inline,
ul.links.inline {
  margin: 0;

  li {
    padding: 0 0.5em 0 0;
  }
}

div.links {
  font-size: 13px;
  line-height: 1em;

}

.comment {

  .content {
    //display: inline-block;
    //width: 100%;
  }

  header {
    float: none;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .name-date {
    > * {
      display: block;
    }
  }

  .submitted {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 1.5em;
    width: 100%;
    display: inline-block;
    //@include text-dots();
    > * {
      //vertical-align: middle;
      float: left;
      //vertical-align: top;
      margin: 0 5px;
    }

    .username {
      max-width: 400px;
      font-size: 1.1em;
    }

  }

  .name-date {
    line-height: 1.4em;
  }

  time {
    font-style: italic;
    color: #aaa;
  }

  .username {
    color: $bright-blue;

    a {
      color: $bright-blue;
    }
  }

  ul.links {
    i {
      display: none;
    }

    li {
      padding: 0;
    }

    a {
      padding: 0 0.5em;
    }
  }

  div.links {
    font-size: 13px;
    line-height: 1.6em;
    border-bottom: solid 1px #eee;

    a {
      color: #aaa;
      text-decoration: none;
    }

    a:hover {
      color: $off-black;
    }

    .rate-widget {
      //float: left;
      margin-right: 0;
      vertical-align: middle;
      display: inline-block;
      margin-bottom: 2px;

      a {
        border: none !important;
      }

      a:hover {
        color: #FFF;
      }
    }
  }

  h3.title {
    margin: 0;
    font-weight: normal;

    a {
      text-decoration: none;
      color: $off-black;
    }
  }

  .user-picture {
    float: left;
    width: $avatar-width - 10px; //overflow: hidden;
    margin-left: 0;
    //margin-right: 10px;
  }

  .content {
    //margin-left: $avatar-width; // + 10px;
  }

  .field-name-field-comment-image {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.comments {
  a.read-more,
  a.read-less {
    margin-left: 50px;
  }

  .indented {
    //margin-left: 0;
    padding-left: 5px;
    border-left: solid 2px #eee;
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    // margin-left: $avatar-width; //35px;
    .indented {
      // margin-left: $avatar-width - 10px;
      .indented {
        .indented {
          margin-left: 0 !important;
          padding-left: 0;
          border: none;
        }
      }
    }

    a.read-more,
    a.read-less {
      margin-left: 35px;
    }

    .comment {
      .user-picture {
        width: $avatar-width - 20px;
      }

      .content {
        //margin-left: $avatar-width - 10px;
      }
    }
  }
}


a.load-prev-ajax,
a.load-more-ajax {
  text-align: center;
  padding: 1em 2em;
  font-size: 18px;
  color: #fff !important;
  text-decoration: none;
  background-color: $dark-grey;
  @include border-radius(4px);
  margin: 2em auto;
  max-width: 50%;
  display: block;
}

a.load-prev-ajax:hover,
a.load-more-ajax:hover {
  background-color: $medium-grey;
  color: $dark-grey !important;
}


// This is the trending articles sidebar.
.view-popular-articles {
  .view-content {
    ul,
    li {
      list-style-type: none;
    }

    li {
      padding: 0 0 10px 0;
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0 0.1em;
    }

    .lead {
      float: left;
      width: 125px;

      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
        border: solid 1px #eee;
        padding: 0;
      }
    }

    .title {
      font-weight: bold;
      padding-top: 5px;
      font-size: 15px;
      font-family: $header-family;
      margin-left: 125px;
      padding-left: 15px;

      a {
        text-decoration: none;
        color: $off-black !important;
      }
    }
  }
}

// user comments shown on the profile page and in ajax popups.
.comment.user-comment {
  .content {
    margin-left: 45px; // overflow: visible; //font-size: 14px !important;
    color: #666;
    line-height: 1.4em;
    margin-right: 100px;
    border-bottom: none;
    display: block;
    width: auto;


  }

  .comment-inner {
    padding: 5px 0;
  }

  .submitted {
    width: auto;

    > * {
      float: none;
      padding: 0;
    }
  }

  .user-picture {
    width: 35px;

    .badge {
      display: none;
    }
  }
}

.view-user-comments {
  a.comment-permalink {
    font-weight: bold;
    color: #333;
  }

  .rate-widget {
    float: right;
    line-height: 20px;
    margin-top: 0; //-5px;
    //position: absolute;
    //right: 0;
    ul {
      line-height: 20px;

      .count {
        display: inline-block;
        float: none;
        margin: 3px;
      }
    }
  }

  .view-content {
    margin: 0 1em 0 0;
  }

  .views-row {
    border-bottom: solid 1px #eee; //margin: 0 1em;
  }

  // h3 is the thread title in this view.
  h3 {
    margin: 0;
    padding: 1em 0 0 0;
    font-family: $default-family;
    font-size: 19px;
    font-weight: normal;
    letter-spacing: normal; //color: #000;
    border: none !important;

    a,
    a:link,
    a:visited {
      color: $bright-blue;
      text-decoration: none;
    }

    a:hover {
      color: #000;
    }
  }
}

.user-comment {
  .response-author {
    color: #888;
    //padding-right: 1em;
    padding-left: 0;
  }

  .images-wrapper {
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }


  .submitted {
    a {
      color: #666;

      &:hover {
        color: #000;
      }
    }
  }
}


/*
Batch Processing
=======================
*/

.page-batch {
  h1.title {
    text-align: center;
    margin: 50px auto 0 auto;
    font-weight: 300;
    font-size: 30px;
    max-width: 800px;
  }

  #progress {
    padding: 50px 0;
    max-width: 400px;
    min-height: 400px;
    margin: auto;
    font-size: 11px;
    font-weight: normal;

    .filled {
      -webkit-transition: width 0.5s;
      /* Safari */
      transition: width 0.5s;
      background-image: none;
      background-color: $bright-blue;
    }

    .bar {
      background: #ddd;
      border: none;
      margin: 0 0.2em;
      @include border-radius(0);
    }
  }
}

/*
views blocks
==================
*/

//.view-related-media,
/*.view-user-photos {
  .view-content {
    line-height: normal;

    .photo {
      float: left; //padding: 2px;
      width: 33.3%;

      a {
        padding: 1px;
        display: block;
      }

      img {
        vertical-align: middle;
        width: 100%;
        height: auto;
      }
    }
  }
}*/

/*#block-block-2 {
  .block-inner {
    width: 300px;
    height: 250px;
    background: #ddd;
    font-size: 20px;
    line-height: 1.3em;
    color: #ccc;
    margin: 0 auto 1em auto;
    text-align: center;

    .content {
      padding: 1em;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }
}*/

.fa-spin {
  -webkit-animation: a $spin-speed infinite linear;
  -moz-animation: a $spin-speed infinite linear;
  -o-animation: a $spin-speed infinite linear;
  -ms-animation: a $spin-speed infinite linear;
  animation: a $spin-speed infinite linear;
}

.fa-spin-ease {
  -webkit-animation: a 0.7s ease-in-out infinite;
  -moz-animation: a 0.7s ease-in-out infinite;
  -o-animation: a 0.7s ease-in-out infinite;
  -ms-animation: a 0.7s ease-in-out infinite;
  animation: a 0.7s ease-in-out infinite;
}

.spinner {
  text-align: center;
  padding: 3em;
  color: #ccc;
}


.flag-subscribe-user {
  font-size: 11px;

  a.flag {
    text-decoration: none;
    color: #fff;
    @include border-radius(2px);
    background-color: $green;
    border: solid 1px $green;
    outline: none;
    text-align: center;

    &:hover {
      background-color: $dark-green;
    }

    .follow-label,
    .following-label,
    .unfollow-label {
      display: block;
      text-align: center;
      min-width: 65px;
    }

    &.flag-link-toggle.flag-action {
      i {
        //vertical-align: middle;
        padding-right: 3px;
      }
    }

    &.flag-link-toggle.unflag-action {
      //background-color: #eee;
      color: #333 !important;
      border-color: #ccc;

      i {
        color: $green;
        vertical-align: middle;
        padding-right: 3px;
      }

      .following-label {
        visibility: visible;
        display: block;
      }

      .unfollow-label {
        visibility: hidden;
        display: none;
      }

      &:hover {
        background: #f95c5c !important;
        border-color: #f95c5c !important;
        color: #fff !important;

        .following-label {
          visibility: hidden;
          display: none;
        }

        .unfollow-label {
          visibility: visible;
          display: block;
        }
      }
    }
  }
}

ul.editor-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  border: solid 1px #bbb;
  @include border-radius(3px);
  font-size: 13px; //@include box-shadow(0, 1px, 2px, 0, rgba(0,0,0,0.5));
  list-style: none;
  padding: 0;
  margin: 0 !important;

  li {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
    display: block;
    float: left;
    border-right: solid 1px #ccc;

    li {
      float: none;
    }
  }

  a {
    text-decoration: none;
    color: #000;
    padding: 0 12px;
    float: left;
    display: block;
    line-height: 2.2em;
    margin: 0; // 3px;
    color: #555;

    &:hover {
      color: #000;
      background: #eee;
    }
  }

  li:last-child {
    border-right: none;
  }
}


// @todo This should share a class.
.group-nav,
.profile-navigation {
  //background: #f4f5f5;
  // box-shadow($hor:none, $ver:0, $blur:0, $size:0, $color:0, $inset:'')
  // @include box-shadow(0, 4px, 3px, -3px, rgba(0,0,0,0.2), inset);
  // border-top: solid 1px #fff;
  border-bottom: solid 1px #eee;
  background: #fff;

  .count {
    font-size: 10px;
    background: #fff;
    border: solid 1px #eee;
    display: inline-block;
    @include border-radius(2px);
    padding: 2px;
    line-height: normal;
    vertical-align: super;
    color: #888;
  }

  .count-0 .count {
    display: none;
  }

  .item-list {
    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      float: left;
    }

    li {
      display: inline;
      font-size: 16px;
      margin-right: 25px;

      a {
        display: block;
        /*background: #eee;*/
        text-decoration: none;
        float: left; // height: 40px;
        line-height: 36px;
        padding: 0 5px;
        background: #fff;
        color: #666; // border-right: solid 1px #e0e0e0;
        border-top: solid 3px #fff;
        border-bottom: solid 3px #fff;

        &:hover {
          //background: #eaeaea;
          //@include box-shadow(0, 4px, 3px, -3px, rgba(0,0,0,0.2), inset);
          color: #000;
          border-bottom: solid 3px #666;
        }

        &.active {
          //background: $bright-blue;
          //background-image: url($sprite);
          //background-repeat: no-repeat;
          //background-position: 50% -80px;
          //@include box-shadow(none);
          color: #000;
          font-weight: bold;
          border-bottom: solid 3px $bright-blue;

          .count {
            font-weight: normal;
          }
        }
      }
    }
  }

  ul.action-links {
    margin: 5px 5px 0 0;
    @include border-radius(3px);
    overflow: hidden;
    border: solid 1px #ccc;
    padding: 0;
    float: right !important;
    font-family: $default-family;

    li {
      @include border-radius(0);
      border-left: solid 1px #ddd;
      margin: 0;
    }

    li.first {
      border: none;
    }

    li a {
      line-height: 27px;
      height: auto;
      color: #333;
      padding: 0 7px;
      font-size: 11px;
      text-transform: none;
      @include border-radius(0);
      @include box-shadow(none);
      @include text-shadow(0 1px 0 #fff);
      border: none;
      @include gradient(#f7f7f7, #fff);

      &.active,
      &:hover {
        @include gradient(#ddd, #eee);
        background-color: transparent;
        border-top: none;
        border-bottom: none;
        color: #000;
      }
    }
  }
}

/*   .view-my-photos,
  .view-list-items-order,
  .view-profile-photos {
    &.no-gridzy {
      .views-row {
        float: left;
        padding: 0;
        height: auto;
        width: 25%; //border-top: solid 1px #eee;
        img {
          max-width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }
    }
    .view-content {

    }
  } */

/* .view.user-gallery {
    .item-details {
      .user-picture {
        display: none;
      }
      .submitted {
        margin-left: 0;
        .username {
          display: none;
        }
      }
    }
  } */

// On profile photo views we want to hide the author since they are always the same.
.view-profile-photos {
  .node {
    .item-details {
      .user-picture,
      .username {
        display: none;
      }

      .submitted {
        margin-left: 0;
      }
    }
  }
}

/*

.node.admin_preview {
  padding: 5px;
  border: solid 1px #eee;
  border-radius: 5px;
  background-color: #fcfcfc;
  display: inline-block;
  width: 100%;

  .media-wrapper {
    width: 200px;
    float: left;
    position: relative;

    .manage {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
      text-align: center;

      a {
        padding: 5px;
        color: #fff;
        text-decoration: none;
        font-size: 20px;
        display: inline-block;

        .label {
          display: none;
        }
      }
    }

    .meta {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 10px;
      text-align: center;
      color: #fff;

      span {
        font-size: 13px; //padding: 0 5px;
        //display: inline-block;
        //text-align: center;
        //font-weight: bold;
        color: #fff !important; //margin: 0;
      }

      i {
        //display: block;
        //font-size: 22px;
        color: #fff; //margin-bottom: 5px;
      }
    }
  }

  .title-wrapper {
    font-size: 11px; //padding-bottom: 20px;
  }

  .title {
    color: #000;
    font-size: 18px;
    @include ellipsis();
    padding-bottom: 5px;

    a {
      text-decoration: none;
      color: #000;
    }
  }

  .vote-stat {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding: 3px 5px;
    color: #000;

    .label {
      font-size: 10px;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .exif-wrapper {
    .image-details {
      font-size: 12px;
      font-family: $default-family;

      .camera {
        font-size: 14px;
        font-weight: bold;
      }

      .camera-settings {
        padding: 0;
      }
    }

    .clearfix {
      &:after {
        content: inherit;
        display: none;
      }
    }
  }

  .content {
    margin-left: 200px;
    padding: 15px 20px;

    .meta {
      display: inline-block;
      font-size: 16px;
      vertical-align: middle;
      text-align: center;
      color: #000;
      padding: 0 15px;
      margin: 0;

      span {
        padding: 0 5px;
        display: inline-block;
        text-align: center;
        font-weight: bold;
        color: #000 !important;
        margin: 0;
      }

      i {
        display: block;
        font-size: 22px;
        color: #CCC;
        margin-bottom: 5px;
      }
    }
  }

  .rating-stars {
    //text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: middle;

    .star-wrapper {
      font-size: 13px;
      margin-right: 1px;
      display: inline-block;
      position: relative;
      vertical-align: middle;

      .star-empty {
        display: block;
        color: #ddd;
      }

      .star-fill {
        color: #ffbf14;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        display: block;
      }
    }
  }
}
*/


/**
 * =======================================
 * Activity Feeds
 * ==============================
 */
#block-views-activity_feed-block_1 {
  padding-top: 100px;
}

/* .view-activity-feed .view-content {
    padding-top: 3em;
  } */

.view-activity-feed .row {
  border-top: solid 1px #eee;
  padding-top: 3em;
  margin-top: 3em;
}

.view-activity-feed .view-content {
  float: left;
  width: 100%;
}

.view-list-items-order {
  .views-row {
    border: none;
  }

  // dont show the node edit/del links
  // when viewing it withing a pin/list context.
  .manage {
    .node-delete-link,
    .node-edit-link {
      display: none;
    }
  }
}

/* #block-views-profile_photos-block_1 {
    .views-row {
      width: 33.3%;
      padding: 0;
      height: auto;
      border: none;
      padding: 0 5px 5px 0;
      img {
        vertical-align: middle;
        width: 100%;
        height: auto;
      }
    }
  } */

#block-views-my_lists-block_1 {
  .views-row {
    width: 50%;
    float: left;
  }

  .pins {
    width: 100%;
  }

  .content .title {
    font-size: 12px;
  }
}

.field-group-div {
  .inner {
    padding: 0 1em 0 0;
  }
}

.group-left {
  .field-label,
  .block-title {
    margin: 0 5px 5px 0;
    padding: 5px;
    font-family: $header-family;
    background: $dark-grey;
    color: #ffffff !important;
    @include border-radius(2px);
    font-size: 15px;
    text-transform: uppercase;
  }

  .block {
    margin: 1em 0;
    float: left;
    width: 100%;
  }

  .bullet-column {
    ul {
      width: 49%;
      float: left;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        list-style: none;
        margin: 0;
        padding: 0 5px 6px 5px;
        line-height: 1.1em;
      }
    }
  }
}

#sidebar-first {
  .field-label {
    font-size: 12px;
    text-transform: uppercase;
    color: $bright-blue;
    padding: 5px 0;
  }
}


.group-right {
  .inner {
    padding: 0 0 0 2em;
  }
}

/* lists
-------------------------
*/

.view-my-lists {
  .node-teaser {
    margin-top: 0;
  }
}

.node-list.node-teaser {
  padding: 0 !important; //0.5em 0 !important;
  margin: 0 !important;
  width: 100%;
  max-width: 350px;

  .node-inner {
    margin: 0 2em 2em 0;
    position: relative;
  }

  .item-details {
    margin: 1px !important;
  }

  .pins {
    width: 100%;
    position: relative;

    a {
      position: relative;
      display: block;
      float: left;
    }
  }

  .pin {
    background: #ddd;
    border: solid 1px #fff;
    position: relative;
    /* img{
        border-bottom: solid 1px #fff;
         border-right: solid 1px #fff;
         display: inline-block;
     }*/
  }

  .image-0 {
    float: left;
    width: 66.6%;
    height: 66.6%;
    border-right: 0;

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
      max-width: 250px;
    }
  }

  .image-1,
  .image-2 {
    width: 33.3%;
    height: 50%;
    left: 66.6%;
    top: 0;
    position: absolute;
    border-right: none;
    border-left: solid 2px #fff;

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .image-2 {
    bottom: 0;
    top: auto;
  }
}

// @todo - what is preview-wrapper ?
/*
.preview-wrapper {
  h3 {
    color: $bright-blue !important;
    font-family: $default-family;
    margin: 0;
    padding: 0;
  }
  .user {
    font-size: 11px;
  }
  .photo {
    margin: 0.5em 0;
  }
}
*/


/*   .profile-tabs {
    border-bottom: solid 1px #ddd;
    .item-list {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          margin: 0 10px 0 0;
          padding: 0;
          float: left;
          a {
            font-size: 12px;
            background: #ddd;
            text-decoration: none;
            line-height: 30px;
            padding: 0 10px;
            display: block;
            color: #666;
            @include border-radius(4px, 4px, 0, 0);
            &:hover,
            &.active {
              background-color: $bright-blue;
              color: #fff;
            }
          }
        }
      }
    }
  } */

/*   .block {
    .view-popular-lists {
      .views-row {

        float: left;
        width: 33.3%;
        .title {
          a {
            text-decoration: none;
            color: $off-black;
          }
        }
        .submitted {

          a {
            font-weight: normal;
          }
        }
      }
      .meta {
        color: #999;
      }
      .content {
        width: auto;
      }
    }
  } */

.sidebar,
.group-left {
  .content {
    padding: 0 !important;
    margin-bottom: 1em;
    width: 100%;
    float: none;
  }

  /*.view-photo-lists,
  .view-profile-lists {
    .views-row {
      width: 50%;
    }
  }*/
}

#block-views-related_media-block {
  .block-inner {
    padding: 2em 0;
    width: 100%;
  }
}

#block-views-photo_lists-block {
  .block-inner {
    display: inline-block;
    padding: 2em 0;
    width: 100%;
  }
}

.pins-add-form {
  .intro {
    font-size: 18px;
    max-width: 950px;
    padding-bottom: 1em;
  }

  .lists-wrapper {
    max-width: 950px;

    .views-row {
      min-width: 250px;
      width: 33% !important;
    }
  }
}


.view-photo-lists {
  .views-row {
    float: left;
    width: 25%;

    .pins {
      width: 100%;
    }

    .content {
      line-height: normal;
      margin: 0;
      padding: 0;
    }

    .meta {
      i {
        margin: 0;
        color: #999;
      }

      span {
        margin-right: 5px;
        color: #555;
      }
    }
  }
}

#sidebar-second {
  .block-title {
    //@include border-radius(2px);
    //background-color: $dark-grey;
    color: $bright-blue;
    font-family: $header-family;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.15em;
    margin: 0 0 10px 0;
    padding: 0 0 5px 0;
    float: none;
    border-bottom: solid 1px #eee;
  }

  .block {
    margin: 0 0 calc($padding * 4) 0;
  }

  .block-views {
    .content {
      padding: 0 5px;
    }
  }
}

.block-support-fstoppers {
  .supportImg {
    text-align: center;
    padding: 10px 0;
  }
}


a.fancy-trigger {
  color: #333;
  display: block;
  float: left;
  font-size: 13px; //line-height: 21px;
  padding: 5px;
  margin: 5px;
  min-width: 30px;
  text-align: center;
  text-decoration: none;
  @include border-radius(2px);
  @include gradient(#eee, #FFF);
  @include box-shadow(0, 1px, 1px, 0, rgba(0, 0, 0, 0.1));
  border: solid 1px #ccc;
  line-height: 1em;

  &:hover {
    background-color: #ddd;
    @include gradient(#ddd, #eee);
    border-color: #bbb;
  }

  display: none;
}

#profile-menu-trigger {
  float: right;
  padding-left: 0.7em;
  padding-right: 0.7em;
  display: none;

  .label {
    display: none;
  }
}

/*
tabs
@todo - This couls be combined with the tabs sass file?
==================
*/

.tabs {
  .jq-dropdown-menu {
    a.active {
      display: none;
    }
  }
}

.tabs-dropdown {
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

ul.tabs.primary {
  padding: 0;
  margin: 0;
  list-style: none;
  float: left;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-left: 12px;
  background: transparent;
  border: none;
  padding-left: 0;

  li {
    margin: 0 3px;
    padding: 0;
    list-style: none;
  }

  li {
    float: left;
  }

  a {
    background: $bright-blue;
    color: #333;
    display: block;
    float: left;
    font-size: 11px;
    line-height: 21px;
    padding: 0 0.7em;
    margin: 0;
    text-decoration: none;
    @include border-radius(2px);
    @include gradient(#eee, #FFF); // @include text-shadow(-1px -1px 0 #FFF);
    @include box-shadow(0, 1px, 1px, 0, rgba(0, 0, 0, 0.1));
    border: solid 1px #ccc;

    &:hover {
      background-color: #ddd;
      @include gradient(#ddd, #eee);
      border-color: #bbb;
    }

    &.active {
      background-color: #ddd;
      @include gradient(#ddd, #eee);
      font-weight: bold; //@include box-shadow(none);
      border-color: #bbb;
      display: none;
    }
  }
}

// @todo can PM tabs have a secondary class so we dont need to combine here?
#block-fs_pm-fs_pm_links .content ul,
ul.tabs.secondary {
  width: auto;
  float: left;
  display: block;
  @include border-radius(3px);
  overflow: hidden;
  border: solid 1px #ddd;
  padding: 0 !important;
  margin: 0;
  margin-bottom: 1em;
  list-style: none;

  li {
    margin: 0;
    padding: 0; //width: auto !important;
    float: left;
    border: none;
    list-style: none;

    &:first-child a {
      border-left: 0 !important;
    }
  }

  a {
    @include gradient(#eee, #FFF); //overflow: hidden;
    color: #666;
    white-space: nowrap;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    display: block; //float: left;
    padding: 7px 18px;
    vertical-align: middle;
    @include border-radius(0);
    @include box-shadow(none);
    border: none;
    text-decoration: none;
    border-left: solid 1px #ddd;

    &:hover,
    &.active {
      border-left: solid 1px #ddd;
      font-weight: normal;
      @include gradient(#ddd, #eee);
    }
  }
}

/* user action buttons
=======================
*/

ul.action-links {
  padding: 0;
  margin: 0;
  list-style: none;
  float: right;
  padding-bottom: 5px;
  z-index: 98;
  position: relative; //margin-top: 5px;
  li {
    margin: 0 3px;
    padding: 0;
    list-style: none;
  }

  li {
    float: none;
    display: inline-block;
  }

  a {
    background: $bright-blue;
    color: #FFFFFF;
    display: inline-block; //float: left;
    //font-size: 14px;
    line-height: 2.1em;
    padding: 5px 15px;
    text-decoration: none;
    font-family: $header-family;
    border-radius: 3px;
    text-transform: uppercase; //@include gradient(#0f6fb9, $bright-blue);
    //@include text-shadow(-1px -1px 0 rgba(0, 0, 0, 0.3));
    //@include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.3));
    // border: solid 1px #0d6ab2;
    &:hover {
      background-color: $darker-blue; //@include gradient($darker-blue, #0f6fb9);
    }

    &.node-delete {
      //@include gradient($delete-red, #c20e1c);
      //border: solid 1px #8d0d17;
    }

    &.node-delete:hover {
      background-color: #c20e1c; //@include gradient(#c20e1c, #da101f);
    }
  }

  .node-delete {
    background-color: $delete-red;

    &:hover {
      background-color: #ff0000;
    }
  }
}

/*.page-profile-cover {
  #main {
    padding-top: 1em;
  }
}*/


//.section-contests,


.section-category {
  h1.title {
    font-family: $header-family;
    font-size: 30px;
    //border-bottom: solid 1px #eee;
    //color: $bright-blue;
    font-weight: bold;
    padding: 10px 0;
    // margin-bottom: 0.5em;
  }

  #fold {
    border-bottom: solid 1px #eee;
  }

  #content,
  #fold {
    padding-bottom: 1em;

    .taxonomy-term-description {
      font-size: 16px;
    }

    .block-title {
      font-family: $header-family;
      color: $bright-blue;
      font-size: 16px;
      line-height: 1.5em;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
    }
  }

  #sidebar-second {
    padding-top: 3em;
  }
}

.section-category {
  #content-header,
  #content-area {
    padding-top: 0;
  }
}

.view-taxonomy-term.article-index {
  .block-title {
    margin-top: 3em;
  }
}

.worksafe-toggle {
  font-size: 10px;
  color: $medium-grey;
  float: left;
  line-height: $nav-height;
  margin: 0 10px;
  font-weight: normal;

  strong {
    font-weight: normal;
  }

  .worksafe-toggle-link,
  .flag-wrapper {
    float: right;
    margin-left: 5px;
  }

  a {
    color: #fff;
  }

  .flag-throbber {
    display: none;
  }
}

.entity-message {
  .date {
    padding-top: 3px;

    i {
      font-size: 14px;
      font-style: normal;
      margin-right: 5px;
      vertical-align: middle;
      color: $bright-blue;
    }
  }

  &.unread-msg {
    background-color: $pale-blue;
  }
}

.notification-blocker {
  display: none;
}

.disabled-view .notification-blocker {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.3);
}

#privatemsg-new {
  .form-item-expires {
    .date-padding {
      padding: 0 0 0.5em 0;

      .description {
        display: none;
      }
    }
  }
}

.register-alt-links {
  padding: 1em 0;
  font-size: 14px;
  line-height: 1.3em;
  font-weight: normal;
  letter-spacing: normal;
}

.captcha {
  .form-item {
    margin: 0;
  }

  img {
    float: left;
    margin-right: 10px;
    padding: 2px;
    border: solid 1px #eee; //vertical-align:text-top;
  }

  label {
    width: auto !important;
    float: none !important;
    padding-bottom: 3px;
    padding-top: 0;
    margin-top: 0;
    font-weight: normal;
  }
}

.login-panel {
  width: 100%;
  max-width: 500px;
  margin: 2em auto;
  border: solid 1px #eee;
  @include border-radius(5px);
  padding: 2em;
  background: #fff;
  font-size: 14px;
  line-height: 1.5em;
}

#change-pwd-page-form {
  .form-item-current-pass {
    margin-bottom: 30px;
  }
}

.user-registration {
  .login-links {
    display: none;
  }
}

.reg-pages form,
.login-panel {
  .intro {
    font-size: 1.2em;
    padding: 1em 0;
    line-height: 1.4em;
  }

  h1.title {
    font-weight: 700;
    border: none;
  }

  h1 {
    font-family: $default-family;
    font-weight: normal;
    margin: 0;
    padding: 0;
    color: $bright-blue !important;
    border-bottom: solid 1px #eee;
    margin-bottom: 20px !important;
    padding-bottom: 5px;
    font-size: 25px;
  }

  .intro-message {
    font-size: 1.4em !important;
    padding: 0.7em;
    margin: 1em 0;
    line-height: 1.4em;
    font-weight: bold;
  }

  .form-actions,
  .edit-actions,
  #edit-actions {
    text-align: center;
    padding: 0 0 1em 0; //$form-label-width;
    clear: both;

    input {
      width: 100%;
      font-size: 20px;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .url-textfield {
    display: none;
  }

  div.password-strength,
  div.password-confirm {
    font-size: 11px;
    margin-top: 0;
    padding-top: 0;
    width: 50%;
    position: absolute;
    top: -2px;
    right: 0;
    line-height: normal;
  }

  .form-item {
    margin-left: 0 !important;
    border-bottom: none;
    padding: 0;
    margin-bottom: 0.7em;
    margin-top: 0.7em;

    label {
      float: none;
      width: auto;
      clear: both;
      text-transform: uppercase;
      font-size: 12px;
      color: #000 !important;
      letter-spacing: normal;

      &.option {
        text-transform: none;
        font-size: 14px;
      }
    }

    input.form-text {
      width: 100%;
      max-width: inherit;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-family: $default-family;
      font-size: 19px;
    }
  }

  &.login-form {
    .form-item input.form-text {
      font-size: 19px;
    }
  }

  .field-name-field-first-name,
  .field-name-field-last-name {
    float: left;
    width: 50%;
  }

  .field-name-field-first-name .form-item {
    margin-right: 0.5em;
    margin-bottom: 0;
  }

  .field-name-field-last-name .form-item {
    margin-left: 0.5em !important;
    margin-bottom: 0;
  }

  .confirm-parent,
  .password-parent {
    width: auto;
    position: relative;
  }

  .fieldset-wrapper {
    padding: 0 !important;
  }

  /*label{
    width: 150px !important;
    text-align: right;
  }
  .description{
    width: 150px;
  }*/
}

.logged-in {
  form#user-pass {
    .edit-actions,
    #edit-actions {
      padding: 0;
      margin: 0;
    }
  }
}

.view-user-image {
  margin-bottom: 1.5em;

  .views-field-picture {
    img {
      width: 100%;
      height: auto;
    }
  }
}


.buttons {
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-left: 5px;

    a {
      display: inline-block;
      padding: 5px;
      color: #fff;
      text-decoration: none;
      background: $bright-blue;
      @include border-radius(3px)
    }
  }
}


#hero {
  //#block-contest-contest_index_header,
  //#block-fs_groups-all_groups_header,
  .page-header-block {
    border: none;

    .block-inner {
      padding: 4em 10px;
      display: inline-block;
      width: 100%;
      color: #aaa; //margin: auto;
      .content {
        font-size: 19px;
        line-height: 1.3em;
        padding-bottom: 5px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .block-title {
      color: #fff;
      font-weight: 300;
      padding: 0 0 10px 0;
      font-size: 40px;
      margin: 0;
    }
  }
}

a.progress-disabled {
  opacity: 0.5 !important;
}

// @todo Is this for the upload page? If so i think it can be moved.
.uploaded {
  padding: 1em;

  .images {
    width: 150px;
    float: left;
    margin-right: 1em;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.sharing-wrapper {
  position: relative;
}

.liking {
  .icon {
    //width: 25px;
    //height: 25px;
    //line-height: 25px;
    @include border-radius(2px);
    background-color: #ccc;
    color: #fff;
    margin-right: 5px !important;
    vertical-align: middle;
    padding: 0 5px;
    display: inline-block;
    width: 37px;
    height: auto;
    text-align: center;
    line-height: 36px;

    i {
      //min-width: 1em;
      font-size: 1.4em;
      vertical-align: middle; //text-align: center;
      //width: 25px;
      //line-height: 25px; //font-weight: normal;
    }
  }

  a.unflag-action {
    .icon {
      background-color: $bright-blue;
    }
  }

  .item {
    a:hover {
      color: $off-black !important;
    }
  }
}

.seperator-right {
  border-right: solid 1px #ccc;
  margin-right: 10px;
  padding-right: 5px;
}

.images-wrapper .image,
.field-name-field-post-images {
  margin: 1em 0;
  position: relative;
  display: block;
  clear: both;

  img {
    //width: 100%;
    max-width: 100%;
    height: auto;
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }

  .port-add {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 11px;
    display: none;

    a {
      background: rgba(0, 0, 0, 0.4);
      line-height: normal;
      display: inline-block;
      padding: 0.5em;
      text-decoration: none;
      color: #fff;
      @include border-radius(3px);

      &:hover {
        background: rgba(0, 0, 0, 1);
      }
    }
  }

  &:hover {
    .port-add {
      display: block;
    }
  }
}

.views-exposed-form {
  .ajax-progress,
  .throbber {
    display: none;
  }
}


.file-video {
  .content {
    img {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
}

video {
  display: block !important;
  margin-right: auto;
  margin-left: auto;
}

.gridzy-view {
  .view-content {
    clear: both;
    float: left;
    width: 100%;
    margin-top: 1em;
  }

  .view-empty {
    padding-top: 3em;
  }
}


/*
._view-rated-photos
*/
.view-popular-photos {
  .views-row {
    float: left;
  }

  .file {
    position: relative;
  }

  .view-content {
    clear: both;
    float: left;
    width: 100%;
    margin-top: 1em;
  }

  /*.views-field-field-video,
  .views-field-field-photo {
    padding: 0;
    position: relative;
  }*/

  /*.views-field-title {
    padding-top: 5px;
  }*/

  /*.view-header {
    margin: 0;
    padding: 2em 0;

    .item-list {
      li,
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: inline;
          font-size: 1.3em;
          margin: 0 1em 0 0;
        }
      }
    }
  }*/
}


/*
.view-popular-photos-distinct.view-display-id-block_sidebar,
.view-popular-photos-user.view-display-id-block_3,
.view-popular-photos.view-display-id-block_1 {
  .view-content {
    clear: both;
    float: none;
    border-top: none;
    padding-top: 0;
  }
}
*/


/* popular lists page */

.view-popular-photos.lists {
  .views-row {
    width: 33.3%;

    /*    .node {
          padding-right: 1em;
          padding-bottom: 1em;
          line-height: normal;
          display: block;
        }*/
    /* .pins {
       width: 100%;
     }*/
  }
}

/* @@todo: make all these use the same parent class in views */

//.view-popular-photos-distinct.view-display-id-dropdown,
/*.view-popular-photos-distinct.view-display-id-block_sidebar,
.view-popular-photos-user.view-display-id-block_3,
.view-popular-photos.view-display-id-block_1 {
  //.view-popular-photos.view-display-id-dropdown_video {
  .views-row {
    float: left;
    margin: 1px;
    width: 32%;
    line-height: normal;
    font-size: 11px;
    vertical-align: middle;
    img {
      padding: 1px;
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
}*/

.region-sidebar-second {
  position: relative;
  //padding-right: 10px;
}

/*#block-block-6,
#block-block-7,
#block-block-8 {
  .content {
    background: #ccc;
    margin: 1em 0;
    height: 200px; xs
  }
}*/


//.view-id-site_9310.view-display-id-block_photos,
.view-featured-photos {
  .views-field-field-date-1,
  .views-field-title {
    height: auto !important;
    width: auto !important;
  }

  /*  .view-content {
      clear: both;
      display: block;
      float: none;
    }*/

  /*.views-row {
    float: left;
    width: 25%;
    margin: 0;
    position: relative;

    &:hover {
      .views-field-title {
        opacity: 1;
        @include transition(opacity .15s ease-in-out);
      }
    }
  }*/

  /*.views-field {
    padding: 2px;
    img {
      max-width: 100%;
      height: auto;
      text-align: center;
      vertical-align: middle;
    }
  }*/

  .views-field-field-date-1,
  .views-field-field-date {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    font-family: $header-family;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1em;
    z-index: 100;
    @include text-shadow(1px 1px 1px #000000);

    .year {
      text-align: right;
      font-size: 0.75em; //font-weight: normal;
    }
  }

  /*  .views-field.views-field-title {
      position: absolute;
      padding: 5px;
      line-height: normal;
      margin: 0;
      background-color: rgba(0, 0, 0, 0.8);
      display: block;
      bottom: 4px;
      left: 4px;
      right: 4px;
      @include border-radius(3px);
      font-size: 11px;
      opacity: 0;
      @include transition(opacity .15s ease-in-out);

      a {
        color: #fff;
        text-decoration: none;
      }
    }

    h3 {
      display: block;
      clear: both;
      margin: 0;
      padding: 0;
      border-bottom: solid 1px #eee;
      font-size: 20px;
      text-transform: uppercase;
      color: $bright-blue !important;
      padding-top: 1.5em;
    }*/
}


#block-views-featured_photos-block_1 {
  .block-inner {
    width: 100%;
    float: left;
    margin-bottom: 2em; //margin-top: 70px;
  }

  .views-row {
    width: 33.3%;
    margin: 0;
  }

  .view-content {
    .views-field {
      padding: 1px;
    }
  }
}


/**
 * Meet the writers/authors page.
 */
.view-authors {
  .view-header {
    font-size: 1.3em;
    line-height: 1.4em;
    letter-spacing: normal;
  }

  .form-actions {
    display: none;
  }

  .view-content {
    //padding-top: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: calc($padding / 2) $padding;

    > h3 {
      border-bottom: 1px solid #EEEEEE;
      color: $bright-blue !important;
      font-size: 20px;
      margin: $padding 0 0 0;
      text-transform: uppercase;
      clear: both;
      float: none;
      padding: 0 1em 0 0;
      width: 100% !important;
      display: block;
      grid-column: 1/-1;
    }


    .views-row {
      //float: left;
      width: 100%;
      padding: 5px;
      display: flex;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;
      font-size: 14px;
      line-height: 1.4em;

      img {
        vertical-align: middle;
      }

      .form-item {
        margin: 0;
      }

      .form-actions {
        display: none;
      }

      .description {
        display: none;
      }
    }

    .picture-wrapper {
      //float: left;
      margin-right: 1em;
      width: 60px;
      display: block;

      img {
        width: 100%;
        height: auto;
        @include border-radius(100%);
        background-color: #eee;
      }
    }

    .location {
      font-style: italic;
      font-size: 0.85em;
      color: #888;
    }

    .user-info {
      flex: 1;
    }

    .username {
      font-size: 1.2em;
      font-weight: bold;
      //margin-bottom: 5px;
      //margin-top: 3px;
      //margin-left: 70px;
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
}


/*.row .gallery_item_uncropped,
.views-row .gallery_item_uncropped,
.row .gallery_item,
.views-row .gallery_item {*/


.gallery_item {
  width: 100%;
}

//.node-list.node-teaser,
.gallery_item_uncropped,
.gallery_item {
  line-height: normal;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  text-align: left;

  .file-video {
    img {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .potd-badge {
    top: 5px;
    right: 5px;
    left: auto;
    z-index: 9999;
    opacity: 0;
    @include transition(opacity .25s ease-in-out);

    .trophy {
      width: 70px;
      height: 70px !important;
    }

    .badge-text {
      display: block;
      text-align: center;
      font-size: 12px;
      margin-top: -5px;
    }
  }

  .meta {
    z-index: 1;
    padding: 5px !important;
    line-height: 13px;
    position: absolute;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.6);
    @include transition((bottom .25s ease-in, opacity .25s ease-in-out));
    bottom: 15px;
    left: 10px;
    opacity: 0;
    @include border-radius(3px);

    span {
      margin-right: 10px;
      font-weight: bold;
      color: #ccc !important; //$off-black;
    }

    /* i {
        font-weight: normal;
        color: #fff; //#999;
      } */
  }

  .small-stars {
    z-index: 1;
    left: inherit; //opacity: 0;
    right: 10px;
    bottom: 10px;
    color: #eee;
    background-color: rgba(0, 0, 0, 0.6); //@include transition((bottom .25s ease-in, opacity .25s ease-in-out));
    @include border-radius(3px);

    .star-wrapper {
      font-size: 0.8em;
    }
  }

  .no-votes {
    font-size: 11px;
  }

  .flag-chosen-contest-entry {
    //.select-entry{
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 18px;
    bottom: inherit;
    left: inherit;
    display: inline-block;
    color: #fff;
    background: rgba(0, 0, 0, 0.6); //opacity: 0;
    z-index: 99;
    text-align: center;
    line-height: 1.6em;
    border-radius: 3px;
    padding: 0 0.3em;

    a {
      color: #fff;
    }

    .unflag-action {
      //color: $green !important;
    }
  }

  .item-details {
    position: absolute;
    top: 5px;
    opacity: 0;
    left: 0;
    right: 0;
    padding: 5px;
    padding-bottom: 10px;
    color: #fff;
    z-index: 99;
    -webkit-backface-visibility: hidden; // background-color: rgba(0,0,0,0.6);
    @include trans-black_gradient-top(); //@include text-shadow(1px 2px 1px rgba(0,0,0,0.7));
    @include transition((top .15s ease-in, opacity .15s ease-in-out));

    .title {
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff !important;
    }

    a {
      color: #fff !important;
    }

    span.username,
    a.username {
      color: #bbb !important;
    }

    .user-picture {
      width: 30px;
      /* img {
          @include border-radius(2px); //border: solid 1px #000;
        } */
    }

    .submitted {
      margin-left: 35px;
      margin-top: 0;
    }
  }

  &:hover {
    .item-details {
      top: 0;
      opacity: 1;
      @include transition((top .15s ease-in, opacity .15s ease-in-out)); // @include transition(top .25s ease-in-out);
    }

    /* .select-entry{
         opacity: 1;
        @include transition((top .15s ease-in, opacity .15s ease-in-out));
      } */
    .meta {
      bottom: 10px;
      opacity: 1;
      @include transition(bottom .25s ease-in, opacity .25s ease-in-out);
    }

    .potd-badge {
      display: block;
      opacity: 1;
      @include transition(opacity .25s ease-in-out);
    }
  }

  .manage {
    display: block;
    position: absolute;
    bottom: 15px;
    right: 10px;
    background: rgba(255, 255, 255, 0.8);
    padding: 0 3px;
    font-size: 11px;
    @include border-radius(2px);
    opacity: 0;
    @include transition(bottom .15s ease-in-out, opacity .25s ease-in-out);

    a {
      margin: 3px;
      line-height: 1.2em;
      height: 1.2em;
      display: inline-block;
      text-decoration: none;
      padding: 0 3px;
      color: #444;
      font-size: 14px;

      &:hover {
        color: #000;
      }

      span.label {
        display: none;
      }
    }
  }

  //&:hover .small-stars,
  &:hover .manage {
    opacity: 1; //@include transition(opacity .25s ease-in-out);
    bottom: 10px;
    @include transition(bottom .25s ease-in-out, opacity .25s ease-in-out);
  }

  .field-name-field-existing-image {
    .gallery_item_uncropped,
    .gallery_item {
      padding: 0;
      display: block;
    }

    .field-name-field-photo {
      margin: 0;
    }
  }

  .field-name-field-lead-image {
    margin: 0;
  }

  div.title {
    padding: 3px 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;

    a {
      color: $off-black;
      text-decoration: none;
    }
  }

  .user-picture {
    width: 20px;
    float: left;

    .badge {
      display: none;
    }
  }

  div.submitted {
    //white-space:nowrap;
    //overflow: hidden;
    margin-left: 25px;
    margin-bottom: 0;

    .username {
      font-weight: normal;
      letter-spacing: normal;
    }
  }
}

.node.full {
  .node-content {
    line-height: 1.6em;

    p {
      margin: 0.5em 0 1em 0;
    }

    ul,
    ol {
      padding-bottom: 1em;
      padding-top: 0.3em;
    }

    /* div:not(.image-compare) {
       img {
         margin: auto; //width: unset;
         max-width: 100%;
         height: auto;
         vertical-align: middle;
       }
     }*/
  }

  .view-profile-photos {
    .row {
      width: 10%;
      float: left;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .portfolio-thumbs {
    margin: 0.4em 0 0 0;
    padding: 5px 0;
    border-top: solid 1px #eee;
    list-style: none;
    float: left;
    width: 100%;

    li {
      margin: 0;
      padding: 3px;
      float: left;
      width: 11.0%;

      img {
        width: 100%;
        height: auto;
        vertical-align: middle; //border: solid 2px #ccc;
        opacity: 0.6;
        @include transition(opacity .15s ease-in-out);
      }

      a:hover img {
        opacity: 1;
        @include transition(opacity .15s ease-in-out);
      }

      a.active img {
        opacity: 1;
      }
    }
  }
}

#node-delete-confirm {
  #edit-actions {
    text-align: center;
    padding-bottom: 1em;
  }
}

.confirm-page.page-node-delete {
  h1.title {
    font-size: 28px !important;
    padding: 0 !important;
  }

  .view-content {
    padding-bottom: 15px;
  }

  .preview-image {
    padding: 5px 0 15px 0;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .preview-title {
    padding: 0;
    color: #000;
  }

  .preview-author {
    font-size: 12px;
    padding: 0;
  }

  #main {
    max-width: 600px;
    min-width: auto;
  }

  #content {
    min-height: auto;
    border: solid 1px #ddd;
    border-radius: 5px;
    padding: $padding calc($padding * 2);
    font-size: 14px;
    margin-top: $padding;
  }
}


#user-register-form {
  .captcha {
    padding-left: 0; //$form-label-width;
    float: left; //margin-bottom: 2em;
  }

  fieldset,
  fieldset#edit-profile-profile {
    border: none;
    padding: 0;
    margin: 0;

    legend {
      display: none;
    }
  }
}

div.fancybox-container div.form-item div.password-suggestions,
#page div.form-item div.password-suggestions {
  display: none !important;
}

.field-name-field-gear-list,
.field-name-field-social-media {
  //float: left;
  // width: 100%;
  .field-items {
    float: left;
    padding: 5px 0;
  }

  span {
    display: none;
  }

  .icon-wrapper {
    float: left;
    padding: 1px;
    text-align: center;
    // font-size: 26px;
  }
}

.avatar-wrapper {
  float: left;
  width: 60px; //background-color: #eee;
  .unread {
    z-index: 1;
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: middle; //@include border-radius(3px);
    //overflow: hidden;
  }

  &.avatars-2,
  &.avatars-3,
  &.avatars-4 {
    /* img {
        @include border-radius(0);
      }
      @include border-radius(3px);
      overflow: hidden; */
  }

  .badge {
    background-size: 100%;
    display: block;

    &.writer {
      color: $bright-blue !important;
      background-image: url(../images/fs-verified.png); // width: 100%;
      // height: auto;
    }

    &.mod {
      //color: $bright-blue !important;
      background-image: url(../images/fs-star-yellow.png); // width: 100%;
      // height: auto;
    }
  }
}

.avatars-2,
.avatars-4,
.avatars-3 {
  img {
    border: solid 2px #fff;
  }

  .user-picture {
    margin-top: -20px;

    .badge {
      display: none;
      background: none !important;
    }

    &:first-child {
      margin: 0;
    }
  }
}


.view-achievement {
  .view-content,
  .view-footer {
    display: inline;

    .view,
    .view-content {
      display: inline;
    }
  }

  .views-row {
    display: inline-block;
    vertical-align: top;
    max-width: 95px;
    padding: 5px;
    margin: 5px;
    position: relative;

    .views-field-aid {
      background-color: #ff0000;
      color: #fff;
      font-size: 13px;
      padding: 3px 5px;
      border-radius: 100px;
      display: inline-block;
      float: right;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.achievement {
  .badge-label {
    text-align: center;
    font-size: 10px;
    padding-top: 3px;
    text-transform: uppercase;
    color: #333;
  }
}

#achievement-user {
  .user-info {
    font-weight: bold;
    clear: both;
    font-size: 16px;
    padding: 10px 0;
    color: #000;
  }

  .user-picture {
    max-width: 40px;
    margin-right: 5px;
    vertical-align: middle;
    display: inline-block;
    float: none;
  }
}


.notifications-wrapper {
  .view-content {
    max-width: 800px;
    position: relative;
  }

  .view-content h3 {
    font-family: $default-family;
    color: $dark-grey !important;
    border-bottom: solid 1px #ddd !important;
    margin: 0 0 5px 0 !important;
    padding: 15px 0 5px 0 !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }

  .view-empty {
    font-size: 16px;
    text-align: center;
    padding: 2em;

    i {
      margin-bottom: 0.3em;
      color: #888;
    }
  }

  .views-row,
  .row {
    position: relative;
    padding: 1em 0;
    width: 100%;
    border-bottom: solid 1px #eee;
    font-size: 14px;

    &:last-child {
      border: none;
    }

    .field-name-field-comment-ref,
    .field-name-field-node-ref {
      position: relative;
      z-index: 1;
    }

    .unread {
      background-color: #ff0000;
      color: #fff;
      font-size: 9px;
      text-transform: uppercase;
      position: absolute;
      left: -5px;
      top: 0px;
      right: inherit;
      padding: 0 3px;
      line-height: 14px;
      @include border-radius(3px);
      z-index: 1;
    }

    .preview-image {
      float: right;
      margin: 0 0 0 1em;

      .content {
        margin: 0 !important;
      }

      img {
        width: auto;
        height: auto;
        max-height: 60px;
        max-width: 85px;
        vertical-align: middle;
      }
    }
  }
}

.view-activity-notifications {
  .entity-message {
    &.selected {
      background-color: #eee;
    }

    &:hover {
      background-color: #c6ddef; //$pale-blue;
    }

    a.message-dropdown-trigger {
      font-size: 16px;
      color: #333;
      display: inline-block;
      padding: 3px 0px 3px 7px;

      &.jq-dropdown-open,
      &:hover {
        color: $bright-blue !important;
      }
    }

    .message-link {
      display: block; //width: 100%;
      margin-right: 25px;
    }

    .message-drop-wrapper {
      float: right;
      position: absolute;
      top: 10px;
      right: 10px; //z-index: 99999;
      .jq-dropdown {
        font-size: 12px;
      }

      //padding-left: 5px;
    }

    //border-bottom: dotted 1px #ddd;
    // padding: 3px;
    // background: #f7f7f7;
    // margin: 3px 0;
    a:link,
    a:visited,
    a {
      text-decoration: none;
      color: #000;
    }

    .avatar-wrapper {
      width: 40px;
    }

    .content {
      margin-left: 50px; //margin-right: 25px;
    }

    .date {
      font-size: 11px;
      font-style: italic;
      color: #999;
    }

    p {
      margin: 0;
      padding: 0;
    }

    .comment-teaser {
      font-style: italic;
      font-size: 11px;
      margin-top: 0.2em;
      line-height: normal;
    }
  }
}

.content .view-activity-notifications {
  .views-row {
    padding: 0; ///border: none;
    .entity-message .message-link {
      padding: 1em; //border-bottom: solid 1px #eee;
    }
  }
}

.following-more a,
a.more-link,
.more-link a {
  @include border-radius(2px);
  background-color: $bright-blue;
  color: #FFFFFF;
  display: inline-block;
  font-size: 11px;
  line-height: 19px;
  padding: 0 5px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: normal;
  margin: 2px;

  &:hover {
    background-color: $darker-blue;
  }
}


form .field-multiple-table td.field-multiple-drag {
  padding-left: 7px;
  width: 35px;
}

.draggable a.tabledrag-handle {
  margin: 0;
  padding-top: 0.5em;
  padding-right: 0 !important;
}

tr.draggable {
  &.odd,
  &.even {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border-bottom: 1px solid #EEEEEE;
  }
}

a.tabledrag-handle .handle {
  background: url("../images/table-drag.png") no-repeat scroll 0 -77px rgba(0, 0, 0, 0);
  height: 10px;
  width: 20px;
  margin: 0;
  padding: 0;
}

a.tabledrag-handle:hover .handle {
  background-position: 0 -91px;
}

table {
  tr {
    &.drag {
      &:hover td,
      &.even:hover td,
      &.odd:hover td,
      td,
      td.active {
        background: $bright-blue !important;
      }
    }
  }
}

/*.view-exif {
  .view-content {
    font-size: 11px;
    padding: 0 0.5em;
    line-height: normal;

    .row {
      padding: 2px 0;
      !*border-bottom: solid 1px #eee; *!
      div {
        margin-left: 90px;
      }

      .field-name-field-tags {
        div {
          margin: 0;
        }

        .field-item {
          margin-right: 7px;
          white-space: nowrap;
          display: inline;
          float: left;
          line-height: 14px;
        }
      }
    }

    span {
      float: left;
      display: block;
      font-weight: bold;
      font-size: 11px;
    }

    span.date-display-single {
      float: none;
      font-weight: normal;
    }

    .views-field-term-node-tid {
      span.field-content {
        margin-left: 90px;
        display: block;
        float: none;
        font-weight: normal;

        a {
          margin-right: 5px;
        }
      }
    }
  }
}*/


.block.form-instructions {
  .content {
    font-size: 1.5em;
    line-height: 1.3em;
    font-weight: bold;
    padding-top: 20px;
    color: #000 !important;
  }
}


/*
Site Search
==========================
*/

/*
#search-block {
  float: right;

  .views-submit-button {
    display: none;
  }

  #edit-k {
    max-width: 200px;
  }

  #edit-k-wrapper {
    float: none;
  }

  #edit-t-wrapper {
    margin: 0;
    padding: 0;
    font-size: 11px;
    float: none;

    .form-type-radio {
      line-height: normal;
      float: left;
      padding-right: 1em;
    }

    label {
      font-weight: normal;
    }
  }
}
*/


blockquote {
  background-color: #f7f7f7 !important;
  padding: 0.5em 1em 0.5em 3em !important;
  margin: 1em 0;
  //font-weight: normal;
  //font-style: normal;
  text-align: left;
  /* makes block not to align behind the image if they are in the same line */
  overflow: auto;
  clear: both;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.1 22.32"><path fill="%23999" fill-rule="evenodd" d="M0 11.16v11.16h11.16V11.16H4.78c0-3.52 2.86-6.38 6.38-6.38V0C5 0 0 5 0 11.16Zm27.1-6.38V0c-6.16 0-11.16 5-11.16 11.16v11.16H27.1V11.16h-6.38c0-3.52 2.86-6.38 6.38-6.38Z"/></svg>') no-repeat left 1em top/1.6em;
  background-position: 1rem 1rem;
  border-left: solid 3px #999;

  p {
    margin: 0.5em 0;
  }

  &.tiktok-embed {
    padding: 0 !important;
    background: transparent;
    background-image: none;
  }
}

/*.view-community-home-members,
.view-community-home-photos {
  clear: both;
  float: none;

  .views-row {
    width: 25%;
    float: left;
    overflow: hidden;
    position: relative;

    .views-field {
      padding: 0 5px;
    }

    img {
      width: 100%;
      height: auto;
    }

    .views-field-name,
    .views-field-title {
      //opacity: 0;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      position: absolute;
      top: 4px;
      left: 4px;
      right: 4px; //display: none;
      padding: 5px;
      font-weight: bold;
      opacity: 0;
      @include transition(opacity .15s ease-in-out);
      @include border-radius(3px);

      .author {
        font-size: 11px;
        font-weight: normal;
        color: #999;

        strong {
          color: #fff;
        }
      }

      .title {
        @include oneline();
        font-size: 11px;
        font-weight: normal;
      }
    }

    &:hover .views-field-name,
    &:hover .views-field-title {
      //opacity: 1.0;
      //display: block;
      opacity: 1;
      @include transition(opacity .25s ease-in-out);
    }
  }
}*/

/*.view-community-home-lists {
  .views-row {
    width: 33.3%;
    float: left;
  }

  .node-list.node-teaser {
    position: relative;
    padding: 0; //margin: 0 10px 10px 0 !important;
    overflow: hidden;
  }
}*/

//.fs-tabs-nav ul,
ul.popular-tabs,
ul.quicktabs-tabs {
  float: none;
  display: block;
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    text-decoration: none;
  }

  li.active {
    font-weight: bold;

    a {
      background: $bright-blue;
      color: #fff;
    }
  }

  li {
    list-style: none;
    font-size: 13px;
    text-transform: uppercase;
    margin: 0 10px 0 0;
    display: inline-block;
    float: left;
    padding: 0;

    .ajax-progress {
      padding: 0;
      vertical-align: middle;
      margin-left: 1em;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      background: $light-grey;
      color: $dark-grey;
      float: left;
      display: block;
      @include border-radius(3px);
      //font-family: $header-family;
      line-height: 2.5em;
      padding: 0 1em;
      vertical-align: middle;

      &:hover {
        background-color: $dark-grey;
        color: #fff;
      }
    }
  }
}

ul.popular-tabs {
  padding-top: calc($padding / 2);
  display: inline-block;

  a.active {
    font-weight: bold;
    background: $bright-blue;
    color: #fff;
  }
}

.view-articles-by-type {
  clear: both;

  .view-header {
    padding: 1em 0;
    font-size: 1.1em;
  }
}

.quicktabs_main {
  clear: both;
  float: none;
  min-height: 150px;
}


.entity-product-license.full {
  //padding: 0.5em;

  .node-product.license {
    //padding-bottom: 1em;
  }
}

/*.node-product.license__REMOVE {
  position: relative;
  padding-top: 1.2em; //font-size: 18px;
  // border-top: solid 1px #eee;
  // margin-top: 0.5em;
  display: inline-block;
  width: 100%;
  padding: 1.2em 5px 0 5px;

  h2 {
    clear: both;
    margin: 0;
    padding: 0;
    font-size: 25px;
    padding: 1em 0
  }

  .field-name-field-caption {
    clear: both;
    padding: 0; //2em 0 0 0;
  }

  .catalog-image {
    float: left;
    width: 30%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .title-wrapper {
    font-weight: 300;
    line-height: 1.7em;
    padding-left: 2em;
    padding-top: 0.5em;
    color: #000;
    margin-left: 30%; //font-family: $header-family;
    .title {
      font-size: 1.8em;
      line-height: 1em; //letter-spacing: -0.03em;
    }

    .subtitle {
      font-size: 0.7em;
      display: block;
      padding-top: 0.1em;
      line-height: 1em;
    }

    .instructor {
      font-size: 1.1em;
      padding-top: 0.5em;
      color: #999;
    }

    a {
      color: #000;
      text-decoration: none;
    }

    .names {
      //color: #000;
    }
  }
}*/

.node-product.preview {
  position: relative; //padding-top: 0.5em;
  // border-top: solid 1px #eee;
  // margin-top: 0.5em;
  display: inline-block;
  width: 100%;
  vertical-align: middle;

  .catalog-image {
    float: left;
    width: 150px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .title-wrapper {
    //font-weight: 300;
    //line-height: 1.8em;
    padding-left: 1.5em;
    color: #000;
    margin-left: 150px;
    //padding-top: 0.3em; //font-family: $default-family;
    .title {
      //font-size: 1.8em;
      font-size: 1.3em;
      line-height: 1em;
      font-weight: 500;
    }

    .subtitle {
      font-size: 0.75em;
      display: block;
      padding-top: 0.1em;
      line-height: 1.3em;
      font-weight: normal;
    }

    .instructor {
      font-size: 0.85em;
      //padding-top: 0.5em;
      color: #999;
    }

    a {
      color: #000;
      text-decoration: none;
    }

    .names {
      //color: #000;
    }
  }
}

/*   .addtocart-popup,
  .add-cart-message-wrapper {
    .node-product.preview {
      vertical-align: middle;
      padding: 0;

      .title-wrapper {
        font-size: 12px;
        padding-left: 1.5em;
        font-family: $default-family;
      }
    }
  } */

.views-table {
  .node-product.preview {
    vertical-align: middle;
    padding: 1em 0;

    .title-wrapper {
      font-size: 11px;
      padding-left: 1.5em;
    }
  }

  .views-field-title {
    min-width: 250px;
  }
}

td.views-field-order-number,
td.views-field-placed {
  white-space: nowrap;
}

.responsive-table {
  overflow-x: auto;
}

/*  table.responsive{
    margin: auto;
    border-collapse: collapse;
    overflow-x: auto;
    display: block;
    width: fit-content;
    max-width: 100%;
    //box-shadow: 0 0 1px 1px rgba(0, 0, 0, .1);
  } */


// @todo - is this the old nav dropdown or the notifications drop?
.drop-contents {
  .node-product.preview {
    .catalog-image {
      width: 110px;
    }

    .title-wrapper {
      margin-left: 110px;
    }
  }
}


.node-product.feature {
  position: relative;
  overflow: hidden;

  .feature-copy {
    position: absolute;
    top: -500px;
    left: 1em;
    width: 55%;
    background-color: rgba(0, 0, 0, 0.85);
    color: #aaa;
    padding: 2em;
    font-size: 14px;
    line-height: 1.4em;
    @include transition(top 0.2s ease-in-out);
    font-family: $default-family;
    text-align: left; //opacity: 0;
  }

  .title-wrapper {
    font-weight: 300;
    line-height: 1.8em;
    padding-bottom: 1em;
    color: #fff;
    font-family: $header-family;

    .title {
      font-size: 34px;
      line-height: 1em;
      font-weight: 600;
    }

    .subtitle {
      font-size: 24px;
      display: block;
      padding-top: 0.1em;
      line-height: 1em;
      font-weight: 300;
    }

    .instructor,
    .instructors {
      font-size: 14px;
      padding-top: 1em;
      font-weight: 500;
      color: #ccc;
      font-family: $default-family;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    .names {
      color: #fff;
    }
  }

  .price {
    font-size: 35px;
    line-height: 1.1em;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    letter-spacing: $header-spacing;
    vertical-align: bottom;
    font-family: $header-family;

    .prefix {
      font-size: 0.6em;
      vertical-align: top;
      font-weight: 600; //color: #666;
    }

    .cents {
      font-size: 0.7em;
      vertical-align: top;
      font-weight: 600;
    }

    .dollars {
      vertical-align: top;
    }
  }

  .on-sale {
    .field-name-commerce-price {
      .price {
        text-decoration: line-through;
        color: #666;
        font-size: 30px; //padding: 0 6px;
        //background-color: transparent;
      }
    }

    .field-label,
    .field-items {
      vertical-align: middle;
      display: inline-block;
      float: none;
    }

    .field-label {
      text-transform: uppercase;
      color: $green;
      font-size: 16px;
    }
  }

  .buttons {
    padding-top: 1em;

    .field-name-field-products {
      display: inline-block;
    }

    .field-name-field-products .form-submit,
    .soldout,
    a {
      display: inline-block;
      margin: 0 5px 0 0; //width: 48%;
      font-size: 16px;
      font-family: $header-family;
      line-height: 3em;
      padding: 0 2em;

      &.button-buy {
        border: solid 1px $bright-blue;
      }

      &.button-view {
        border: solid 1px #333;
        background-color: rgba(0, 0, 0, 1);
        color: #ccc;

        &:hover {
          //border-color: $bright-blue;
          background-color: #333;
          color: #fff !important;
        }
      }
    }

    .soldout {
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      background-color: #ff0000;
      @include border-radius(2px);
    }
  }

  /* &:hover{
      .image-fade .catalog-img-0{
        opacity: 0;
      }
      .feature-copy{
        top: 1em;

        //opacity: 1;
      }
    } */
}


.node-product.teaser {
  margin: 0;
  padding: 0;
  font-family: $default-family;
  position: relative;
  max-width: 380px;

  .content {
    font-size: 14px;
  }

  .inner {
    min-width: 0 !important;
  }

  .catalog-image {
    //float: left;
    //width: 100%;
    margin-bottom: 5px;
    position: relative;
    //background-color: #ccc;

    .product-images-wrapper {
      overflow: hidden;
      border-radius: 5px;
      vertical-align: middle;
      background-color: #eee;
      border: solid 1px #eee;
    }

    img {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
      line-height: normal;
      //border: solid 1px #eee;
    }

    /* .image-fade {
      position: relative;
      .catalog-img-0 {
        position: absolute;
        top: 0;
        left: 0;
      }
    } */
    .m1-logo {
      position: absolute;
      bottom: 5px;
      left: 5px;
      border: none;

      img {
        border: none;
      }
    }

    .sale-tag {
      background-image: url("../images/sale-tag.png");
      background-position: top right;
      background-repeat: no-repeat;
      color: #FFFFFF;
      font-size: 15px;
      font-weight: bold;
      position: absolute;
      top: 5px;
      left: -3px;
      z-index: 1;

      .label {
        line-height: 2.7em;
        padding: 0 20px 0 10px;
        display: block;
      }
    }

    .original {
      display: none;
      /* background: $bright-blue;
        color: #fff;
        text-transform: uppercase;
        font-family: $header-family;
        font-size: 11px;
        display: block;
        position: absolute;
        bottom: 10px;
        left: -3px;
        padding: 0 5px;
        font-weight: 700;
        line-height: 17px; */
    }
  }

  .new-product {
    background-image: url("../images/red-badge.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    color: #FFFFFF;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: 50px;
    line-height: 49px;
    position: absolute;
    right: -5px;
    text-transform: uppercase;
    top: -5px;
    width: 50px;
    text-align: center;
  }

  .product-copy {
    padding: 0.2em 0 0 0.2em;
    display: flex;
    width: 100%;
    align-content: flex-start;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .title-wrapper {
    //width: 78%;
    //float: left;
    padding-right: 0.4em;
    font-size: 16px;
    /* width: 100%;
      display: inline-block; */
  }

  .title {
    font-weight: 600;
    font-size: 1em;
    line-height: 1.15em; //letter-spacing: -0.015em; //$header-spacing;
    padding-bottom: 0.2em;
    //float: left;
    a {
      text-decoration: none;
      color: #000; //$off-black;
    }
  }

  .subtitle {
    font-size: 0.85em;
    display: block;
    line-height: 1.15em;
    padding-top: 0.2em;
    font-weight: normal;
    letter-spacing: normal; //color: #666;
  }

  .instructor {
    padding-top: 0; //3em;
    color: #666;
    display: inline-block;
    width: 100%;
    font-weight: normal;
    font-size: 0.70em;

    .names {
      font-weight: 500;
      color: #111;
    }
  }

  .price-wrapper {
    //width: 22%;
    //float: right;
    text-align: center;
    font-family: $header-family;
  }

  .field-name-field-produced-by {
    //display: none;
    .field-name-field-lead-image {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      padding: 4px 6px;
      top: 5px;
      left: 5px;
      border-radius: 3px;

      border: none;
      margin: 0;
      width: auto;
      float: none;
      height: auto;

      .field-item {
        float: none;
        width: auto;
        padding: 0;
      }

      img {
        max-width: 50px;
        max-height: 30px;
        //image-rendering: -webkit-optimize-contrast;
        border: none;
        vertical-align: middle;
        display: block;
      }
    }
  }

  @media only screen and (max-width: $mobile-max-width),
  only screen and (max-device-width: $mobile-max-width) {
    .field-name-field-produced-by {
      display: none;
    }
  }

  .price {
    font-size: 20px;
    line-height: 1.1em;
    padding: 0.4em;
    border-radius: 3px;
    background-color: #eee;
    color: $bright-blue;
    font-weight: bold;
    display: inline-block;
    letter-spacing: -0.05em; //$header-spacing;
    margin-bottom: 5px;

    .prefix {
      font-size: 0.6em;
      vertical-align: top;
      font-weight: 600; //color: #666;
    }

    .cents {
      font-size: 0.7em;
      vertical-align: top;
      font-weight: 600;
    }

    .dollars {
      vertical-align: top;
    }
  }

  .commerce-price-savings-formatter-list {
    .price {
      //text-decoration: line-through;
      color: #999;
      font-size: 16px;
      padding: 0;
      background-color: transparent;
    }
  }

  .on-sale {
    .field-name-commerce-price {
      .price {
        //text-decoration: line-through;
        color: #999;
        font-size: 16px;
        padding: 0 6px;
        background-color: transparent;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          left: -0.05em;
          top: 40%;
          right: -0.05em;
          border-top: 0.12em solid;
          border-color: inherit;

          -webkit-transform: rotate(-7deg);
          -moz-transform: rotate(-7deg);
          -ms-transform: rotate(-7deg);
          -o-transform: rotate(-7deg);
          transform: rotate(-7deg);
        }
      }
    }
  }

  .field-name-field-description {
    padding-top: 1em;
  }

  .field-name-field-product-category {
    color: $bright-blue;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
    position: absolute;
    top: -20px;
  }

  /* .instructor {
      padding-top: 0.5em;
      color: #999;
    } */
}

// end product teaser


/*   .node-instructor {
    .view-store-catalog {
      clear: both;
      //padding-top: 3em;

      .content {
        margin: 0;
        padding: 0;
      }
    }
  } */

.node-product {
  &.teaser,
  &.feature {
    .image-fade {
      position: relative;

      .catalog-img-0 {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        @include transition(opacity 0.3s ease-in-out);
      }
    }

    a:hover {
      .image-fade {
        .catalog-img-0 {
          opacity: 0;
        }
      }
    }
  }
}


// I think this is the carousel / slider for portfolios that is shown on images in popups
#profile_photos-block_4 {
  margin: 0 40px;

  .swiper-slide {
    display: inline-block;
    vertical-align: top;
    float: left;
    width: auto;

    .file-video,
    .photo {
      height: 200px;

      img {
        height: 100% !important;
        width: auto;
      }
    }
  }

  /* .swiper-slide-active{
      display: none;
    } */
  .swiper-scrollbar {
    margin: 10px 0px;
    background-color: #444;
    cursor: pointer;

    .swiper-scrollbar-drag {
      background-color: #fff;
      opacity: 0.7;
      cursor: pointer;
      height: 7px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .swiper-pagination {
    //display: none;
    position: relative;
    top: 0;
    padding: 0 0 10px 0;
    //padding: 25px 0 0 0;
    .swiper-pagination-bullet {
      width: 13px;
      height: 13px;
      display: inline-block;
      border-radius: 100%;
      background: #fff;
      opacity: .4;

      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}

.view-profile-photos.view-display-id-block_4 {
  .view-content {
    position: relative;
  }

  .load-more-wrapper,
  .page-load-status {
    display: none !important;
  }

  .swiper-button {
    color: #fff;
    position: absolute;
    background-color: #000;
    padding: 10px;
    width: auto;
    display: inline-block;
    top: 30%;
    z-index: 9;
    opacity: 0.5;
    outline: none;

    &:hover {
      opacity: 1;
    }

    &.swiper-button-disabled {
      opacity: 0.1 !important;
      cursor: default !important;
    }
  }

  .sw-button-next {
    right: -40px;
  }

  .sw-button-prev {
    left: -40px;
  }
}


/* .view-related-products {
  .views-row {
    width: 33.3%;
  }
} */


// @todo - do i need this?
/*
.sidebar {
  //.view-members-using-product,
  .view-users-following {
    .views-row {
      float: left;
      width: 16.66%;

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        vertical-align: middle;
        @include border-radius(3px);
      }

      a {
        display: block;
        padding: 2px;
      }
    }

    .view-empty {
      padding: 0;
      font-size: 13px;
    }
  }
}
*/

.profile2-profile.teaser {
  display: flex;
  //gap: 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-size: 16px;
  gap: 1em;

  .user-picture {
    width: $avatar-width;
    flex: 0 0 $avatar-width;
    float: left;
  }

  .user-info {
    //margin-left: $avatar-width + 10px;
    //padding-top: 5px;
    width: 100%;
    font-size: 16px;
    line-height: 1.2em;
    flex: 1;

    div {
      width: 100%;
    }

    .name {
      @include multiline-clamp($lines: 2);
      padding: 0;
      //margin-bottom: 4px;

      a {
        text-decoration: none;
        color: $off-black;
      }
    }

    .field-name-field-location {
      color: #999;
      font-size: 12px;
    }
  }
}

//.view-profile-search,

/*.view-profile-followers,
.view-users-following,
.view-subscribe-user,*/
.profile-lists {
  //@include no-rollovers();
  //@include no-active();

  .view-content {
    clear: both;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1em 4em;
  }

  .view-content > .views-row {
    //display: inline-block;
    vertical-align: top;
    //width: 33%;
    //padding: 10px 0;
    //float: left;
    width: 100%;
    //padding: 5px;
    //display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 14px;
    line-height: 1.4em;
  }

  .views-field-picture {
    width: 70px;
    margin-right: 15px;
    float: left;
  }


  .views-field-name {
    font-weight: bold;
    font-size: 16px;
    padding: 0.3em 0;

    a {
      text-decoration: none; //font-size: 14px;
      //font-weight: bold;
      color: #000;
    }
  }

  /*.views-row{
    padding: 10px 0;
  }*/

  .views-field-count {
    font-size: 11px;
  }

  .views-field-field-location {
    //font-style: italic;
    font-size: 12px;
    color: #999;
  }

  a.flag {
    float: left;
    line-height: 24px;
    color: #fff;
    background-color: $green;
    text-decoration: none;
    @include border-radius(2px);
    font-size: 11px;
    padding: 0 5px;
    margin-top: 5px;
    text-transform: uppercase;
    min-width: 100px;
    text-align: center; //font-family: $header-family;
    //font-weight: 500;
    &.unflag-action {
      background-color: #ddd;
      color: $dark-grey !important;

      &:hover {
        background-color: $delete-red !important;
      }
    }
  }
}


/*.view-profile-search {
  clear: both;
}*/

//.view-profile-search,
//.profile-lists,


// The followers popup on profiles.
.view-profile-followers {

  .views-field-rendered-entity {
    margin-right: 110px;
  }

  // the user follow flag wrapper
  .views-field-php,
  .views-field-ops {
    float: right;
    text-align: center;
    font-size: 10px;
    width: 100px; //border: solid 1px #eee;
    //padding: 5px;
    a.flag {
      width: 100%;
      text-align: center;
    }

    .follow-count {
      color: #ccc;

      a.followers {
        text-decoration: none !important;
        font-weight: normal;
        color: #ccc;

        strong {
          font-weight: normal;
        }
      }
    }
  }
}


// @todo - Remove?
/*
#block-views-popular_profiles-block {
  .views-field-rendered-entity {
    margin-right: 0;
  }
}
*/

.no-following {
  .views-field-rendered-entity {
    margin-right: 0 !important;
  }
}

.view-categories {
  .view-header {
    padding: 1em 0;
  }

  .view-content {
    ul {
      column-count: 3; //column-rule: solid 1px #eee;
      list-style-type: none;
      font-size: 18px;
      line-height: 1.2em;
      padding: 10px;

      li {
        margin: 0;
        padding: 0.2em 0;
      }
    }
  }
}

.helpers-share {
  padding: 0;
  float: right;
  font-size: 12px;
  font-family: $header-family;

  .total-shares-wrapper {
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
    color: $off-black;
    font-size: 11px;
    text-transform: uppercase;
    text-align: right;

    .total-shares {
      font-size: 20px;
      font-weight: 600;
      padding: 4px 0 3px 0;
      text-align: right;
    }

    span {
      //display: none;
      font-size: 9px;
    }
  }

  .social-shares {
    margin: 0;
    display: inline-block;
    vertical-align: middle;

    .sharing-icon {
      width: 37px;
      height: 36px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      line-height: 36px;
      padding: 7px; //margin-right: 5px;
      //@include center-vertical();
      @include border-radius(2px);

      .icon-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
      }

      svg {
        fill: #fff; //max-height: 75%;
        //max-width: 75%;
        text-align: center;
        margin: 0 auto;
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
      }
    }

    .facebook .sharing-icon {
      background-color: #3b5998;
    }

    .twitter .sharing-icon {
      background-color: #000;
      //background-color: #00aced;
    }

    .share-item {
      display: inline-block;
      margin: 0 0.3em 0 0;
      padding: 0;
      cursor: pointer;

      &:hover {
        color: #202121 !important;
      }

      &.facebook:hover .sharing-icon {
        background-color: #2d4373;
      }

      &.twitter:hover .sharing-icon {
        background-color: #0087ba;
      }
    }

    .label {
      display: none;
    }
  }
}

// @todo: i think this can be removed.
/*#waterfall {
  .item {
    //padding: 5px;
    border: solid 1px #eee;
    width: 230px;
    @include box-shadow(0,
      1px,
      2px,
      0,
      rgba(0,
        0,
        0,
        0.1));
    background: #fff;

    .title {
      border-bottom: solid 1px #eee;
      background: #fff;
      margin-bottom: 3px;
      padding: 0 5px 3px 5px;
      font-weight: bold;

      a {
        color: #000;
        text-decoration: none;
      }
    }

    .user {
      font-size: 11px;
      font-weight: bold;
      padding-left: 5px;

      a {
        text-decoration: none;
        color: #000;
      }

      .avatar {
        max-width: 30px;
        float: left;
        margin-right: 5px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .comments {
      float: right;
      font-size: 11px;
      color: #ccc;
      padding-right: 5px;
    }
  }

  .photo {
    max-width: 230px;
    padding: 5px;
    background: #fff;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      vertical-align: middle;
      line-height: normal;
    }
  }
}*/

// @todo: what is this?
/*.view-popular-photos-data {
  .pager {
    display: none;
  }

  .view-content {
    float: left;
    width: 100%;

    .item {
      width: 237px;
      margin-bottom: 20px;
      background-color: #fff;
      @include box-shadow(0,
        1px,
        2px,
        0,
        rgba(0,
          0,
          0,
          0.1));
      border: solid 1px #eee;
      line-height: normal;

      &:hover {
        border: solid 1px #aaa;
      }

      .photo {
        padding: 5px 5px 0 5px;

        img {
          width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }

      .title {
        padding: 0 5px 2px 5px;

        a {
          text-decoration: none;
          color: #000;
        }
      }

      .user {
        padding: 0 5px;
        font-size: 11px;
        border-top: solid 1px #eee;
        padding-top: 2px;

        a {
          text-decoration: none;
          color: #999;
        }
      }
    }
  }
}*/

.node-featured-photo.full {
  padding-top: 0.5em;

  .potd-badge {
    opacity: 1;
    @include transition(opacity .15s ease-in-out);
  }

  .potd-badge:hover {
    opacity: 0;
    @include transition(opacity .15s ease-in-out);
  }

  .photo {
    margin: 0.5em 0 1em;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  header h2 {
    font-size: 1.5em;
    font-family: $default-family;
    font-weight: bold;
  }

  div.title {
    font-size: 1.5em;
  }

  .comment-count {
    font-size: 11px;
    background: none;
    padding: 0;
    text-align: left;

    a {
      color: #888;
      font-weight: normal;
    }
  }

  .field-name-field-date {
    color: $bright-blue;
    padding: 0.7em 0;
    font-size: 2.2em;
    letter-spacing: $header-spacing;
    font-weight: normal;
  }

  .node-photo {
    clear: none;
  }
}

.field-name-field-existing-image {
  font-size: 14px;

  .field-name-field-photo {
    margin: 0.5em 0 1em;
    clear: both;
  }
}

/*
.node.header .featured-photo {
  position: relative;

  .pn_link {
    //background-color: #ccc;
    width: 50%;
    position: absolute;
    top: 0;
    height: 100%;

    a {
      display: block;
      width: 100%;
      height: 100%;
      background-image: url(../images/next-back-arrows.png); //background-position: 0 -9999px;
      background-repeat: no-repeat;
      text-indent: -3999px;
      overflow: hidden;

      &:hover {
        //background-color: #fff;
      }
    }
  }

  // @todo: does pn_* class still exist? (previous/next)
  .pn_next {
    right: 0;

    a {
      background-position: 103% center;
      opacity: 0;
      background-color: transparent;
      @include transition(background-position .25s ease-in-out,
        opacity .25s ease-in-out);
    }

    a:hover {
      background-position: 100% center;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      @include transition(background-position .25s ease-in-out,
        opacity .25s ease-in-out);
    }
  }

  .pn_prev {
    left: 0;

    a {
      background-position: -3% center;
      opacity: 0;
      background-color: transparent;
      @include transition(background-position .25s ease-in-out,
        opacity .25s ease-in-out);
    }

    a:hover {
      background-position: 0 center;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      @include transition(background-position .25s ease-in-out,
        opacity .25s ease-in-out);
    }
  }
}
*/

.selected-by {
  font-size: 11px;
  padding-bottom: 10px;

  a {
    text-decoration: none;
    font-weight: bold;
    color: $off-black;
  }
}

#node-header {
  .video-wrapper,
  .photo-wrapper,
  .featured-photo {
    text-align: center;
    position: relative;
  }

  .photo {
    text-align: center; //cursor: url('/sites/all/themes/fstop/images/zoomin.cur'), -moz-zoom-in, -webkit-zoom-in, zoom-in, pointer;
    /*         cursor: -moz-zoom-in;
        cursor: -webkit-zoom-in;
        cursor: zoom-in; */
    img {
      ////width: 100%;
      width: auto;
      max-width: 100%;
      height: auto;
      vertical-align: middle;
      display: block;
      cursor: -moz-zoom-in;
      cursor: -webkit-zoom-in;
      cursor: zoom-in;
    }
  }

  /* .actions-wrapper .fivestar-container{
      padding-right: 20px !important;
    } */
}

.node.full .userinfo-wrapper {
  .userinfo {
    padding: 0 0 10px 0;
  }

  .user-picture {
    width: 60px;
  }
}

.portfolio-item.full {
  .content {
    .node-content {
      //margin-right: 500px;
      h3 {
        font-size: 12px;
        color: $bright-blue !important;
        border-bottom: solid 1px #eee;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-family: $default-family !important;
        letter-spacing: normal !important;
      }
    }

    h1.title {
      clear: both;
      display: block;
      margin-left: -70px;
      padding-top: 10px;
    }

    .userinfo-wrapper {
      .userinfo {
        padding: 0;

        .flag-wrapper {
          display: block;
          margin: 0;
          padding-top: 2px;
        }
      }
    }

    .date {
      font-size: 12px;
      color: #888;
      padding-bottom: 15px;
    }
  }

  /* .node-content-wrapper{
    float: left;
    margin-right: -500px;
    width: 100%;
    position: relative;
  } */
  /* .comment-col {
    float: right;
    width: 500px;
    position: relative;

    //position: relative;
    .comment-wrapper {
      padding-left: 20px;
      margin-left: 20px;
      border-left: solid 1px #eee;
      box-sizing: border-box;
    }
  } */
  .sticky-comments {
    position: fixed;
    top: $nav-height;
    width: 480px;
  }

  /*   .sticky-comments-bottom{
    position: absolute;
    top: inherit;
    bottom: 0;
    width: 480px;
  } */
}

.node.full {
  .content-block {
    margin: 1em 0;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 10px 0;
    margin: 10px 0;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #eee;

    &.tags {
      margin-left: 0 !important;
      border: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        list-style: none;
        margin-right: 0;
        margin-bottom: 3px;
        margin-left: 0; //font-size: 11px;
      }
    }
  }

  .image-details {
    //margin-right: 40px;
    //min-width: 45%;
    i {
      font-size: 0.35em;
      vertical-align: middle;
      padding: 0 0.5em;
    }
  }

  .image-details,
  .image-tags {
  }

  .image-tags {
    padding-left: 1.5em;
  }
}

// @todo: I think this can be removed, not using h2 for comments?
/*
#comment-thread {
  h2.comment-count {
    font-size: 18px;
    text-transform: uppercase;
  }
}
*/

.potd-badge {
  text-align: center;
  color: #fff; //width: 160px;
  height: auto !important;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10px;

  a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
  }

  .trophy {
    //display: none !important;
    background-image: url(../images/fs-potd-badge2.png);
    background-repeat: no-repeat;
    width: 60px;
    height: 60px !important;
    background-size: contain;
    display: inline-block !important;
    vertical-align: middle;

    i {
      display: none;
    }

    .potd-label {
      display: none !important;
    }
  }

  .badge-text {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
  }

  .badge-date {
    font-size: 14px;
    font-weight: bold; //padding-top: 108px;
    text-transform: uppercase;
    line-height: 1.2em;
    display: block; //padding-bottom: 10px;
  }

  .year {
    font-size: 13px;
    font-weight: normal;
    display: block;
  }
}


// @todo add to error page only css?
.page-error {
  text-align: center;
  font-family: $header-family;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
  font-weight: 300;

  &.no-header .logo {
    margin: 3em auto 0;
    max-width: 520px;
    text-align: center;
    padding: 0 1em;
  }

  #page {
    padding-bottom: 0;
    border-bottom: solid 1px #ddd;
  }

  #content {
    text-align: center;
    padding: 2em;
    font-size: 1.5em;
    background: #fff;
    min-height: auto;
  }

  #main {
    padding-bottom: 0;
  }

  h1,
  h3 {
    font-weight: 300;
    padding: 0;
    margin: 0;
  }

  h1.title {
    font-size: 35px;
    font-weight: 300;
    letter-spacing: -0.03em;
    padding: 10px;
    padding-bottom: 1em;
  }

  h3 {
    font-size: 25px;
    color: #777 !important;
    margin-bottom: 40px;
  }

  .main-stage-wrapper {
    padding: 0;
    margin-top: 0 !important;
  }

  .button {
    display: inline-block;
    margin: 5px;
    text-transform: uppercase;
  }

  .button a {
    border-radius: 3px;
    background-color: #1680d3;
    font-size: 16px;
    display: inline-block;
    line-height: 50px;
    padding: 0 20px;
    text-decoration: none;
    color: #fff;
    border: solid 1px #1680d3;
  }

  .button a:hover {
    color: #1680d3;
    background-color: #fff;
  }

  .button i {
    margin-right: 10px;
  }

  #content-after {
    background: none;

    .inner {
      margin: auto;
      max-width: 600px;

      .button {
        float: none;
      }
    }
  }

  ul.action-links {
    float: none;

    li {
      display: inline-block;
      float: none;
    }
  }
}

.page-node {
  #content {
    //font-size: 1em;
    //line-height: 1.1em;
    position: relative;
    z-index: 1;

    //h1.title {
    //font-size: 3.4em;
    //}
  }
}

.sidebar {
  position: relative;
  z-index: 2;
  font-size: 13px;
  line-height: 1.5em;
}

.section-community {
  .more-button {
    padding: 2em 0 0.5em 0;
    text-align: center;

    a {
      display: inline-block; //background: $bright-blue;
      color: #fff;
      font-family: $header-family;
      letter-spacing: $header-spacing;
      text-transform: uppercase;
      font-weight: 700;
      padding: 0 10px; // display: inline-block;
      line-height: 2.2em;
      font-size: 16px;
      float: none;
      text-decoration: none;
      text-align: center;
      @include border-radius(2px);
      @include gradient(#0f6fb9,
        $bright-blue);
      @include text-shadow(-1px -1px 0 rgba(0,
        0,
        0,
        0.3)); //@include box-shadow(0, 1px, 2px, 0, rgba(0,0,0,0.3));
      border: solid 1px #0d6ab2;

      &:hover {
        background-color: $darker-blue;
        @include gradient($darker-blue,
          #0f6fb9);
      }
    }
  }

  #fold {
    .more-button {
      padding: 1em 0;
    }
  }
}


/* .workbench-info-block {
  .workbench {
    float: left;
    margin-right: 1em;
  }
} */
.unpublished {
  color: #ff0000;
  font-weight: bold;
  background: none;
}

.page-node-rating {
  #content-area {
    h2,
    .block-title {
      text-transform: uppercase;
      color: $bright-blue;
      margin: 0;
      padding: 0;
    }
  }
}


form.confirmation {
  font-size: 1.4em;
  line-height: 1.3em;

  ul {
    //margin-bottom: 1em;
    padding-left: 1em;
    font-size: 1.2em;
    margin-left: 0;

    li {
      padding-bottom: 0.7em;
    }
  }
}


// @todo Where is this used? Contests?
// Yes, the contests nav.
.sub-navigation {
  text-align: left;
  width: 100%;
  display: inline-block;
  background: #fff;
  border-bottom: solid 1px #ddd;
  margin-bottom: 5px;

  .contest-title {
    float: left;
    line-height: 35px;
    font-size: 14px;
    padding: 0 10px;
    margin: 10px 0;
    display: none;

    .field-name-field-contest-type {
      display: inline-block;
      margin-right: 10px;
      border-right: solid 1px #ddd;
      padding-right: 10px;
    }

    .title {
      display: inline-block;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none; //font-family: $header-family;
    //letter-spacing: $header-spacing;
    //text-transform: uppercase;
  }

  .item-list ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 20px;
    line-height: 55px;
    font-weight: 500;
  }

  a {
    text-decoration: none;
    display: inline-block;
    color: #333;
    padding: 0 25px; //overflow: hidden;
    i {
      color: #888;
      margin-right: 5px;
    }

    &:hover {
      color: $bright-blue;

      i {
        color: $bright-blue;
      }
    }

    &.active {
      color: $bright-blue;
      background-image: url(../images/nav-marker.png);
      background-repeat: no-repeat;
      background-position: center bottom -3px;

      i {
        color: $bright-blue;
      }
    }

    &.add-contest-results {
      float: right;
      height: auto;
      line-height: 2.5em;
      padding: 0 1em;
      margin: 10px 0;
      color: #fff;
      font-family: $header-family;
      font-size: 12px;
    }
  }

  .item-list {
    display: inline-block;
    margin-bottom: -1px;
  }
}


/* body.node-type-contest{
    .node-header{
      .content{
        margin-top: 300px;
      }
    }
  } */

.help-wrapper {
  display: inline-block;
}


.node-contest-entry {
  &.gallery_item_uncropped {
    .item-details {
      .submitted {
        font-size: 12px;
        margin-left: 32px;
        padding-top: 5px;
      }

      .user-picture {
        width: 25px;
      }
    }
  }

  /*.node-content {
    font-size: 16px;
  }*/

  .contest-entry-date {
    clear: both;
    padding-top: 5px;
    padding-bottom: 0 !important;
  }

  .node.portfolio-item {
    h1.title {
      font-size: 20px !important;
    }

    .date {
      display: none;
    }

    .userinfo-wrapper {
      .user-picture,
      .username {
        display: none;
      }
    }
  }
}

// @todo: I think popular profiles can be removed.
// The new view is popular_profiles2
/*.view-popular-profiles {
  &.view-display-id-block {
    .badge {
      display: none;
    }
  }

  .view-content {
    display: block;
    clear: both;
    float: none;
  }
}*/

// @todo: I dont think im using QT for community_members any more.
// This can probably be removed.
/* #block-quicktabs-community_members {
  border-bottom: solid 1px #eee;
  .view-popular-profiles {
    .row {
      width: 33%; //height: 60px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .user-picture {
    width: $avatar-width + 10px;
  }
  .user-info {
    margin-left: $avatar-width + 20px;
    font-size: 12px;
  }
  .name {
    font-size: 14px;
  }
} */


.nsfw-body {
  .media-element-container {
    display: none;
  }
}

.nswf-wrapper {
  padding: 1em;
  background: #ffefb1;
  color: #884400;
  @include border-radius(5px);
  font-size: 1.3em;
  text-align: center;
  margin: 1em 0;
  line-height: 1.3em;

  .nsfw-warning {
    font-weight: bold;
  }
}

#block-fs_helpers-nsfw {
  .block-inner {
    //padding: 2em;
    font-size: 1.6em;
    line-height: 1.3em;

    .actions {
      padding: 1em;
      text-align: center;
    }

    strong {
      color: #000;
    }
  }
}

.register-popup {
  font-size: 2em;
  line-height: 1.3em;
  text-align: center;
  padding: 1em 2em;
}


/*#quicktabs-following {
  .view-empty {
    padding: 5px 0;
    font-size: 12px;
    text-align: left;
  }
}*/


.over-limit {
  #content-inner {
    text-align: center;
    font-size: 1.3em;
    padding: 4em 2em;
  }

  #content-area {
    line-height: 1.7em;
    font-size: 1.3em;
  }

  h1.title {
    color: $bright-blue; //font-family: $default-family;
    font-size: 2.8em;
  }
}

.cols-3 td {
  width: 33.3%;
}

.followers-popup {
  min-width: 500px;
  overflow: hidden;

  h3 {
    text-transform: uppercase;
    background-color: $light-grey;
    color: $dark-grey;
    display: block;
    @include border-radius(3px);
    padding: 5px;
    margin: 0;
  }

  .view-users-following {
    .view-content {
      //min-width: 450px;
      //width: 500px;
    }

    table {
      border: none;
    }

    td.views-row {
      float: none; //min-width: 275px;
      border-bottom: solid 1px #eee;
      border-right: none; //padding: 1em 0;
    }

    table.views-view-grid {
      border: none;
      width: 100%;
    }

    .username {
      text-decoration: none;
      color: #000;
      font-weight: bold;
      font-size: 1.1em;
    }
  }

  .cols-2 td {
    width: 50%;
  }
}

/*#quicktabs-following {
  .views-field-picture {
    margin: 2px;
  }
}*/


.block {
  .more {
    float: right;
    font-family: $default-family;
    font-size: 11px;
    letter-spacing: normal; // padding-right: 5px;
    a {
      text-decoration: none;
      color: #fff;
    }
  }
}

.page-node-add,
.page-node-edit {
  #content {
    h1.title {
      //font-family: $default-family;
      border-bottom: solid 1px #eee;
      color: $bright-blue;
      font-size: 1.7em !important;
    }
  }
}

form .cancel {
  margin-left: 10px;
}

.node {
  .meta {
    span {
      color: #555;
      font-weight: normal;
      margin-right: 5px;
    }

    i {
      color: #ccc;
      margin: 0;
    }
  }
}

.infinite-scroll {
  .pagination .item-list ul.pager,
  ul.pager {
    clear: both;
    display: block;
    text-align: center;
    float: none;
    padding: 0 !important;
    margin: -2em 0 0 0;
    width: 100%;
    list-style: none;

    li {
      float: none;
      width: 100%;
      list-style: none;
      display: none;

      a {
        float: none;
        display: block;
        font-size: 18px;
        line-height: 2.5em;
        padding: 0.6em 1.5em !important;
        text-decoration: none;
        text-transform: uppercase;
        @include border-radius(2px); //background-color: $bright-blue;
        background: #fff;
        border: solid 1px #ccc;
        color: #888;

        &:hover {
          background-color: $darker-blue;
          border-color: $darker-blue;
          color: #fff;
        }
      }
    }
  }
}

.node-group {
  &.teaser,
  &.feature {
    .count {
      //      font-size: 12px;
      margin-right: 10px;
    }
  }
}

.node.node-group.node-teaser {
  //padding: 1em 1em 0 0;
  position: relative;
  margin: 0;
  line-height: 1.5em;
  background: #fff; //border: solid 1px #eee;
  max-width: 400px;

  .group-title,
  h2 {
    font-family: $default-family;
    font-size: 18px;
    letter-spacing: normal;
    margin: 0 0 5px 0;
    padding: 0;
    font-weight: bold;

    a {
      text-decoration: none;
      color: $off-black;
      @include multiline-clamp(3);
    }
  }

  .count {
    color: #888;

    i {
      color: #666;
    }
  }

  .field-name-field-lead-image {
    width: 120px;
    float: left;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;

    img {
      width: 100%;
      height: auto;
      border: none;
    }
  }

  .content {
    margin-left: 120px;
    padding: calc($padding / 2) 0 0 $padding;
  }
}

.group-grid {
  .view-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: $padding;
  }

  .more-link {
    float: left;

    a {
      font-size: 14px;
      padding: 5px 10px;
      display: inline-block;
      float: none;
      line-height: normal;
      text-transform: none;
    }
  }

  &.view-profile-groups {
    .view-content {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
}


#block-ajax_modal-editors_picks_footer,
#block-fs_helpers-editors_picks_footer {
  .infinite-scroll {
    display: block;
    clear: both;
    padding: 4em 0 4em 0;

    ul.pager li {
      display: block;
    }
  }
}

// @todo: I think search related css can move to its own file.
/*
.search-members {
  text-align: center;
  font-size: 1.4em;
  padding: 2em 0;
  background: #f7f7f7;
  border-radius: 3px;
  margin-bottom: 4em;
  border: solid 1px #eee;
  margin-top: 1em;

  form {
  }

  button {
    font-size: 1.3em;
    padding: 0 20px;
    height: auto;
    vertical-align: top;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  input.search-text {
    font-size: 1em;
    width: 90%;
    width: 100%;
    padding-right: 70px;
  }

  .search-fields-wrapper {
    width: 100%;
    max-width: 400px;
    position: relative;
    text-align: center;
    margin: auto;
  }
}
*/

#block-fs_helpers-editors_picks_footer {
  .infinite-scroll {
    ul.pager {
      margin: 0;
    }
  }
}

#user-cancel-confirm-form {
  //padding-top: 3em;
  //font-size: 1em;
  padding-top: 2em;

  .form-actions {
    text-align: left !important;

    input.form-submit {
      width: auto;
    }

    #edit-cancel {
      padding: 0 2em;
    }
  }

  .form-item-confirm {
    padding-top: 1em;
  }

  label {
    float: none;
    display: block;
    clear: both;
    width: auto;
    color: $off-black;

    &.option {
      display: inline;
    }
  }

  .description {
    margin-left: 0;
  }

  .form-item {
    margin: 0.5em 0;
  }
}

#block-fs_helpers-popular_tags {
  .content {
    .item-list {
      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      li {
        @include tags();
      }
    }
  }
}

#block-views-popular_tags-block {
  border-bottom: solid 1px #eee;

  .view-content {
    @include no-rollovers();

    table {
      border: none;

      td {
        font-size: 1.2em;
        padding: 3px;
      }
    }

    .item-list {
      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      li {
        @include tags();
      }
    }
  }
}

/* ===============
Confirm form page
============
*/

.confirm-page {
  #main {
    padding: 1em;
    max-width: 900px;
  }

  h1.title {
    //font-family: $default-family;
    font-size: 2.8em;
    padding: 1em 0 !important;
  }

  h2 {
    //font-family: $default-family;
    font-size: 1.5em;
    padding: 0;
    font-style: italic;
  }

  #edit-actions,
  .edit-actions,
  .form-actions {
    text-align: left;
    padding: 2em 0;
    font-size: 14px; //line-height: 3em;
    /*input.form-submit{
      margin: 0 1em 0 0 !important;
    }*/
  }

  #edit-cancel {
    //float: right;
  }
}


// I think this what is now known as community index pages.
.popular-items {
  h1.title {
    font-family: $header-family;
    font-size: 30px;
    float: left;
    margin-right: 1em;
    padding: 0.3em 0;
  }

  #content-area {
    padding-bottom: 6em;
    padding-top: 0;
  }

  #content-header {
    padding: 0;
    //display: none;
  }

  #sidebar-first,
  #sidebar-second {
    // block headers
    .block .block-title {
      font-size: 12px;
      text-transform: uppercase;
      color: #1680d3;
      padding: 5px 0;
      font-weight: bold;
      letter-spacing: normal;
      font-family: $default-family;
      background: none; //@include border-radius(0);
      line-height: 1em;
    }
  }

  .popular-feed {
    clear: none;
  }
}

/* ===============
report abuse page
============
*/

.report-abuse {
  &.two-sidebars,
  &.sidebar-first {
    .center {
      margin-left: 35%;
    }
  }

  // Left value & negative left value.
  #sidebar-first {
    width: 35%;
    margin-right: -35%;

    .region-sidebar-first {
      padding-right: 2em;
    }

    .block-inner {
      ul {
        margin: 0 1em;
      }
    }
  }
}


.community-side-navigation {
  .content {
    font-size: 20px;
    line-height: 1.25em;

    h3 {
      font-family: $default-family;
      margin: 0;
      padding: 0 0 5px 5px;
      font-weight: normal;
      font-size: 11px;
      letter-spacing: normal;
      text-transform: uppercase;
      border-bottom: solid 1px #eee;
      margin-bottom: 5px;
    }

    .add-group-button {
      display: block;
    }

    .item-list {
      li,
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      /*li {
        font-size: 1.7em;
        line-height: 1.3em;
      }*/

      ul {
        margin-bottom: 2em;
      }

      a {
        text-decoration: none;
        color: $dark-grey;
        display: block;
        padding: 5px;
        @include border-radius(3px);

        &.active {
          color: $bright-blue; //font-weight: bold;
          background-color: #f7f7f7;
        }

        &:hover {
          background-color: #f7f7f7;
          color: $off-black;
        }
      }
    }
  }
}


.user-picture {
  position: relative;

  .badge {
    padding: 1px;
    color: $bright-blue;
    position: absolute;
    top: -3px;
    right: -3px; // @include border-radius(30px);
    line-height: normal;
    width: 15px;
    height: 15px;
    position: absolute;
    background-size: 100%;
    display: block;

    &.writer {
      background-image: url(../images/fs-verified.png);
    }

    &.mod {
      background-image: url(../images/fs-star-yellow.png);
    }
  }
}


.one-column-page {
  h1.title {
    margin-top: calc($padding * 2);
  }

  #main {
    #content {
      display: block;
      float: none;
      width: 100%;
      max-width: 900px;
      margin: auto;

      .node-form {
        .group-right,
        .group-left {
          width: 100%;
          float: none;
          display: block;

          .inner {
            padding: 0;
          }
        }
      }
    }

    //max-width: 600px;
  }
}

.narrow-page {
  h1.title {
    margin-top: calc($padding * 2);
  }

  #main {
    #content {
      display: block;
      float: none;
      width: 100%;
      max-width: 500px;
      margin: auto;

      .node-form {
        .group-right,
        .group-left {
          width: 100%;
          float: none;
          display: block;

          .inner {
            padding: 0;
          }
        }
      }
    }

    //max-width: 600px;
  }
}


.achievement-full {
  font-size: 18px;
  line-height: 1.4em;
  max-width: 600px;
  margin: auto;
  padding: 1em;

  .badge-wrapper {
    position: relative;
    width: 100%;
    max-width: 270px;
    margin: 40px auto;
    overflow: hidden;
    border-radius: 10px;
    border: solid 5px #000;

    img {
      width: 100%;
      height: auto;
    }
  }

  .field-name-field-description {
    padding: 20px 0;
    border-top: solid 1px #ddd; //border-bottom: solid 1px #ddd;
  }

  .achievement-type {
    .field-name-field-description {
      //padding: 0;
      border: none;
    }
  }

  .field-name-field-lead-image {
    position: absolute;
    top: 0;
    left: 0;
    /* max-width: 350px;
      height: auto;
      margin: 30px auto; */
  }

  .form-item-agree,
  .field-name-field-agreed {
    font-weight: bold;
    font-size: 20px;

    input {
      transform: scale(1.4);
      float: left;
      width: 24px;
      height: 24px;
    }

    label.option {
      font-weight: bold;
      font-size: inherit;
      padding-left: 0.5em;
      margin-left: 24px;
      display: block;
    }

    //padding-top: 30px;
  }
}


#edit-field-exif-date {
  .date-no-float {
    float: left;
    clear: none;
    width: auto;

    label {
      float: left;
    }

    .date-padding {
      padding: 0;
    }
  }
}

/*   a.top-scroll {
    position: fixed;
    bottom: 100px;
    right: 3em;
    text-decoration: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.80);
    font-size: 9px;
    padding: 1em;
    @include border-radius(3px);
    display: none;
    z-index: 999999;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.7);
  } */

#node-header .rate-widget-fivestar {
  //@include border-radius(2px);
  padding: 0; //5px;
  //background-color: #f7f7f7;
  margin: 0;
  line-height: normal;
  float: none;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0; //2px;
}

.fivestar-label {
  float: none; //margin-right: 1em;
  //display: none;
  .rate-description {
    font-size: 11px; //text-align: right;
    color: #fff; //$off-black;
    text-transform: uppercase; //font-weight: bold;
  }

  .rate-info {
    font-size: 11px;
    text-align: left;
  }
}

.fivestar-wrapper {
  &.community-show {
    text-align: left;
    color: #fff;
    padding-left: 10px;
    line-height: 1em;
    border-left: solid 1px #333;
    margin-left: 10px;

    .fivestar-label {
      display: inline-block;
      text-align: left;
      float: none;
      line-height: 1em;
    }

    .rate-info {
      color: #fff;
    }

    .rate-button {
      font-size: 15px;
      width: 15px;
      height: 15px;
    }
  }

  .avg-label {
    font-size: 11px;
    margin-top: 2px;
  }

  .avg-count {
    font-weight: 600;
    color: #fff;
    font-size: 22px;
    line-height: 1em;
  }

  .avg-total {
    font-size: 11px;
    color: #888;
    margin-left: 2px;
    color: #ddd;
  }
}

.fivestar-container {
  float: left !important;
  width: auto !important;
  min-width: initial !important;
}

.fivestar-stars {
  float: left;
  position: relative;
  line-height: 1em
}

.fivestar-wrapper {
  /*@include border-radius(2px);
  padding: 5px;
  margin-right: 5px;
  background-color: #eee;*/
  padding: 0; //0.5em;
  float: none;
  display: inline-block;
  vertical-align: top; //border-right: solid 1px #eee;
  position: relative;
  line-height: 1em;
  vertical-align: middle;

  ul {
    margin: 0;
  }
}


// @todo: is this ever shown in a block anymore?
#block-fs_helpers-fs_helpers_star_rating {
  .rate-description,
  .rate-info {
    color: #888;
  }

  .rate-description {
    padding-bottom: 3px;
  }

  .rate-fivestar-btn-empty i {
    color: #ddd;
    opacity: 1;
  }

  .background-fill {
    overflow: hidden;
    display: inline-block;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;

    i {
      color: #ffc600;
    }
  }

  .avg-label {
    color: #666;
    font-size: 11px;
  }
}

.vote_count {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  line-height: 1em;
  margin-left: 10px;

  .label {
    font-size: 11px;
    text-transform: uppercase;
    line-height: 1em !important;
    color: inherit !important;
    border: none !important;
    font-weight: normal !important;
    margin: 0;
    padding: 0;
  }

  .count {
    font-size: 25px;
    font-weight: 600;
    line-height: 1em;
    margin-left: 0;
  }
}

a.vote-help {
  vertical-align: top;
  display: inline-block;
  font-size: 13px;
  margin-left: 0;
  color: #666;
  line-height: normal;
  margin-right: -10px;
  margin-top: -7px;
  padding: 5px 7px !important;

  &:hover {
    color: #fff;
  }

  i {
    font-size: 1em;
    font-size: 20px !important;
  }
}

.rate-blocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  padding: 0.4em;
  @include border-radius(2px);
  font-weight: normal;
  line-height: 1.1em;
  font-size: 11px;
  z-index: 3;
}

/*.rate-user .rate-fivestar-btn-empty,
.rate-user .rate-fivestar-btn-filled{
  background: none;
}*/

.rate-user a.rate-fivestar-btn-filled,
.rate-user a.rate-fivestar-btn-empty {
  background-image: none !important;
}

.rate-widget {
  .rate-fivestar-btn-filled,
  .rate-fivestar-btn-empty {
    width: auto; //25px;
    height: auto !important; //line-height: 1.6em;
    display: block; //position: relative;
    // background-image:url(../images/fivestar.png) !important;
    background-image: none !important; // background-size: 100%  !important;
    @include border-radius(2px); //padding: 3px;
    // margin: 1px;
    //background-color: #eee;
    //display: block;
    margin-right: 2px;
    color: #fff !important;
    text-indent: inherit !important;
    font-size: 25px;
    text-align: center;
    position: relative;
    overflow: hidden;
    /* .background-fill{
    //position: absolute;
    //background: $bright-blue;
    top: 0;
    bottom: 0;
    left: 0;
  } */
    i {
      z-index: 2;
      position: relative;
    }
  }
}

.backLink {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  color: #ccc;

  a {
    color: #fff;
  }
}

.userinfo {
  font-family: $header-family;
  letter-spacing: $header-spacing;
  padding: 2em 0;

  h1.title {
    padding: 5px 0 0 0;
    font-size: 25px !important;
  }

  .flag-wrapper {
    display: inline-block;
    width: 110px;
    vertical-align: middle;
    margin-left: 10px;
    text-transform: uppercase;

    a {
      display: inline-block;
      font-size: 12px;
      color: #fff;
      letter-spacing: normal;

      i.fa {
        padding-right: 3px; //color: #fff;
      }
    }
  }

  a.flag {
    background-color: $green;
    padding: 0 7px; // line-height: 35px;
    color: #fff;

    &:hover {
      background-color: $dark-green;
    }

    &.unflag-action {
      background-color: #ddd;
      color: $dark-grey; // !important;
      &:hover {
        // background-color: $medium-grey  !important;
        // color: #ccc;
      }
    }

    .unfollow-label {
      color: #fff !important;

      .fa {
        color: #fff !important;
      }
    }

    .follow-label {
      color: #fff !important;

      .fa {
        color: #fff !important;
      }
    }
  }

  .username {
    color: #666;
    font-size: 19px;
    padding: 0;
    line-height: 28px;

    .username {
      display: inline-block;
    }

    a {
      text-decoration: none;
      color: #666;
      display: block;
    }
  }
}

.user-picture {
  max-width: 150px;
  float: left;
}

.user-details {
  margin-left: 70px;
}

.no-author .user-details {
  margin-left: 0;
}

.rate-fivestar-btn-empty {
  // background-position: 0 -40px !important;
  //background: #ccc !important;
  i {
    color: #666; //opacity: 0.2;
  }
}

.rate-fivestar-btn-filled {
  // background-position: 0 0 !important;
  //background: $bright-blue !important;
  i {
    color: #ffc600;
  }
}

.admin-modal-rating {
  .rate-fivestar-btn-filled {
    i {
      color: $bright-blue;
    }
  }
}

.rate-another {
  float: none;
  padding: 0 0 0 0.8em;
  display: inline-block;
  vertical-align: middle;

  a {
    display: block;
    background: $green;
    color: #fff !important;
    text-decoration: none;
    font-family: $header-family;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 35px;
    padding: 0 1em;
    @include border-radius(2px);
    font-weight: normal;
    letter-spacing: normal; //$header-spacing;
    &:hover {
      background: $dark-green;
    }
  }
}


.support-fstoppers {
  .content {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.tos-form-wrapper {
  text-align: center;

  #edit-agree {
    margin: 2em;
  }

  .tos-cancel {
    font-size: 14px;
    padding: 1em;
  }
}

.terms-scroller {
  overflow: scroll;
  margin: 1em 2em;
  border: solid 4px #ccc;
  height: 300px;
  padding: 2em;
}


.field-name-field-website {
  max-width: 100%;

  a {
    width: 100%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.author-block {
  margin: 0 0 1em 0;
  padding: 1em;
  border-radius: 3px;
  border: solid 1px #eee;
  background-color: #f7f7f7;
  display: block;
  float: left;
  width: 100%;

  .socicons {
    //    text-align: left;
    margin-right: 1em;
    font-size: 10px;

    .icon-wrapper {
      //      font-size: 32px;
      //      padding: 0 3px 0 0;
      //      margin: 0;
      //      text-align: left;

      a {

        background-color: #ddd;
        color: #888;
        margin: 0;
        padding: 0;

        &:hover {
          color: #000;
          background: #fff;
        }
      }

      float: none;
      display: inline-block;
      vertical-align: middle;

      a:hover svg {
        //transform: scale(1);
      }
    }
  }

  .author-wrapper {
    display: inline-block;
    width: 100%;
    padding-bottom: 1em;
  }

  .user-picture {
    width: 90px;
    height: 90px;

    img {
      border: solid 2px #ddd;
    }
  }

  .author-links {
    margin-left: 90px;
    padding-left: 1em;
  }

  .bio-header {
    line-height: 1em; //padding-top: 10px;
    padding-bottom: 10px;
  }

  .bio-title {
    font-size: 20px;
    color: #000;
    font-family: $header-family;
    font-weight: 800;
    vertical-align: middle; //padding-left: 3px;
    line-height: 1em;
    display: inline-block;
  }

  .flag-subscribe-user {
    display: inline-block;
    width: 150px;
    font-style: normal;
    text-align: center;
    margin-left: 15px;
    font-size: 12px;
    vertical-align: middle;
    float: none;

    .flag-link-toggle {
      float: none;
      display: inline-block; //background: $green;
      //border: solid 1px $green;
      text-decoration: none; //font-weight: 700;
      @include border-radius(2px);
      color: #fff !important;
      line-height: 2.7em;
      padding: 0 3px; //font-family: $header-family;
      text-transform: uppercase;
      font-size: inherit;
      width: 100%;

      &.unflag-action {
        background-color: #ddd;
        color: $dark-grey !important;
        border: solid 1px $medium-grey;

        &.hover {
          background-color: $delete-red;
        }
      }

      .fa {
        padding: 0 !important;
      }
    }

    .flag-throbber {
      display: none;
    }
  }

  .bio {
    font-size: 1em;
    line-height: 1.4em;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .field-name-field-website {
    padding: 0;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    float: none;
    /* max-width: 100%;
      a{
        width: 100%;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      } */
  }

  .field-name-field-social-media {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }
}


.highlight_container {
  animation: highlight_container 2.5s;
  -moz-animation: highlight_container 2.5s;
  -webkit-animation: highlight_container 2.5s;
}

@keyframes highlight_container {
  50% {
    background-color: #F8F99A;
  }
}

@-moz-keyframes highlight_container {
  50% {
    background-color: #F8F99A;
  }
}

@-webkit-keyframes highlight_container {
  50% {
    background-color: #F8F99A;
  }
}

#autocomplete {
  line-height: normal;
  //font-size: 14px;
  li {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  div.reference-autocomplete {
    // @include clearfix();
    margin: 0;
    padding: 2px;
    line-height: normal;
    height: 52px; //float: left;
    width: 100%;
    clear: both;
    display: inline-block;
    background: #fff;
    cursor: pointer;
    position: relative;
    border-bottom: solid 1px #eee;

    &:hover {
      background: $bright-blue;
    }
  }

  .image,
  .views-field-field-lead-image {
    cursor: pointer;
    display: block;
    float: left;
    height: 40px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 50px;

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .views-field-title {
    display: block;
    margin: 5px 0 5px 60px;
    font-size: 14px;
    word-wrap: normal;
  }

  .field-content {
    height: auto;
    margin: 0;
    padding: 0;
    display: inline-block;
  }
}

.field-name-field-gear .tabledrag-toggle-weight {
  display: none !important;
}

.field-name-field-gear .form-type-textfield input.form-text {
  width: 100%;
  max-width: 100% !important;
  margin-right: 0;
}

#content .field-name-field-product-variations,
.field-collection-view {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.field-name-field-product-variations {
  .field-name-field-lead-image {
    //float: left;
    //margin-right: 2em;
  }
}

/*
#block-fs_gear-gear_variations,
.view-gear-buy-links {
  .content .item-list ul {
    padding: 0;
    margin: 0;
  }

  .content .item-list li,
  .views-row {
    clear: both;
    padding: 0.5em;
    display: block;
    width: 100%;
    float: left;
    margin: 0;
  }

  .link {
    font-size: 14px;
    font-weight: bold;
    padding-top: 5px;
  }

  .lead-image,
  .views-field-field-lead-image {
    float: left;
    width: auto;
    clear: none;
    margin-right: 1em;

    img {
      margin: auto;
      width: auto;
      clear: none;
      border: solid 1px #eee;
    }
  }
}
*/

code {
  background: #eee;
  padding: 1em;
  margin: 1em 0;
}

.block.potd {
  font-size: 1.4em;
  line-height: 1.3em;
  padding: 1em 1em 0 1em;
  margin: 1em 0 !important;
  color: #666;
  background: #eee;
  border: solid 1px #ddd;
}

.rating-header {
  font-size: 1.7em;
  line-height: 1.4em;
  font-weight: normal;
  color: $off-black;
  margin: 0;
  float: left;
  width: 55%;
}

/*.rate-gear-wrapper {
  margin: 0 0 0 0; //padding: 1em;
  font-size: 1.2em; //background: #eee;
  float: right;
  display: block;
  width: 45%;

  .form-item {
    padding: 1em;
    border-left: solid 1px #eee;
    margin: 0 0 0 1em;
  }

  ul {
    margin: 0.3em 0 !important;

    li {
      margin: 1px !important;
    }
  }
}*/

.rating-header-wrapper {
  padding: 1em 0;
  margin-top: 1em;
  border-top: solid 1px #eee;
}

/*.view-gear-main-index {
  .views-row {
    float: left;
    width: 25%;
    padding-bottom: 2em;
  }

  .views-field {
    padding: 0 1em;
  }

  .views-field-name {
    text-align: center;
    font-size: 1.3em;
    padding-top: 0.5em;

    a {
      color: #000;
      font-weight: bold;
      text-decoration: none;
    }
  }
}*/

/*.gear-popular {
  .views-row {
    float: none;
    width: 100%;
    clear: both;
  }

  .views-field-pid {
    font-size: 11px;
    color: #aaa;
  }

  .views-field-name {
    color: $bright-blue;
    text-align: left;
    font-size: 1.9em;
    border-bottom: solid 1px #eee;
    margin-bottom: 0.3em;
    display: block;
    padding: 1.4em 0 0.3em 0 !important;
  }

  .views-field {
    padding: 0;
  }

  .view-popular-gear {
    .views-row {
      float: left;
      width: 14%;
      height: 200px;
      clear: none;
      padding: 0;

      .title a {
        font-size: 12px !important;
        font-weight: bold;
      }
    }

    .views-field {
      padding: 0;
    }

    .node.node-teaser {
      padding: 1em 1em 0 0;
    }
  }
}*/

// @todo: what is this?
/*.brand-search {
  float: right;
  z-index: 1;

  .block-title {
    font-size: 14px;
    font-family: $default-family;
    margin: 0;
    padding: 0 0 3px 0;
    letter-spacing: normal;
  }

  .form-item {
    margin: 0;
  }
}*/

/*#content-area .view-bhgear2 {
  .view-header {
    float: left;
    padding: 1em;
    background: #eee;
  }

  .view-content {
    clear: both;
  }
}*/


.description.description-above {
  padding-bottom: 0.5em;
}


.grey-button {
  line-height: 1.6em;
  padding: 0 0.4em;
  height: auto;
  background: #fff;
  border: solid 1px #ccc;
  color: #888 !important;
  display: inline-block;
  clear: none; //margin: 0 0.3em;
  @include gradient(#eee,
    #FFF);
  @include box-shadow(0,
    1px,
    1px,
    0,
    rgba(0,
      0,
      0,
      0.1));
  @include border-radius(3px);

  &:hover {
    background-color: #ddd;
    @include gradient(#ddd,
      #eee);
    border-color: #bbb;
  }
}

.user-info {
  //  position: relative;


}

div.entity.removed {
  opacity: 0.4;
}

.entity-profile2.teaser {
  //padding: 0.5em;
}

.stickyBlock {
  position: fixed;
}

#scrollgroup-1 {
  &.fadein {
    opacity: 1;
    @include transition(opacity .15s ease-in-out);
  }
}

.ToggleSwitch {
  margin: 0 0.4em;
  vertical-align: middle;
  float: right;
}


h2.popup-title {
  color: $bright-blue;
  font-size: 2em;
  padding-bottom: 0.4em;
  font-family: $default-family;
  letter-spacing: normal;
  border-bottom: solid 1px #eee;
}


.fontAwesome {
  font-family: $fa-family !important;
  font-style: normal;
  font-weight: 400;
}


.group-level {
  //font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
  background: #ccc;
  color: #FFF;
  padding: 0 10px;
  line-height: 2em;
  height: 2em;
  display: inline-block;
  @include border-radius(2px);
  margin-bottom: 3px;
}

.membership-button {
  //padding: 1em 0 0 0;
  font-size: 14px;
  line-height: 2.5em;

  a {
    //@include grey-button(14px, 2.1em);
    display: inline-block !important;
    padding: 0 10px;
    float: none;
    font-weight: bold;
  }
}

.mod-icon {
  background: url('../images/fs-star-yellow.png');
  padding: 1px;
  position: absolute;
  top: 7px;
  left: -3px;
  line-height: normal;
  width: 22px;
  height: 22px;
  position: absolute;
  background-size: 100%;
  display: block;
}


.fs-oembed {
  text-align: center;
  margin: 1.2em 0;
  position: relative;

  .pds-box,
  iframe {
    margin: auto !important;
    position: relative !important;
  }

  img {
    width: 100%;
    height: auto;
  }

  .getty {
    iframe {
      position: absolute !important;
      height: 100% !important;
    }
  }
}

// @toodo: I think this was for the homepage, it can maybe go
// or it may need to be in groups.css
/* .view-popular-discussions {
  .views-row {
    .user-picture {
      max-width: 30px;
      float: left;
      @include border-radius(2px);
    }
  }
} */
.preview-frame {
  width: 100%;
  min-height: 500px;
}

// @todo: this can go into accounts.css
// What is this?
.page-user-email-help {
  #content-area {
    .content {
      font-size: 1.3em;
      line-height: 1.4em;
      padding: 1em 0;

      ol,
      ul {
        padding: 0 1.5em;

        li {
          margin-bottom: 1em;
        }
      }
    }
  }
}

#profile-extras-unvalidated-email-change-form {
  label {
    width: 180px;
  }

  .form-submit {
    margin-left: 180px;
    margin-top: 10px;
  }
}

#fs-helpers-ig-text-form {
  .full-image {
    img {
      width: 100%;
      height: auto;
    }
  }
}

#edit-mailchimp {
  fieldset,
  fieldset .fieldset-wrapper {
    padding: 0;
    margin: 0;
    border: none;
  }
}

/*.form-item-htmlmail-plaintext,
#edit-commentaccess-settings{
  .description{
    margin-left: 0 !important;
  }
}*/

#modal-form-wrapper {
  width: 100%;
  max-width: 500px;
}


.recaptcha-element {
  margin: 0 0 2em 0;
}

#content-after {
  background: #fff;
  padding: 0 10px;

  .inner {
    padding-bottom: 1em;
    width: 100%;
    max-width: 1600px;
  }

  .block-inner {
    padding: 3em 0 !important;
  }

  .view-related-media,
  .view-editors-favorites {
    padding: 0 2px;

    .row,
    .views-row {
      float: left;
      width: 16.6%;

      .views-field {
        padding: 3px 3px 0 3px;
      }
    }
  }

  .block {
    width: 100%;
    display: inline-block;
  }

  .block-title {
    margin: 0;
    font-family: $default-family;
    font-size: 13px;
    text-transform: uppercase;
    color: $bright-blue;
    padding: 10px 5px;
    letter-spacing: normal;
  }

  // @todo: I think I can remove this.
  .view-popular-articles {
    display: inline-block;
    width: 100%;

    .view-content {
      display: inline-block;
      width: 100%;
      padding-bottom: 4em;
    }

    .views-row {
      width: 25%;
      float: left;
      padding: 0 1em !important;
    }

    .node {
      .inner {
        min-width: auto;
      }

      .photo-wrapper,
      .field-name-field-lead-video,
      .field-name-field-lead-image,
      .field-name-field-photo,
      .field-name-body {
        margin: 0 !important;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

fieldset#edit-browser {
  display: none;
  margin: 0;
}

// @todo: if im removing OneSignal I can get rid of this class.
.push-prompt {
  font-family: $header-family;
  position: fixed;
  top: -300px;
  right: 10px;
  width: 340px;
  max-width: 100%;
  font-size: 1.2em;
  z-index: 9999999;
  padding: 0;
  background: #fff;
  line-height: 1.3em;
  margin: 0;
  border: solid 1px #ddd;
  @include box-shadow(-2px,
    2px,
    10px,
    0px,
    rgba(0,
      0,
      0,
      0.4)); //@include border-radius(5px);
  .push-logo {
    float: left;
    width: 90px;
    height: auto;
    padding: 5px;
  }

  .inner {
    padding: 0; //1em;
    .content {
      margin-left: 90px;
      padding: 0.7em 0.7em;
    }
  }

  .button {
    //float: left;
    font-family: $header-family;
    margin: 0;
    width: 50%;
    margin: auto;
    float: none;
  }

  .buttons {
    padding: 0.5em;
    text-align: center;
    background-color: #f7f7f7;
    border-top: solid 1px #ddd;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
  }

  #no-subscribe-link {
    font-size: 12px;
    margin-left: 10px;
    color: #999;
    float: right;
    line-height: 2.5em;
    padding: 0 10px;
  }
}

.profile-tip {
  max-width: 300px;

  img {
    width: 100%;
    height: auto;
  }
}

.users-rated {
  .item-list {
    padding: 0;
    margin: 0;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 0;
      padding: 0 0 3px 0 !important;
      list-style: none;
      font-weight: bold;
      font-size: 13px;
      display: block;
      clear: both; //width: 100%;
      margin-bottom: 2px;

      a {
        text-decoration: none;
        color: #000;
      }

      .user-picture {
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.profile-image-wrapper {
  background-color: $light-grey;
}

.profile2-profile {
  .gradient-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}

/* Profile Preview Rollover popups
====================================
*/
.profile2-profile.preview {
  text-align: left;
  position: relative;
  margin: 0;
  padding: 0;
  width: 330px;
  // max-width: 350px;

  //padding-bottom: 4px;
  //display: inline-block;
  line-height: 12px;
  // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);

  .field-name-field-job-title {
    // @todo: Isnt the verified badge an SVG now?
    font-size: 14px;
    background-image: url('../images/fs-verified.png');
    background-repeat: no-repeat;
    background-size: 20px;
    padding: 2px 0 2px 25px;
    word-wrap: break-word;
    position: absolute;
    line-height: 20px;
    top: 5px;
    left: 5px;
    color: #fff;
    @include text-shadow(0 1px 3px rgba(0,
      0,
      0,
      0.9));
    z-index: 1;
  }

  .profile-image-wrapper,
  #profile-image-wrapper {
    img {
      width: 100%;
      height: auto;
    }
  }

  .user-info {
    position: relative;
    font-size: 12px;
    line-height: 12px;
    /* margin-left: 100px; */
    /* margin-top: -35px; */
    //background: rgba(255, 255, 255, 1);
    padding: 10px;
    clear: both;
    text-align: center;


    .item-list {
      font-size: 11px;
      width: 100%;
      display: inline-block;
      padding-bottom: 10px;

      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
        color: #888;
      }

      li {
        display: inline-block;
        padding-right: 4px;
        padding-bottom: 4px;
        float: left;

        &.last {
          float: right;
        }
      }

      a {
        text-decoration: none;
        color: #888;
      }
    }
  }

  .star-wrapper {
    font-size: 1.1em;
    margin-right: 1px;
    display: inline-block;
    position: relative;

    .star-empty {
      display: block;
      color: #ddd;
    }

    .star-fill {
      color: #ffbf14;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      display: block;
    }
  }

  .social-wrapper {
    position: relative;
    white-space: nowrap;
    padding: 5px 0 0 0;
    font-size: 9px;
  }

  .icon-wrapper {
    display: inline-block;
    margin: 0;
    padding: 0;
    float: none;
  }

  .field-name-field-social-media {
    display: inline-block;
    float: none;
    padding: 0;
    min-height: inherit;
    height: auto;
  }

  .field-name-field-location,
  .field-name-field-business-name {
    //font-size: 11px;
  }

  .field-name-field-location {
    //font-style: italic;
  }

  .name {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    padding-bottom: 4px;
    font-family: $header-family;
    line-height: 1.1em;

    a {
      text-decoration: none;
      color: #000;
    }
  }


  .picture-wrapper {
    width: 80px;
    margin: auto;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .user-picture {
    img {
      border: solid 2px #fff;
    }
  }

  .follow-count {
    display: none;
  }

  .flag-wrapper {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .flag {
    //border: solid 1px #fff;
    border-radius: 3px;
    display: block;
    padding: 0 5px;
    background: #ccc;
    line-height: 2.8em;
    font-size: 9px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    clear: both;
    border: solid 1px transparent !important;

    i {
      padding: 0;
    }

    &.flag-action {
      color: #fff;
      background-color: $green; //border-color: $green;
      &:hover {
        background-color: $dark-green; //border-color: $dark-green;
      }
    }

    &.unflag-action {
      color: #777;
      background-color: #ddd;
      border: solid 1px #fff !important;

      &:hover {
        border: solid 1px #fff !important; //color: #fff;
        //background-color: $delete-red;
      }
    }
  }

}


/**
 * Tipped Skins
 * ========================
 */

.tpd-skin-light .tpd-title {
  line-height: 13px;
  padding: 5px;
  font-size: 11px;
  font-weight: normal;
}

/* light */

.tpd-skin-fstop .tpd-content,
.tpd-skin-fstop .tpd-title,
.tpd-skin-fstop .tpd-close {
  color: #333;
}

.tpd-skin-fstop .tpd-background-content {
  background-color: #fff;
}

.tpd-skin-fstop .tpd-background {
  border-width: 1px;
  border-color: #838383;
  border-color: rgba(0, 0, 0, .3);
}

.tpd-skin-fstop .tpd-background-title {
  background-color: #f7f7f7;
}

.tpd-skin-fstop .tpd-title-wrapper {
  border-bottom: 1px solid #c0c0c0;
}

.tpd-skin-fstop .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, .15);
}

/* fallback for no/disabled shadow */

.tpd-skin-fstop.tpd-no-shadow .tpd-background {
  border-color: #acacac;
  border-color: rgba(100, 100, 100, .3);
}

.tpd-skin-fstop .tpd-spinner-spin {
  border-color: rgba(51, 51, 51, .2);
  border-left-color: #333;
}

.tpd-skin-fstop a {
  color: #808080;
}

.tpd-skin-fstop a:hover {
  color: #6c6c6c;
}

.tpd-skin-fstop .tpd-content,
.tpd-skin-fstop .tpd-title {
  padding: 0;
}


.view-content,
.portfolio-selector {
  width: 100%; //max-width: 1200px;
  .port-image {
    display: block;
    float: left;
    max-width: 14.2%;
    padding: 0;
    position: relative;
    min-width: 100px;

    a {
      display: inline-block;
      padding: 3px;
      position: relative;
      outline: none;
      text-decoration: none;
      border: solid 5px #fff;

      i {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        font-size: 4em;
        display: block;
        margin: auto;
        width: 100%;
        height: 50%;
        text-align: center;
        vertical-align: middle;
        top: 25%;
        color: #fff;
      }

      &:hover {
        background: $bright-blue;
      }
    }

    img {
      width: 100%;
      height: auto; //border: solid 1px #fff;
      vertical-align: middle;
    }

    a.selected-image {
      background: $bright-blue;

      img {
        //border: solid 3px $bright-blue;
        opacity: 0.4;
      }
    }

    a.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
}


.block-ajax-modal {
  padding-bottom: 8em;
}

.ajax-block {
  min-height: 300px;

  .spinner {
    padding: 3em;
    text-align: center;
    color: #ccc !important;
    display: block;
    float: none;
    font-size: 1.5em;
    padding-bottom: 6em;

    div {
      border-color: #ccc transparent #ccc transparent !important;
    }
  }

  &.ajax-none {
    .spinner {
      display: none;
    }
  }
}

.media-info {
  font-size: 1.3em;
  line-height: 1.3em;
  padding: 3em !important;
}


a.ajax-modal-close {
  float: right;
  font-size: 24px;
  color: #999;
  text-decoration: none;

  &:hover {
    color: #333;
  }
}

.node.ajax_node {
  padding: 20px;

  header {
    display: none;
  }

  .content {
    font-size: 1.2em;
    line-height: 1.4em;
    color: #333;

    .intro {
      font-size: 1.4em;
      line-height: 1.3em;
      color: #000;
      padding-bottom: 0.5em;
    }
  }

  h3 {
    font-size: 1.7em;
    padding: 0;

    .fa-star {
      color: #ffc600; //margin-right: 4px;
    }
  }
}

.ajax-content {
  width: 100%;
}

.loadingIconDark {
  width: 60px;
  margin: auto;

  .loaderBG {
    fill: #333;
  }

  .loaderFill {
    fill: #fff;
  }
}

.loadingIconLight {
  width: 60px;
  margin: auto;

  .loaderBG {
    fill: #ddd;
  }

  .loaderFill {
    fill: #666;
  }
}

.iconMed {
  width: 90px;
}

.logoFSMask {
  //transform-origin: 50% 50%;
  -webkit-animation: loaderMaskFS 1.5s infinite;
  -moz-animation: loaderMaskFS 1.5s infinite;
  animation: loaderMaskFS 1.5s infinite;
}

.logoCircleMask {
  //transform-origin: 50% 50%;
  -webkit-animation: loaderMaskCircle 1.5s infinite;
  -moz-animation: loaderMaskCircle 1.5s infinite;
  animation: loaderMaskCircle 1.5s infinite;
}

/*  @-moz-keyframes  loaderMaskFS,
@-webkit-keyframes  loaderMaskFS, */

@keyframes loaderMaskFS {
  0% {
    transform: translate(40px, 0); //transform: translate(40px, 0);
  }
  65% {
    transform: translate(0px, -140px);
  }
  100% {
    transform: translate(40px, -230px);
  }
}

/* @-moz-keyframes  loaderMaskCircle,
@-webkit-keyframes  loaderMaskCircle, */

@keyframes loaderMaskCircle {
  0% {
    transform: translate(30px, -20px);
  }
  50% {
    transform: translate(20px, 90px);
  }
  100% {
    transform: translate(30px, 270px);
  }
}

/* body.admin-menu.adminimal-menu,
html body {
  &.profile-offset {
    margin-top: -200px !important;
  }
} */

// @todo: I can remove this if im getting rid of OneSignal.
.push-wrapper {
  display: none;
  font-size: 1.2em;
  font-weight: normal;
  padding: 1em;
  background: #f7f7f7;
  border: solid 1px #eee; //display: inline-block;
  width: auto;
  margin: 1em auto;

  &.push-unsupported {
    color: #a10808;
  }

  .button {
    margin: 0.5em 1em;
  }
}


body.portfolio-item {
  &.two-sidebars,
  &.sidebar-second {
    .center {
      margin-right: 60%; //$second_sidebar_width + 100px;
    }
  }

  // Left value & negative left value.
  #sidebar-second {
    width: 60%; //$second_sidebar_width + 100px;
  }

  #main {
    padding-top: 40px;
  }
}

body.section-contests,
body.community-groups,
body.section-groups {
  &.two-sidebars,
  &.sidebar-first {
    .center {
      margin-left: 350px; //$second_sidebar_width + 100px;
      border-left: solid 1px #eee;
      padding-left: 2em;
    }
  }

  // Left value & negative left value.
  #sidebar-first {
    width: 350px; //$second_sidebar_width + 100px;
    .block-title {
      color: $bright-blue;
      margin-bottom: 10px;
    }

    .block {
      padding-bottom: 20px;
    }
  }

  .region-sidebar-first {
    padding-left: 10px;
    padding-top: 2em;
    padding-right: 2em; // border-right: solid 1px #eee;
    margin-right: 0; //2em;
  }

  .header-inner {
    display: none;
  }

  #hero {
    background-color: #000;
    /* .inner {
        padding: 5px 0 0 0;
      } */
    .region-hero {
      max-width: $max-width + 320;
      margin: 0 auto; //padding: 0 10px;
      /*.block-inner {
        border: 0;
      }*/
    }
  }
}

.page-node-add,
.page-node-edit {
  #block-system-help {
    .block-inner {
      font-size: 16px;
      line-height: 1.3em;
      border: solid 1px $bright-blue;
      padding: 1em;
      margin: 1em 0;
      background-color: $pale-blue;
      @include border-radius(3px);

      ul {
        margin: 0 1em;
        border-top: solid 1px $bright-blue;
        padding-top: 0.5em;
      }

      p {
        margin: 0 0 0.5em 0;
      }
    }
  }
}


#node-header {
  //overflow: hidden;
  ul.portfolio-thumbs {
    // border-top: solid 1px #eee;
    margin: 0 auto 0 auto;
    max-width: 100%;
    padding: 4px;
    position: relative; //bottom: -60px;
    left: 0;
    right: 0; //opacity: 1;
    text-align: center;
    display: block;
    height: auto;
    list-style: none; //@include transition(all .15s ease-in-out);
    li {
      list-style: none;
      display: inline-block;
      margin: 0;
      width: 11.11%;
      padding: 2px;

      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
        padding: 0;
        margin: 0;
        border: solid 2px transparent;
      }

      a {
        display: inline-block;
        padding: 0;
        margin: 0;

        &:hover {
          img {
            border-color: #fff;
          }
        }
      }
    }
  }

  .content {
    position: relative;
  }

  // @todo is prev/next classes still being used?
  /* .content:hover {
    .pn-nav {
      @include transition(all .15s ease-in-out);
      opacity: 1;
      &.nav-next {
        right: 5px;
      }
      &.nav-prev {
        left: 5px;
      }
    }
  } */
}

// @todo: is targeting touchevents correct here?
.touchevents #node-header {
  &.node-photo,
  &.node-video {
    .gallery-trigger {
      opacity: 1;
      top: inherit;
      left: 1px;
      bottom: 0;
      right: 5px;
      left: inherit;
    }
  }
}

.region-hero {
  position: relative;
}

// .node-type-list,
.portfolio-item {
  .region-hero {
    background: #111;

    .node .photo-wrapper {
      background-color: transparent;

      .photo,
      a {
        background-color: transparent;
      }
    }
  }

  .potd-badge {
    a {
      background-color: transparent;
    }
  }
}

#node-header {
  .freepager-previous,
  .freepager-next {
    font-size: 40px;
    text-align: center; //float: right;
    position: relative;
    display: block;
    width: 100px;
    height: auto;
    top: 50%;
    bottom: 50%;

    a {
      height: 100%;
      max-height: 100px; //width: 30%;
      width: 100px;
      display: block; //position: absolute;
      span {
        display: block;
        background-color: rgba(0, 0, 0, 0.8);
        @include border-radius(3px);
        padding: 5px 15px;
        color: #fff;
        opacity: 0.7;
        @include center-vertical(absolute);
        line-height: 65px; //height: 65px;
      }

      &:hover span {
        opacity: 1;
      }
    }
  }

  .freepager-previous {
    float: left; //a span{
    left: 10px; //}
    a span {
      left: 0;
    }
  }

  .freepager-next {
    right: 10px;
    float: right;

    a span {
      right: 0;
    }
  }
}

.region-hero {
  #node-header {
    position: relative;

    .port-item-wrapper {
      height: 650px;
      display: block;
      position: relative;

      div {
        height: 100%;
        display: block;
      }
    }

    .nsfw-warning {
      width: 100% !important;
      height: auto !important;
      max-width: 100%;
      max-height: 100%;
      display: block;
      margin: 0 auto;
      vertical-align: middle;
      text-align: center;
      @include center-vertical();
      padding: 0;
      position: absolute;
      font-size: 2.3em;
      border: none;
      background-color: transparent;
      color: #fff;

      a.button {
        font-size: 0.8em;
        padding: 0 1em;
        line-height: 2.5em;
        height: 2.5em;
      }
    }

    .video-wrapper,
    .photo-wrapper {
      position: absolute;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 0;
      width: 100%; //height: 100%;
      display: block; //border-bottom: solid 70px transparent;
      img {
        width: auto !important;
        height: auto !important;
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: 0 auto;
        vertical-align: middle;
        text-align: center;
        @include center-vertical();
      }
    }

    .video-wrapper,
    .photo-wrapper {
      padding-top: 30px;
    }

    .video-wrapper {
      overflow: hidden; //border-top: solid 25px transparent;
      iframe {
        box-sizing: border-box;
        vertical-align: middle; //display: none;
      }

      div {
        width: 100% !important; //height: 100% !important;
        box-sizing: border-box;
      }
    }
  }
}

.center-vert {
  @include center-contents();
}

#node-header {
  .port-item-wrapper.views-empty {
    line-height: 1.5em;
    color: #fff;
    padding-top: 175px;

    .icon-wrapper {
      display: block;
      color: #666;
    }

    p {
      padding: 1em 0 0 0;
      margin-bottom: 0;
    }

    .freepager-previous,
    .freepager-next {
      position: inherit;
    }
  }
}

body.portfolio-item {
  #sidebar-second {
    .block-title,
    .label {
      font-size: 12px;
      text-transform: uppercase;
      color: $bright-blue;
      font-weight: bold;
      background: none;
      font-family: $default-family;
      letter-spacing: normal;
      padding: 10px 0 0 0;
      margin: 0 0 7px 0;
      border-bottom: solid 1px #eee;
      @include border-radius(0);
      line-height: normal;
    }
  }
}


//Exif data on photo nodes
.image-details {
  color: #666;
  font-size: 14px;
  line-height: 1.4em;
  font-family: $header-family;

  .dot {
    font-size: 2em;
    vertical-align: middle;
  }

  .exif-info-wrapper {
  }

  .exif-image-thumb {
    float: left;
    width: 55px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: -5px;

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .camera-icon {
    font-size: 26px;
    float: left;
    color: #ddd;
  }

  .camera {
    padding-top: 5px;
    font-size: 22px;
    padding-bottom: 3px;
    color: #111;
    font-weight: 800;
    letter-spacing: -0.025em; // margin-left: 35px;
    a {
      color: #111;
      text-decoration: none;
    }
  }

  .camera-settings {
    padding-bottom: 5px;
    color: #666;
    font-size: 1em; // margin-left: 35px;
  }

  label {
    display: inline-block;
    color: #666 !important;
  }

  .field {
    //font-size: 11px;
    color: #666;
  }

  .field-label-inline {
    font-size: 1em;

    .field-label {
      display: inline-block;
      float: none;
      font-size: 1em;
      letter-spacing: normal;
    }

    .field-item {
      display: inline-block;
      float: none;
    }
  }
}

.image-tags {
  //padding-top: 10px;
  .item-list {
    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      @include tags();
    }
  }
}

.nsfw-warning {
  text-align: center;
  font-size: 20px;
  line-height: 1.4em;
  padding: 2em 2em;
  color: #555;
  background: #eee;
  border: solid 1px #ddd;
  margin: 1em 0; //background-color: #000;
  // margin: 0;
  //color: #fff;
  .nsfw-actions {
    padding: 1em;

    a.button {
      line-height: 3em;
      display: inline-block;
      height: auto;
      padding: 0 1.2em;
      vertical-align: middle;
      font-family: $header-family; //letter-spacing: $header-spacing;
    }
  }
}


.node {
  .field-name-field-sources {
    display: inline-block;
  }

  .node-content {
    //font-family: $header-family;
    //font-size: 1em;
    //line-height: 1.5em;
  }

  &.teaser {
    .lead-image {
      position: relative;
    }

    .field-name-field-dek {
      font-family: $default-family;
      font-size: 1.35em;
      color: #000;
    }

    .field-name-field-entry-date {
      display: inline-block;
    }

    .field-name-field-results-body,
    .field-name-body {
      //font-family: $header-family;
      font-size: 1em;
      line-height: 1.5em;
      //@include multiline-clamp(6);
      p:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .contest-deadline-wrapper,
    .submission-deadline {
      padding: 0.5em 0;

      .label {
        font-weight: bold;
        display: inline-block;
      }

      .contest-deadline {
        display: inline-block;
      }
    }

    .meta {
      //padding-top: 10px;
    }
  }
}

// @todo: what is this?
.node.node-teaser .article-type-label {
  display: block;
  position: relative;
  top: initial;
  right: initial;
  font-size: 11px;
  text-transform: uppercase;
  color: $bright-blue;
}

// @todo: is this class used?
.lead-image {
  img {
    width: 100%;
    height: auto;
  }
}

.node-contest-results.feature {
  .contest-type {
    font-weight: bold;
    font-size: 13px;
    vertical-align: middle;
    letter-spacing: normal;
    display: block;
    line-height: 1.3em;
    font-family: $header-family;
    color: $bright-blue;
    text-transform: uppercase;
    font-weight: 600;
  }

  .lead-image {
    padding-bottom: 10px;
    position: relative;

    .field-name-field-lead-image {
      margin: 0;
    }

    .meta {
      position: absolute;
      top: 2px;
      right: 2px;
      padding: 0;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 3px;

      span {
        padding: 0 10px;
        font-size: 16px;
        display: inline-block;
        line-height: 2.7em;
      }

      strong {
        //font-weight: normal;
        color: #fff;
      }

      i {
        color: #fff;
      }

      a {
        color: #fff;
      }
    }
  }

  h2 {
    color: #000;
    margin: 0;
    font-family: $default-family;
    font-size: 20px;
    letter-spacing: normal;
    font-weight: 600;

    a {
      text-decoration: none;
      color: #000;
    }
  }

  .meta {
    padding-top: 10px;

    .submission-count {
      line-height: 2.2em;
      font-size: 14px;
    }
  }
}


.node-contest.teaser {
  header {
    padding-bottom: 1em;
  }

  .meta {
    line-height: 2.5em;
    display: inline-block;
    width: 100%;
    padding-top: 0.3em;
  }

  .contest-type {
    font-weight: 800;
    font-size: 14px;
    vertical-align: middle;
    letter-spacing: normal;
    display: block;
    line-height: 1.3em;
    font-family: $header-family;
    color: $bright-blue;
    text-transform: uppercase;
  }

  .contest-theme {
    //font-family: $header-family;
    font-size: 1.3em;
    color: $off-black; //font-weight: bold;
    padding: 0.2em 0;
    letter-spacing: $header-spacing;
    line-height: 1.2em;
    font-family: $header-family;
    font-weight: bold;
  }

  .submitted {
    padding-top: 0.5em;
    padding-bottom: 5px !important;
    margin: 0;
  }

  .field-name-body {
    //padding: 0.5em 0;
  }

  .field-name-field-guest {
    font-weight: normal;
    color: $off-black;
    font-size: 1em;
  }

  .field-name-field-lead-video {
    max-width: 60%;
    position: absolute;
    top: 5px;
    right: 5px;
    border: solid 2px #fff;

    .field-item {
      padding: 0;
    }
  }
}

.node-type-product,
.node-type-article {
  h1.title {
    //    font-size: 4.5em;
  }
}

.nsfw-overlay {
  position: absolute;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); //line-height: 18em;
  vertical-align: middle; // display: table-cell;
  font-size: 16px;
  text-align: center;
  height: auto !important;
  padding: 0 0.5em;
  line-height: 1.2em;
  color: #ccc;

  span {
    //display: table-cell;
    vertical-align: middle;
    padding: 0 0.5em;
    max-width: 150px;

    span {
      max-width: auto;
      padding: 0;
    }
  }
}

.pin .nsfw-overlay {
  font-size: 11px;

  .small-hide {
    display: none;
  }
}

.desktop-hide {
  display: none;
}

span.play-icon {
  position: absolute;
  top: 2px;
  left: 6px;
  font-size: 42px;
  color: #fff;
  line-height: normal;
  @include text-shadow(1px 1px 1px rgba(0,
    0,
    0,
    0.3));
}

//.store-catalog,
//.contest,
.popular-items {
  /*&.two-sidebars,
  &.sidebar-second {
    .center {
      margin-right: 230px;
    }
  }*/
  &.sidebar-first {
    #content-inner {
      padding-left: $padding*2;
      border-left: solid 1px #eee;
    }

    .center {
      margin-left: 240px;
    }
  }

  /*#sidebar-second {
    //float: right;
    //margin-left: -210px;
    width: 230px;
  }*/
  #sidebar-first {
    width: 240px;
    padding-right: $padding*2;
  }
}


.comment-page {
  //  padding-left: 9px; //margin-left: -2px;
}

form .form-item-uploaded-nids {
  display: none;
}

.loading-wrapper {
  text-align: center;
  font-size: 14px;
  padding: 2em;
  color: #666;

  i {
    clear: both;
    display: block;
    margin-bottom: 15px;
    color: #ccc;
    font-size: 35px;
  }

  div {
    border-color: #ccc transparent #ccc transparent !important;
  }
}

// @todo: can go to grearbag.css
ul.sortable-stage {
  a.gear-remove {
    @include red-button();
    display: none;
    color: #fff;
    @include border-radius(3px);
  }

  a.gear-add {
    @include green-button();
    display: none;
    color: #fff;
    @include border-radius(3px);
    right: 10px;
    bottom: 10px;
    top: inherit;
    left: inherit;
  }

  a.gear-action {
    font-size: 12px;
    line-height: 1em;
    padding: 5px 8px;
    color: #fff !important;
    margin: 0;

    i {
      vertical-align: middle;
    }
  }
}


// @todo: I think this can be removed.
// Is there a block with this class?
/*.follow-members-block {
  text-align: center;

  .view-empty {
    padding-top: 0;
  }

  .row,
  .views-row {
    position: relative;
    width: 100% !important;
    float: none;
    height: auto;
    line-height: normal;
    margin-bottom: 10px;
    min-width: 280px;
    max-width: 400px;
    display: inline-block;
    padding: 1em;
    vertical-align: top;
  }

  .button {
    font-size: 20px;
    padding: 0.5em 2em;
    height: auto;
  }

  .actions {
    padding: 3em 0 4em;
  }
}*/

.wide-only {
  display: none;
}

.modal-header {
  a.close-modal {
    float: right;
    font-size: 2em;
    color: #ccc;

    &:hover {
      color: #000;
    }
  }
}

// @todo: is this used?
/*.view-profile-followers {
  .entity-profile2.teaser {
    padding: 0.5em 0;
  }
}*/

#modal-form-wrapper {
  .login-panel {
    margin: 0;
    border: none;
    padding: 0;
  }
}


.sidebar-menu-trigger {
  display: none;
}


.login-feedback {
  font-size: 20px;
  max-width: 500px;
  line-height: 1.4em;
}

.view-potm-achievement {
  .views-row {
    display: inline-block;
    vertical-align: top;
    width: 33%;
    padding: 1em;
  }

  .view-content {
    h3 {
      text-align: center;
      font-size: 2.5em;
      border-bottom: solid 1px #eee;
      margin: 1em 0 0.3em 0;
    }

    .views-field-field-achievement-month {
      text-align: center;
      text-transform: uppercase;
      font-size: 13px;
      color: $bright-blue;
      font-family: $header-family;
      font-weight: bold;
      padding: 5px;
    }
  }
}

.region-content {
  .view-header {
    font-size: 1.1em;
    line-height: 1.6em;
  }
}

.view-activity-feed,
.view-aggregate-activity {
  .view-header {
    clear: both;
    padding: 1em 0;
  }

  .activity-user {
    clear: both;
    padding: 1em 0 1.5em 0; //border-top: solid 1px #eee;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    .user-info {
      margin-left: 10px;
      font-size: 15px;
      color: #000;
      flex: 1;
      line-height: 1.3em;

      .username,
      .username a {
        color: $bright-blue;
        font-weight: bold;
        text-decoration: none;
      }

      span {
        display: block;
        color: #999;
        padding-top: 5px;
        font-style: italic;
      }
    }

    .user-picture {
      width: 40px;
    }
  }

  .view-empty {
    padding: 5em 2em 0 2em;
  }
}

/*.view-potm-achievement,
.profiles-view {
  .view-content {
    clear: both;
    text-align: center;
  }

  .views-row {
    display: inline-block;
    vertical-align: top;
    width: 33%;
    text-align: center;
    padding: 2em 1em 2em 0;
  }
}*/

.profiles-view {
  .view-content {
    clear: both;
    text-align: center;
    display: grid;
    justify-content: space-between;
    align-items: start;
    align-content: space-around;
    justify-items: start;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 1em;
    padding: 1em 0;

    // first level h3s should span the whole width.
    > h3 {
      grid-column: 1 / -1;
      width: 100%;
    }
  }

  .views-row {
    width: 100%; //height: 64px;
    //display: inline-block;
    vertical-align: top;
    padding: 0;
    flex: 1;
    //margin-right: 20px;
    //float: left;
  }
}

.lazy-profile {
  min-height: 130px;

  img {
    height: auto;
    max-width: 100% !important;
  }
}

.slick-view {
  .pagination {
    display: none;
  }
}


.flag-chosen-contest-entry {
  float: right;
}

.flag-chosen-contest-entry a {
  display: inline-block;
  font-size: 13px;
  background: #eee;
  line-height: 26px;
  padding: 0 0.6em;
  text-decoration: none;
  color: #fff;
  border-radius: 2px;
  text-align: center;

  &.flag-action {
    color: #333;
  }

  &.unflag-action {
    background: $green;
  }
}

.flag-outer-chosen-contest-entry {
  float: right;
}

.image-controls {
  .fivestar-container {
    display: none;

    &.active {
      display: block;
    }
  }

  .rating-tabs {
    margin-bottom: 0; //-6px;
    padding: 0 10px;

    a.rating-tab {
      display: inline-block;
      background: rgba(0, 0, 0, 0.5); // border: solid 1px #111;
      //border-bottom: solid 1px #111;
      color: #666;
      text-decoration: none;
      padding: 0 10px;
      line-height: 24px;
      font-size: 11px;
      margin: 0 0 0 5px;
      font-weight: normal;
      text-transform: uppercase;
      border-radius: 3px 3px 0 0;

      &.active {
        font-weight: bold;
        color: #fff; // border-bottom: solid 1px #000;
      }
    }
  }

  .rating-wrapper.has-tabs {
    display: block;
    margin-top: -24px;
    float: left;
  }

  .actions-wrapper .action-item {
    //border: solid 1px #111;
    //background: #ff0000;
  }
}

.load-more-wrapper {
  text-align: center;
  padding-top: 60px;
  clear: both;

  button {
    max-width: 500px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 60px;
    height: auto;
    background: #fff;
    color: #777;
    border: solid 1px #ddd;
    @include box-shadow(0,
      2px,
      10px,
      -5px,
      rgba(0,
        0,
        0,
        0.3));

    &:disabled {
      display: none;
    }

    &:hover {
      color: #fff;
      background-color: $bright-blue;
      border-color: $bright-blue;
    }
  }
}

.page-load-status {
  text-align: center;
  font-size: 18px;
  display: none;

  div.infinite-scroll-request,
  p {
    font-size: 16px;
    line-height: 60px;
  }

  .infinite-scroll-request {
    font-size: 30px;
    color: #888;
    /*     div{
      border-color: #
    } */
  }
}

.slick-list {
  .load-more-wrapper,
  .page-load-status {
    display: none !important;
  }
}

.slick-dots {
  white-space: nowrap;
  overflow: hidden;
}

// hidden form element for honeypot.
form .url-textfield {
  display: none;
}


@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.lds-dual-ring {
  position: relative;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.lds-dual-ring div {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0.1em solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  -webkit-animation: lds-dual-ring 0.7s linear infinite;
  animation: lds-dual-ring 0.7s linear infinite;
}

.css-loader {
  position: relative;
  text-align: center;
  font-size: 30px;
}

// normal desktop (not wide)
/*@media only screen and (min-width: 920px) and (max-width: 1090px) {
  #header #logo {
    width: 200px;
    padding-top: 20px;
  }
  #navigation .search #edit-t {
    width: 170px !important;
  }
  #navigation {
    #primary {
      font-size: 17px;
    }
  }
}*/


#worksafe-toggle-form,
.bootstrapToggle-wrapper {
  display: inline-block;
  width: 100%;
  position: relative;

  label {
    display: inline-block;
    float: right;
  }

  label.option {
    padding-top: 0; //0.4em;
    display: inline-block !important; //font-weight: 600;
  }

  .btn-sm {
    padding: 5px 10px;
  }

  .toggle-handle {
    float: none;
    font-size: inherit;
    font-weight: inherit;
  }

  .toggle.fs-toggle {
    border-width: 1px; //2px;
    border-radius: 2px; //4px;
    clear: both;
    display: block;
    margin: 0 0 5px 0;
    float: right !important;

    &.btn-primary {
      border-color: $bright-blue;
    }

    .btn-primary {
      background-color: $bright-blue;
    }

    .toggle-handle {
      width: 50px; //border: solid 2px transparent;
      border-width: 0px;
      border-radius: 1px; //2px;
      vertical-align: top;

      &.btn-default:hover {
        background: #fff;
      }
    }

    .toggle-on.btn-sm {
      padding-right: 35px;
    }

    .toggle-off.btn-sm {
      padding-left: 35px;
    }

    .toggle-group {
      label.btn {
        margin: 0 !important;
        color: inherit !important;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 1.6em;
        float: right;
      }
    }
  }
}

#worksafe-toggle-form {
  float: right;
  width: auto;
  padding: 0;

  label {
    line-height: 1.5em !important;
    font-size: 1em;

    &:hover {
      background: none;
    }

    &.toggle-on {
      padding-right: 30px;
      padding-left: 5px;
    }

    &.toggle-off {
      padding-left: 30px;
      padding-right: 5px;
    }
  }

  .btn-sm {
    padding: 0 10px;
  }

  .toggle.fs-toggle {
    min-height: inherit;
    margin-bottom: 0; //  height: 21px !important;
  }
}


// @todo this node type was like a product but a waste of time.
.node-type-file-downloads {
  /*&.sidebar-second .center,
  &.two-sidebars .center {
    margin-right: 460px;
    border: none !important;
  }

  #sidebar-second {
    width: 460px;
    //margin-left: 0;
  }

  .region-sidebar-second {
    padding-left: 0;
  }*/

  #block-fs_store-store_home_button {

    .block-inner {
      text-align: center;
      padding: 2em 0;
    }
  }

}

.node-file-downloads.full {
  .field-name-field-product-images {
    margin-bottom: 2em;

    img {
      width: 100%;
      height: auto;
    }
  }
}

a.store-back-button {
  padding: 1em 1.5em;
  background: #fff; //#f7f7f7;
  display: inline-block;
  text-decoration: none;
  font-family: $header-family;
  letter-spacing: $header-spacing;
  line-height: 1.4em;
  font-size: 20px;
  border-radius: 3px;
  border: solid 1px #eee;

  i {
    float: left;
    display: block;
    color: $bright-blue;
  }

  .text-wrapper {
    margin-left: 4em;
    display: block;
    text-align: left;
  }

  .text {
    color: #666;
  }

  .website {
    display: block;
    font-size: 1.6em;
    color: #000;
    font-weight: 600;
  }

  .store {
    color: $bright-blue;
  }

  &:hover {
    border-color: $bright-blue;
    background-color: $pale-blue;
  }
}

// Small
@media only screen and (max-width: $mobile-max-width),
only screen and (max-device-width: $mobile-max-width) {
  a.store-back-button {
    font-size: 13px;
  }
  .store-back img {
    width: 100%;
    height: auto;
  }
}

.file-downloads-form-wrapper {
  background-color: #eee;
  border-radius: 10px;
  margin: 2em 0;
  padding: 2em;
  font-size: 16px;
  line-height: 1.6em;
  text-align: center;
  position: relative;

  .register-prompt {
    font-size: 1.2em;
    color: #000;
  }

  .edit-link {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 11px;
    background-color: #fff;
    padding: 5px;
  }
}

.file-downloads-wrapper {

  /*.block-inner {
    background-color: #eee;
    border-radius: 10px;
    margin: 2em 0;
    padding: 2em;
    font-size: 16px;
    line-height: 1.6em;
    text-align: center;
  }*/


  input.form-submit,
  a.button {
    font-family: $header-family;
    font-weight: 800;
    font-size: 22px;
    line-height: normal;
    padding: 0.5em 1em;
  }

  .field-name-field-buy-header,
  .intro {
    //font-family: $header-family;
    font-weight: bold;
    font-size: 1.7em;
    padding: 0 0 1em 0;
    color: #000;
  }

  .field-name-field-product-images {
    margin-bottom: 1rem;

    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .video-button {
      position: absolute;
      display: block;
      width: 150px;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 5px;
      padding: 0 0.75rem;
      max-width: 25%;
    }

    svg {
      color: #ff0000;
      fill: #ff0000;
      vertical-align: middle;
    }

    a:hover {
      .video-button {
        background: rgba(255, 255, 255, 1);
        //width: 160px;
      }
    }
  }
}

.code-wrapper {
  textarea {
    width: 100%;
    height: 3em;
    overflow: hidden;
    text-align: center;
    border: solid 1px transparent !important;
    background: none;
    box-shadow: none;
    resize: none;
    vertical-align: top;
  }

  .copy-link {
    font-size: 14px;
  }
}

/*
.file-downloads-wrapper {
  background-color: #f7f7f7;
  border: solid 1px #eee;
  border-radius: 10px;
  margin: 2em 0;
  padding: 1em;
  font-size: 16px;
  line-height: 1.6em;
  text-align: center;
  color: #000;
  @media only screen and (min-width: $wide-min-width) {
    padding: 2em;
  }
}
*/

// @todo - store only? May be on teasers.

table.commerce-price-savings-formatter-prices {
  tr {
    &:hover {
      background-color: transparent;

      td {
        background-color: transparent;
      }
    }

    td {
    }
  }
}

.fs-tabs-nav-container {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
}

.fs-tabs-nav {
  text-align: center;
  clear: both; //border-top: solid 1px #222;
  margin-top: 2em;
  float: left;
  width: 100%;

  .item-list {
    border-bottom: none; //solid 1px #ddd;
    //margin-bottom: 15px;
  }

  ul {
    font-family: $header-family;
    display: inline-block;
    float: none;
    letter-spacing: $header-spacing;
    padding: 0;
    margin: 0; //margin-bottom: -1px;
    vertical-align: bottom;
    overflow-y: hidden;
    text-align: center;
    list-style: none;
    text-transform: uppercase; //li:last-child,
    li {
      font-size: 17px;
      font-weight: 600;
      margin: 0; // 15px;
      vertical-align: bottom; //line-height: 2.5em;
      float: none;
      display: inline-block;
      letter-spacing: normal;

      .count {
        background: #333;
        border-radius: 3px;
        font-size: 12px;
        vertical-align: middle;
        padding: 0 0.6em;
        font-weight: 500;
        margin-left: 0.3em;
      }

      i {
        vertical-align: middle;
        line-height: inherit;
        color: #fff; //font-size: 0.9em;
        margin-right: 0.3em;
      }

      a {
        //background-color: #ccc; //transparent !important;
        color: #999;
        @include border-radius(0);
        font-weight: inherit;
        padding: 0 1em; // 0 15px 0;
        //line-height: unset;
        text-decoration: none;
        line-height: 2.4em;
        display: block;

        &:hover {
          color: #fff;
        }
      }

      &.active {
        a {
          background-image: url(../images/nav-marker.png) !important;
          background-repeat: no-repeat;
          background-position: center bottom -6px;
          color: #fff;
        }

        a,
        i {
          color: #fff; //$bright-blue;
          //background: transparent;
          font-weight: inherit;
        }
      }
    }
  }
}

/*
.field-collection-view {
  ul.field-collection-view-links {
    font-size: 14px;
    a {
      margin: 0;
      color: #999;

      &:hover {
        color: #000;
      }
    }
  }
}

.field-collection-container {
  border: none;

  ul.action-links {
    text-align: center;
    width: 100%;

    li {
      float: none;
      display: inline-block;
    }
  }

  ul.action-links-field-collection-add {
  }
}
*/


td.file-button {
  text-align: right;
}

#autocomplete {
  li {
    padding: 2px;
  }
}

.autocomplete-avatar {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

/*.view-user-product-licenses {
  table {
    @include no-rollovers();
  }
}*/

.beer-slider-wrapper {
  text-align: center;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.beer-reveal {
  border-right: solid 1px #fff;

  img {
    max-width: inherit !important;
  }
}

.beer-reveal[data-beer-label]::after,
.beer-slider[data-beer-label]::after {
  word-break: normal;
  white-space: nowrap;
  font-size: 12px;
}

.beer-slider {
  img {
    padding: 0 !important;
    max-height: 80vh;
    width: auto;
  }
}

// @todo could use a shared class.
#block-views-users_following-following_you,
#block-views-users_following-you_follow {
  float: left;
  width: 50%;

  .block-inner {
    padding: 1em 2em 0 0;
  }

  .views-row {
    display: inline-block;
    max-width: 80px;
    padding: 3px;
    width: 18%;
  }
}

/* .catalog-image {
  overflow: hidden;
  border-radius: 5px;
} */

/* .product-images-wrapper{
  overflow: hidden;
  border-radius: 5px;
} */


/*
.view-user-coupons {
  //background-color: $pale-blue;
  //border: solid 1px $bright-blue;
  //padding: 1.5em;
  //border-radius: 3px;
  display: block;
  clear: both;
  padding: 0; //line-height: 1.4em;
  .views-row {
    width: auto;
    padding: 0;
    margin: 0;
  }

  .view-content,
  .view-header {
    font-size: inherit;
    padding: 0;
    margin: 0;
    text-align: center; //font-size: 18px;
    ul,
    li {
      padding: 0;
      margin: 0;
      list-style-type: none;
      list-style: none;
    }
  }

  .view-content {
    font-size: 16px;
  }

  .view-header {
    color: $bright-blue;
    font-size: 20px;
  }

  .views-field-code {
    text-transform: uppercase;
    color: #000;
    font-weight: bold;
  }

  .views-field-field-description {
    //font-size: 14px;
    padding-top: 10px;
  }
}
*/


.page-achievement-edit,
.page-achievement-add {
  #content-header,
  #content-area {
    max-width: 600px;
    margin: auto;

    form {
      background: #fff;
      padding: 2em;
      border: solid 1px #eee;
    }
  }

  #content-header {
    padding: 1em;
  }

  h1.title {
    font-size: 30px;
    font-weight: 300;
  }
}

.portfolio-item {
  #content-header,
  #content-area {
    padding-top: 0;
  }

}

/* .badge-preview{

} */


#mc_embed_signup {
  max-width: 500px;
  margin: 2em auto;
  padding: 1em 2em;
  background-color: #f7f7f7 !important;
  border: solid 1px #eee;
  border-radius: 5px;

  div[aria-hidden="true"] {
    display: none;
  }

  .clear {
    clear: none;
  }

  input {
    margin: 3px 0;
  }

  input[type="email"] {
    width: 100%;
  }
}


.copy-link {
  font-size: 12px;
}


#product-license-confirm-invite-form {
  font-size: 18px;
  line-height: 1.3em;
  text-align: left;
  padding: 30px 0;
}

.view-product-license-invite-items {
  .view-content {
    padding: 20px 0;
  }

  .views-row {
    width: 100%;
    display: inline-block; //vertical-align: top;
    padding: calc($padding / 2) 0;
    text-align: left;

    .views-field-field-product-images {
      width: 200px;
      float: left;
    }

    .views-field-title {
      margin-left: 200px;
      font-weight: bold;
      font-size: 20px;
      color: #000;
      padding: 5px 0 5px $padding;
      line-height: 1.2em;
    }

    .views-field-field-dek {
      margin-left: 200px;
      padding-left: 20px;
      font-size: 16px;
      line-height: 1.2em;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 100%;
    }
  }
}

.on-air {
  background-color: #ff0000;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  display: inline-block;
  padding: 0.3em 0.5em;
  border-radius: 2px;
  margin-right: 5px;
  font-family: $header-family;
  font-weight: 800;
  line-height: normal;
  vertical-align: middle;
}

.on-air-message {
  margin-bottom: 0.5em;
  font-size: 16px;
  /*font-size: 14px;*/
}


// This is an admin only css?
// @todo - maybe move these
/*.view-permissions-review,
.views-field-field-permissions-review {
  .form-actions {
    display: none;
  }
}

.views-field-field-permissions-review {
  padding-top: 5px;
  border-top: solid 1px #eee;
  margin-top: 5px;

  .views-label {
    font-weight: bold;
  }

  .form-type-radios {
    margin: 0;
  }
}*/

// set an abs height for the facebook share block
/*#block-fs_helpers-fs_helpers_connect {
  height: 150px;

  .follow {
    height: 40px;
  }
}*/


#block-fs_helpers-fstop_socials {
  .block-inner {
    padding: 2.5em 0;
    border-bottom: solid 1px #eee;
  }

  .content {
    margin: 0;
  }

  //border-bottom: solid 1px $light-grey;
  .block-title {
    font-size: 16px !important;
    color: $bright-blue !important;
    border: none !important;
    font-weight: 800 !important;
  }

  .socicons {
    display: inline-block;
    width: 100%;
    padding: 0.2em 0 0.7em 0; //text-align: center;
  }

  /* .hidden{
    display: none;
    text-indent: -9999px;
    overflow: hidden;
  } */
  .icon-wrapper {
    // font-size: 40px;
    display: inline-block;
    padding: 1px;

    a {
      background-color: #eee;
      color: #999;
      border: solid 1px #eee;

      &:hover {
        background-color: #fff;
        color: #000;
        border: solid 1px #ccc;
      }
    }

    /*.count {
      font-size: 12px;
      color: #999;
    }*/

    /*.icon-out {
      opacity: 0.7;
      text-align: center;
    }*/

    /* a{
      display: inline-block;
      margin: 5px 1px;
    } */
  }
}

.no-sidebars.node-type-page #content {
  max-width: 1000px;
  margin: auto;
  float: none;
}

.page-file {
  h1.title {
    line-break: anywhere;
  }

  #content-area {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

// instagram embeds.
blockquote.instagram-media {
  background: none;
  background-color: none !important;
  padding: 0 !important;
  text-align: center;
  display: block;
}

.small-stars {
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  @include border-radius(0,
    0,
    5px,
    5px);
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;

  .help-text {
    color: #888;
    font-size: 11px;
    display: block;
    opacity: 0; //display: none;
    //height: 0;
    max-height: 0;
    padding-bottom: 0;
    @include transition(all .15s ease-in-out);

    strong {
      color: #eee;
    }
  }

  &:hover {
    .help-text {
      //display: block;
      opacity: 1; //height: 0;
      padding-bottom: 5px;
      max-height: 52px;
      @include transition(all .15s ease-in-out);
    }
  }

  .avg {
    display: none;
  }

  .rate-fivestar-btn-filled,
  .rate-fivestar-btn-empty {
    font-size: 14px;
    line-height: 14px;
    margin: 0;
  }

  .rate-widget-fivestar {
    text-align: center;

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        float: none;
        margin: 0;
        padding: 0;
      }
    }
  }

  .star-wrapper {
    font-size: 1.2em;
    margin-right: 1px; // float: left;
    display: inline-block;
    position: relative;

    .star-empty {
      display: block;
      color: #666;
    }

    .star-fill {
      color: #ffbf14;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      display: block;
    }
  }

  .star-fill {
    color: #ffbf14;
  }
}

.swiper-slide {
  width: auto;
}

/*.user-port-footer {
  .view-profile-photos {
    .splide__arrow--prev {
      left: -40px;
    }

    .splide__arrow--next {
      right: -40px;
    }

    .splide__pagination {
      position: relative;
      bottom: initial;
      padding: 1em;
    }

    .splide__pagination__page {
      width: 15px;
      height: 15px;

      &.is-active {
        transform: scale(1.2);
      }
    }
  }
}*/

.bgGrad-0 {
  background: linear-gradient(to bottom, #be747b, #883c96)
}

.bgGrad-1 {
  background: linear-gradient(to bottom, #2bacbd, #fbc208)
}

.bgGrad-2 {
  background: linear-gradient(to bottom, #79da86, #dbbd8d)
}

.bgGrad-3 {
  background: linear-gradient(to bottom, #2d3e9d, #4dccd9)
}

.bgGrad-4 {
  background: linear-gradient(to bottom, #d95b0a, #822e45)
}

.bgGrad-5 {
  background: linear-gradient(to bottom, #d70480, #df9b35)
}

.bgGrad-6 {
  background: linear-gradient(to bottom, #eddbd4, #7f035c)
}

.bgGrad-7 {
  background: linear-gradient(to bottom, #91473e, #edca74)
}

.bgGrad-8 {
  background: linear-gradient(to bottom, #5ff1d5, #4975c4)
}

.bgGrad-9 {
  background: linear-gradient(to bottom, #94d4d7, #8db364)
}

.bgGrad-10 {
  background: linear-gradient(to bottom, #b08ffd, #a6bbc7)
}

.bgGrad-11 {
  background: linear-gradient(to bottom, #30566d, #5d91f3)
}

.bgGrad-12 {
  background: linear-gradient(to bottom, #67a22a, #0d9a5e)
}

.bgGrad-13 {
  background: linear-gradient(to bottom, #b5640f, #faa110)
}

/* ===================
commerce
=======================
*/

.view-shopping-cart-summary2 {
  color: #111;

  .views-row {
    padding: 15px 0;
    border-bottom: dashed 1px #ddd;
    width: 100%;
    display: inline-block;
    font-size: 15px;

    &:last-child {
      border: none;
    }
  }

  .views-field-rendered-entity,
  .views-field-line-item-title {
    color: #111;
    width: 75%;
    float: left;
    padding-right: 5px;

    .catalog-image {
      width: 80px;
    }

    .title-wrapper {
      margin-left: 80px;
      font-size: 12px;
    }

    .inner {
      padding: 0;
      border: none;
      margin: 0;
    }
  }

  .views-field-commerce-total {
    text-align: right;
  }

  .views-field-quantity {
    float: left;
    padding-left: 10px;
    color: #bbb;
    font-size: 11px;
  }
}

.commerce-order-handler-area-order-total .commerce-price-formatted-components {
  width: 100%;
  margin: 0;
  padding: 0;

  td {
    padding: 5px 0;
    color: #888;
  }

  tr {
    padding: 0;
    //border-top: solid 1px #ddd; //$bright-blue;
    &:first-child {
      border: none;
    }

    &.component-type-base-price {
      border-top: none;
      font-weight: bold;

      td {
        color: #000;
        font-size: 16px;
      }
    }

    &.component-type-giftcard,
    &.discount {
      td.component-total {
        color: $green;
      }
    }
  }

  tr.component-type-commerce-price-formatted-amount {
    //border-top: solid 1px #ddd;
    border-bottom: none;

    td {
      color: #000000 !important;
      font-size: 16px;
      font-weight: bold;
    }

    td.component-title {
      font-weight: normal;
    }

    td.component-total {
      font-size: 25px;
      //letter-spacing: -0.03em;
    }
  }
}

table.commerce-price-formatted-components {
  tbody {
    border: none;
  }
}
.commerce-price-savings-formatter-savings{
  flex-basis: 100%;
}
div.commerce-price-savings-formatter-prices {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1em;
  justify-content: center;
  align-items: center;
}

div.commerce-price-savings-formatter-prices,
table.commerce-price-savings-formatter-prices {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  color: #000;

  tbody,
  thead,
  tr,
  td {
    border: none;
  }

  td {
    padding: 0 0 4px 0;
    text-align: right;
    font-size: 16px;
    color: #000;
  }

  &.commerce-price-savings-formatter-prices-down {
    .commerce-price-savings-formatter-list td {
      //text-decoration: line-through;
      color: #999;
      font-weight: normal;
      font-size: 0.85em;
    }

    .commerce-price-savings-formatter-price td {
      padding: 5px 0 0 0;
      color: #000; //$green;
      //font-weight: bold;
    }
  }
}

.commerce-price-savings-formatter-list {
  font-weight: normal;
  color: #999;
  //text-decoration: line-through;
  .price-amount {
    text-decoration: none;
  }

  //.on-sale .field-name-commerce-price .price
  .price {
    position: relative;
    white-space: nowrap;

    &::before {
      position: absolute;
      content: "";
      left: -0.05em;
      top: 40%;
      right: -0.05em;
      border-top: 0.16em solid;
      border-color: inherit;

      -webkit-transform: rotate(-7deg);
      -moz-transform: rotate(-7deg);
      -ms-transform: rotate(-7deg);
      -o-transform: rotate(-7deg);
      transform: rotate(-7deg);
    }
  }
}

/*.on-sale {
  .field-name-commerce-price {
    .price {
      text-decoration: none;

    }
  }
}*/


.commerce-price-savings-formatter-price {
  .price-amount {
    color: inherit;
  }
}

.commerce-price-savings-formatter-prices.commerce-price-savings-formatter-prices-down {
  .commerce-price-savings-formatter-price {
    .price-amount {
      color: inherit;
    }
  }
}

.commerce-price-savings-formatter-prices {
  .price-amount {
    text-align: inherit;
  }
}


.node .toc-tree {

  padding: $padding;
  line-height: 1.3em;
  //min-width: 230px;
  //border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
  margin-bottom: 2rem;
  display: inline-block;
  width: 100%;

  .toc-list {
    color: #bcc3c9;
  }

  .toc-title {
    padding: 0;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    color: #999;
    //font-family: $default-family;
  }

  ul {
    margin-bottom: 0;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-left: 0;
    list-style: none;
    font-weight: bold;

    ul {
      margin-bottom: 0.7em;
      margin-left: 1.2em;
      list-style: disc;
    }

    li {
      margin: 0.75em 0;
    }
  }

  ul.flat-list {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

}

#block-views-contests_list-block_2 {
  border-top: solid 1px #eee;
  margin: 2.5em 0;

  .block-title {
    text-transform: uppercase;
    font-weight: bold;
    color: $bright-blue;
    font-size: 16px;
    font-family: $header-family;
    padding: 2em 0 0 0;
  }

  .view-content {
    .views-row:last-of-type {
      border: none;
    }
  }
}

.block-views-instream_features {
  border-top: solid 1px #eee;
  margin: 2.5em 0;

  .block-title {
    text-transform: uppercase;
    font-weight: bold;
    color: $bright-blue;
    font-size: 16px;
    font-family: $header-family;
    padding: 2em 0 1em 0;
  }

  .view-content {
    display: flex;
    flex-wrap: wrap;

    .views-row {
      flex: 1 0 50%;
      padding: 1em 2em 1em 0;
      border: none;
      min-width: 350px;
    }
  }

  .related_block {
    .field-name-field-lead-image {
      width: 140px;
    }

    .content {
      margin-left: 140px;
    }

    .meta {
      font-size: 12px;
      font-style: italic;
    }
  }
}
