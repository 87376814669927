@import "_config.sass";

@media only screen and (max-width: $tablet-max-width2) {

  .node.node-teaser.node-contest,
  .node.node-teaser.node-article {
    .content {
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: $tablet-max-width2) and (min-width: $mobile-max-width) {

  .view-categories{
    .view-content{
      ul {
        column-count: 3;
      }
    }
  }

/* #menu-background,
body.menu-blocked #menu-background{
  display:none;
}
 */
/*  #search-trigger {
    display: block;
  }*/

/*  #navigation {
    #search-form {
      //margin-top: 11px;

    }

    &.search-open {
      !* #search-form {
        margin-top: 0px;
      } *!

      .search {
        display: block;
        border-top: solid 1px #eee;
      }

      #search-trigger {
        color: $off-black;
      }
    }

    .name-wrapper {
      display: none;
    }

    .search {
      margin-top: 52px;
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;
      display: none;
      background: #f7f7f7;
      padding: 15px;
      border-bottom: solid 1px #ccc;

      .search-submit {
        background-position: -549px 5px;
        top: 15px;
        right: 5px;
        width: 30px;
        height: 30px;
      }

      div {
        width: 100%;

        #edit-t {
          width: 100% !important;
          font-size: 16px;
          line-height: 20px;
          max-width: none; //float: right;
        }
      }

      div.search-field-wrapper {}
    }



  }
  .small-nav,
  .sticky {
    #navigation {
      #search-form {
        margin-top: 0px;
      }

      .search {
        margin-top: 43px;
        padding: 10px !important;
      }

      #primary {
        font-size: 13px !important;
      }
    }
  }*/
}

@media only screen and (max-width: $tablet-max-width) and (min-width: $mobile-max-width) {
  .hide-narrow {
    display: none;
  }

  #content-after {
    .inner {
      max-width: 100%;
    }
  }

 // nav div.inner,
 // header div.inner,
  #pre-footer div.inner,
  //#pre-header div.inner,
  #footer div.inner,
//  #crumb-bar-inner,
  #fold-inner,
  #header-inner,
  #main,
  #banner div.inner,
  #footer-inner {
    //max-width:760px;
    //min-width:600px;
    width: 100%;
    max-width: 100%;
    min-width: auto;
  }
/*
  #content-after .inner,
  nav div.inner,
  header div.inner,
  #pre-footer div.inner,
 // #pre-header div.inner,
  #banner div.inner,
  #footer div.inner,
  #crumb-bar-inner,
  #fold-inner,
  #header-inner,
  #main,
  #footer-inner {
    min-width: auto;
  } */

  #footer .footer-col.last {
    padding: 0;
  }

  /* // No columns.
  // Left value.
  .two-sidebars,
  .sidebar-first {
    .center {
      margin-left: 0 !important;
          clear: both;
     float: none;
    }
  }
  // Left value & negative left value.
  #sidebar-first {
    width: 100% !important;
    margin: 0 !important;
    clear: both;
     float: none;
  }
  .two-sidebars,
  .sidebar-second {
    .center {
      margin: 0 !important;
          clear: both;
     float: none;
    }
  }
  // Right value.
  #sidebar-second {
    width: 100% !important;
        clear: both;
     float: none;
  }

 .front,
 .node-type-homepage,
 .node-type-article{
  #sidebar-second {
    display: none;
  }
 } */


  .node.full {
    .content-col,
    .comments-col {
      margin: 0;
      width: 100%;
      float: none;
    }

    .comments-col .comment-wrapper {
      margin-left: 0;
      padding: 0;
      border: none;
    }
  }


  body.community-groups,
  body.section-groups {

    &.two-sidebars,
    &.sidebar-first {
      .center {
        margin-left: 200px; //$second_sidebar_width + 100px;
      }
    }

    // Left value & negative left value.
    #sidebar-first {
      width: 200px; //$second_sidebar_width + 100px;
    }

    .region-sidebar-first {
      padding-left: 5px;
      padding-right: 10px;
      margin-right: 10px;
    }

    .header-inner {
      display: none;
    }
  }

  body.popular-items,
  body.page-popular {

    &.two-sidebars,
    &.sidebar-first {
      .center {
        margin-left: 150px; //$second_sidebar_width + 100px;
      }

      #content-inner {
        padding-left: 1em;
      }
    }

    // Left value & negative left value.
    #sidebar-first {
      width: 150px; //$second_sidebar_width + 100px;
      padding-right: 0;
    }

    .region-sidebar-first {
      padding-left: 5px;
      padding-right: 10px;
      margin-right: 10px;
    }
  }





  //#pre-header #block-fs_helpers-ad_Header_Small,
  #pre-footer #block-fs_helpers-ad_Footer_Small {
    display: none;
  }


 // #pre-header #block-fs_helpers-freestar_ATF,
 // #pre-header #block-fs_helpers-freestar_BTF,
 // #pre-header #block-fs_helpers-ad_Header_Big,
//  #pre-footer #block-fs_helpers-ad_Footer_Big {
 //   margin: auto;
 //   float: none;
 //   width: auto;
 // }

  #admin-menu {
    display: none;
  }


  .two-sidebars,
  .sidebar-second {
    .center {
      //margin-right: 250px;
      margin-right: 0 !important;
      padding: 0;
    }
  }

  // Right value.
  #sidebar-second {
    width: 100%;
    clear: both;
    float: none;
    max-width: 500px;
    margin: auto;
    padding-top: calc($padding*2);
  }

  #right-header-bottom {
    padding-top: 10px;
  }

  h1.title {
    font-size: 2.2em;
  }

  .node-type-article h1.title {
    font-size: 2.3em;
  }

  .sidebar-second {
    #content-area,
    #content-header {
      //padding-right: 2em;
      //padding-left: 2em;
    }
  }

  body.portfolio-item{
    #main {
      padding-top: 10px;
    }
    #sidebar-second{
      width: 100%;
      max-width: 100%;
    }
  }

  /*#navigation div.inner #primary {

    li {
      .full {
        header {
          .submitted {
            .liking {
              float: none;
              clear: both;
              padding: 5px 0;
              text-align: center;
            }
          }
        }

        .related-articles {

          .views-row-5,
          .views-row-6 {
            display: none;
          }

          .views-row {
            width: 50% !important;

            .title {
              font-size: 11px;
              line-height: normal;
            }
          }
        }
      }
    }
  }*/

  .flag-text {
    display: none;
  }

  .view-popular-articles {
    .view-content {
      .title {
        margin-left: 110px;
        padding-top: 2px;
      }

      .lead {
        width: 100px;
        margin-right: 0;
      }
    }
  }

  .node.node-teaser {
    .lead-image {
      max-width: 220px;
    }


    // &.node-product,
    &.node-contest-results,
    &.node-contest,
    &.node-article {
      margin: 1.5em 0;

      .content {
        margin-left: 240px;
      }
    }

    /*&.node-group {
      .field-name-field-lead-image {
        width: 120px;
        height: auto;
        max-width: 120px;
        float: left; // clear: both;

        img {
          width: 100%;
          height: auto;
          margin: auto;
        }
      }
    }*/
  }


  .node-product.teaser{
    .price-wrapper,
    .title-wrapper{
      padding: 0;
      float: none;
      width: 100%;
      text-align: left;
    }
    .price-wrapper{
      margin-top: 10px;
    }
  }







  #block-fs_helpers-freestar_InContent,
  #block-fs_helpers-ad_Post_Square {
    margin: 0;
    float: none;
    clear: both;
    width: 100%;
  }

/*  .view-id-popular_profiles2 {
    .views-row {
      width: 100%;
    }
  }
  .view-potm-achievement,
  .profiles-view {
    .views-row {
      width: 49%;
    }
  }*/

  .field-gear-bag ul {
    @include columns(3, 5px);
  }

/*  .gallery_item,
  .gallery_item_uncropped{
      .meta,
      .manage,
      .item-details {
        display: none;
      }
  }*/


  ul.popular-tabs,
  ul.quicktabs-tabs {
    padding-top: 7px !important;

    li {
      font-size: 11px; //margin-right: 0;
      padding-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }




  span.play-icon {
    font-size: 30px;
  }



  /*.view-profile-search {
    .views-row {
      width: 49.5%;
    }
  }*/




  .node-group-post.teaser {
    h2 {
      font-size: 1.6em;
    }

    .field-name-body {
      font-size: 13px;
    }
  }

  .node-group.header {
    div.content {
      h2 {
        margin-right: 0;
      }
    }
  }



  .view-potm-achievement {
    .view-content {
      text-align: center;
    }
  }

  // side navigation. (groups and popular)
  .community-side-navigation {
    .content {
      font-size: 16px;
      .item-list {
        li {
          //font-size: 1.3em;
          //line-height: 1.3em;
        }

        ul {
          margin-bottom: 2em;
        }
      }
    }
  }



  .fs-tabs-nav {
    ul{
      li{
        &:last-child{
          display: none;
        }
        .count{
          display: none;
        }
      }
    }
  }


  /*.webform-client-form{
    padding: 5px 15px;
  }*/




  /*.view-profile-followers,
  .view-users-following,
  .view-subscribe-user{
    .views-row{
      width: 49%;
      //border-bottom: solid 1px #eee;
    }
  }*/

  #node-header {
    .port-item-wrapper {
      height: 480px !important;
    }
  }


}


/* /end only screen and (max-width: $tablet-max-width) and (min-width: $mobile-max-width){ */
