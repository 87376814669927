.jq-dropdown {
  font-size: 14px;
  .ajax-progress,
  .ajax-progress-throbber {
    display: none !important;
  }
}

.jq-dropdown .jq-dropdown-menu LI A,
.jq-dropdown .jq-dropdown-menu LABEL {
  display: block;
  color: #555;
  text-decoration: none;
  line-height: 1.4em;
  padding: 7px 10px;
  margin: 0;
  white-space: nowrap;
  font-weight: normal;
}
.jq-dropdown .jq-dropdown-menu .jq-dropdown-divider {
  padding: 5px 0 0 0;
  margin: 5px 0 0 0 !important;
}
.jq-dropdown .jq-dropdown-menu LI A:hover,
.jq-dropdown .jq-dropdown-menu LABEL:hover {
  background-color: $bright-blue;
  color: #FFF;
  cursor: pointer;
}



.jq-dropdown,
.dropdown{
   ul.jq-dropdown-menu,
   .jq-dropdown-menu,
   .jq-dropdown-panel{
      margin: 0;
      //@include border-radius(2px);
      //@include box-shadow(0, 1px, 1px, 0, rgba(0,0,0,0.2));
      box-shadow: 0px 3px 20px -5px rgba(0, 0, 0, 0.3);
      border-radius: 2px;
   }
   li a.disabled{
      display: none;
   }
   li .ajax-progress{
      display: none;
   }
   .jq-dropdown-menu {
      li{

        width: 100%;
       // line-height: 1.9em;
        a:hover{
          background-color: $bright-blue;
          color: #fff !important;
        }
      }
      .flag,
      .flag-wrapper{
        font-size: inherit;
      }

   }
}
.comment .jq-dropdown{
 // font-size: 12px;
  .jq-dropdown-menu li{
    margin: 0;
  }
}

.jq-dropdown ul.dropdown-list li{
  border-right: none;
  padding: 2px 0 !important;
  //display: inline-block;
  //width: 100%;
}
