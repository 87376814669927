@media only screen and (min-width: $wide-min-width) {

  /* .breadcrumb{
    margin-left: 1.1em;
  } */

  .sticky-col-spacer {
    //margin-bottom: 30px;
  }

  .two-sidebars,
  .sidebar-second {
    .center {
      margin-right: $second_sidebar_width + 50;
      border-right: solid 1px #ddd;
    }
  }

  // Right value.
  #sidebar-second {
    width: $second_sidebar_width + 50;
  }


  #content-area,
  #content-header {
    padding-top: $padding;
  }

  /*.popular-items{
    &.two-sidebars,
    &.sidebar-second {
      .center {
        margin-right: 0;
      }
    }
    &.sidebar-second #content-header,
    &.sidebar-second #content-area{
      padding-right: 1.1em;
    }
    #sidebar-second{
      float: right;
      margin-right: -230px;
      width: 230px;
    }
  }*/
  .region-sidebar-second {
    padding-left: $padding*2;
  }

  #header #logo {
    //width: 250px;
    //padding-top: 13px;
  }

  #navigation {
    //.search {
    //  #edit-t {
    //    width: 270px;
    //  }
    //}
    //
    //#primary {
    //  font-size: 20px;
    //}

    #nav-dropdown {
      /* .view-store {
        .views-row {
          width: 25% !important;
        }

        .views-row-4 {
          display: block;
        }
      }
 */
      /* .groups.nav-section {
        .view-featured-groups {
          width: 96%;

          .views-row {
            width: 33.3%;
            padding-right: 2em;
          }

          .views-row-4 {
            display: block;
          }
        }
      } */
      /* .popular-block {
        padding-right: 45px;
        //max-width: 400px;
      } */
      /*
            .articles,
            .groups,
            .contests{
              .views-row{
                padding-right: 40px;
                //max-width: 400px;
              }
            } */
      /* .view-popular-articles {
        /  * .views-row {
          width: 24%;
        }

        .views-row-4 {
          display: block;
        } *  /

        .node {
          .inner {
            //margin-right: 40px !important;
          }
        }
      } */
    }
  }

  .view-categories {
    .view-content {
      ul {
        column-count: 4;
      }
    }
  }

  .wide-enabled {

    /* .view-rated-photos,
    .view-popular-photos {
      &.no-gridzy {
        .views-row {
          width: 20%;
        }
      } */

    .view.lists {
      .views-row {
        width: 25%;
      }
    }

    /* .view-store {
      .views-row {
        width: 24.5%;
      }
    } */
    .view-store,
    .view-store-catalog {

      /* .view-content {
        margin-left: -4%;

        h3 {
          margin-left: 4%;
        }
      }

      .views-row {
        width: 33.3%;
        padding: 10px 0 20px 4%;
        //max-width: 380px;
      } */
    }

    /*   .view-related-products {
        .view-content {
          margin-left: -2%;
        }
        .views-row {
          //float: left;
          width: 25%;
          padding: 10px 0 20px 2%;

        }
      } */

  }
  body.no-sidebars.narrow-page #content-header,
  body.no-sidebars.narrow-page #content-area {
    margin: 0 !important;
    padding: 0 10px !important;
    border: none !important;
  }
  /* .view-profile-search {
    .views-row {
      width: 24.5%;
    }
  } */
  .node-type-list,
  .node-type-article,
  .wide-enabled {
    &.sidebar-second {

      /*#content-header,
      #content-area {
        //padding-right: 0;
        padding-left: $padding;
        margin-right: $padding*2;
        padding-right: 0;
      }*/
    }

    /*.comment .content {
      font-size: 14px !important;
      line-height: 1.4em !important;
    }*/

    /*     .view-list-items-order,
        .region-hero .userinfo-wrapper,
        //.region-hero .fivestar-container,
        #content-after .inner,
        //nav div.inner,
        //header div.inner,
        #pre-footer div.inner,
       // #pre-header div.inner,
        //#footer div.inner,
        //#crumb-bar-inner,
        #fold-inner,
        #header-inner,
        #main,
        #footer div.inner,
        .actions-wrapper,
        //#footer-inner
          {
          max-width: $max-width + 320;
          margin: auto;
        } */

  }

  /*   .fsPhotoViewer2{
  .comment{
      .content{
        font-size: 12px !important;
        margin-left: 40px;
      }
      .user-picture{
        width: 30px;
      }
    }
  } */


  /*   .node-type-homepage {
      .region-hero .userinfo-wrapper,
      //.region-hero .fivestar-container,
      #content-after .inner,
      nav div.inner,
      header div.inner,
      #pre-footer div.inner,
     // #pre-header div.inner,
      //#footer div.inner,
      #crumb-bar-inner,
      #fold-inner,
      #header-inner,
      #main,
      #footer div.inner
        {
        max-width: $max-width + 320;
        margin: auto;
      }
    } */

  //.wide-enabled {
  #footer {
    .socicons {
      .icon-wrapper {
//        font-size: 45px;
      }
    }

    .footer-col.last {
      //float: right;
      padding-right: 0; //20px;
      padding-left: 0;
      width: auto;
    }

    .footer-col {
      font-size: 14px;
      line-height: 1.5em;
      //margin: 1.5em 1em;
    }
  }

  //}
  .wide-only {
    display: block;
  }


  .node-type-article {
    #content-header {
      //padding-right: 110px;

      h1.title {

        font-size: 3.2em;
        //line-height: 1em;

      }
    }
  }

  .author-block {
    padding: 2em;
  }
  .share-header {
    font-size: 12px;
    text-transform: uppercase;
    color: $off-black;
    padding-top: 10px;
    border-bottom: solid 1px #eee;
    margin-bottom: 5px;
  }

  .field-name-field-lead-video,
  .field-name-field-lead-image {
    img {
      width: 100%;
      max-width: none;
    }
  }

  .taxonomy-term {
    .field-name-field-lead-video,
    .field-name-field-lead-image {
      img {
        width: auto;
        max-width: 100%;
      }
    }
  }

  //
  .buy-now {
    .soldout,
    .button {
      a {
        font-size: 18px;
      }
    }
  }

  .node-article.full {

    .comments,
    .node-content {
      margin-left: 170px;
    }

    header {
      .sharing-wrapper {
        float: left;
        display: block;
      }

      .liking {
        font-size: 12px;
        position: absolute;
        //top: 40px;
        margin: 10px 0 0 0;
        padding: 0;
        width: 130px;

        //&.stuck,
        &.stuck {
          position: fixed;
          top: 70px;
        }

        &.bottomed {
          position: absolute;
          bottom: 0;
        }

        .form-item {
          width: 100%;
          float: left;
          margin-right: 0;
          margin-bottom: 4px;
        }

        .flag-text {
          display: inline-block;
        }
      }

      .helpers-share {
        float: none;
        clear: both;

        .total-shares-wrapper {
          border-bottom: solid 1px #eee;
          margin-bottom: 5px;
          padding-bottom: 3px;
          clear: both;
          margin: 0;
          display: block;
          text-align: left;

          .total-shares {
            font-size: 40px;
            letter-spacing: -0.08em;
            color: #111;
            font-weight: 600;
            display: inline-block;
            margin-right: 5px;
          }

          span {
            text-transform: uppercase;
            color: #111;
            display: inline-block;
            font-size: 12px;
          }
        }

        .social-shares {
          display: block;
          margin: 3px 0;
          float: none;
          clear: both;
          padding: 0;

          .share-item {
            display: block;
            margin: 3px 0;
            float: none;
            clear: both;
            padding: 0;
          }

          .label {
            display: inline-block;
            vertical-align: middle;
            //font-weight: 600;
            font-size: 12px;
            padding-left: 0.5em;
          }
        }
      }

      .item {
        display: block;
        clear: both;
        margin: 3px 0;
        width: 100%;
        float: left;

        a {
          padding: 0; //0.5em 0.6em;
          //background: #f7f7f7;
          color: #666;
          text-decoration: none;
          display: block;
          font-size: 12px;
          font-weight: 600;

          a:hover {
            color: $off-black !important;
          }

          i {
            min-width: 1em;
            font-size: 1.2em;
            text-align: center;
            width: 25px;
          }

          .label {
            font-size: 12px;
            padding-left: 0;
          }
        }
      }

      div.rate-up-down ul {
        width: 100%;
        line-height: 1.5em;

        li {
          width: 50%;
        }

        i {
          font-size: 16px;
          vertical-align: middle;
        }

        .rate-button {
          display: block;
          width: auto;
          float: none;
        }

        .count {
          font-size: 14px !important;
          font-weight: normal;
          float: right;
          line-height: 34px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 30px;
          right: 5px;
        }
      }
    }
  }


  .node-group-post.teaser {
    .content {
      .col-2 {
        text-align: left;
        padding-top: 10px;

        .comment-count {
          font-size: 20px;
        }
      }
    }
  }

  //.view-homepage-articles2{
  .node.node-teaser.node-contest,
  .node.node-teaser.node-article {


    .lead-image {
      max-width: 370px;
    }

    .content {
      margin-left: 400px;
      font-size: 16px;
      //font-size: 1.35em;
      //line-height: 1.3em;
    }

    .submitted {
      //padding-bottom: 1em;
    }
  }


  .fs-tabs-nav {
    ul {
      //li:last-child,
      li {
        font-size: 20px;
        margin: 0; // 20px;
      }
    }

  }


  /*.view-gear-bag,
  .view-gear-bag-preview2,
  .view-recent-user-licenses{
    .view-footer {
      float: left;
      margin: 1em 0 1em 0;
      padding: 1em;
      width: 23%;
      max-width: 230px;
      font-size: 16px;
      line-height: 1.3em;
      text-align: center;
      clear: none;
    }
  }*/





}

@media only screen and (min-width: $xtra-wide-min-width) {


}
