@import "_config.sass";

@media only screen and (max-width: $mobile-max-width),
only screen and (max-device-width: $mobile-max-width) {
  select:focus,
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"] {
    font-size: 16px !important;
  }
  html.js input.form-autocomplete {
    background-image: url(../images/throbber2.gif);
    background-position: 99% 13px;
  }
  html.js input.throbbing {
    background-position: 99% -38px;
  }
  /* body {
    #takeOver {
      margin-top: 0 !important;
      display: none !important;
    }
    .pushdown,
    #pushDown {
      &.open {
        height: 400px;
      }
    }
  } */
  body.adminimal-menu:before {
    height: auto;
  }
  body.admin-menu {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  body.admin-menu.adminimal-menu:before {
    height: auto !important;
  }
  .slicknav_btn {
    display: none;
  }
  /*   #content-after div.inner,
   // nav div.inner,
   // header div.inner,
    #pre-footer div.inner, //#pre-header div.inner,
    #banner div.inner,
    #footer div.inner,
  //  #crumb-bar-inner,
    #fold-inner,
    #header-inner,
    #main,
    #footer-inner {
      min-width: inherit;
    } */
  .sidebar,
  #content {
    float: none !important;
    clear: both;
  }
  .sidebar {
    padding-top: calc($padding * 2)
  }
  #content-inner {
    border: none !important;
    margin-right: 0;
  }
  /*#sidebar-second .block {
    margin: 0 0 4em 0;
  }*/
  #content {
    .region-content {
      //padding: 5px;
    }
  }
  body.portfolio-item {
    #main {
      padding-top: 10px;
    }

    #sidebar-second {
      max-width: 100%;
    }
  }
  #fold {
    margin-left: calc($padding / -2);
    margin-right: calc($padding / -2);
  }

  #main {
    padding-top: 0; //5px;
    padding-left: calc($padding / 2);
    padding-right: calc($padding / 2);
  }


  // Left value.
  .two-sidebars,
  .sidebar-first {
    .center {
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
  }
  // Left value & negative left value.
  #sidebar-first {
    width: 100% !important;
    margin: 0 !important;
  }
  .two-sidebars,
  .sidebar-second {
    .center {
      margin: 0;
      padding: 0;
    }
  }
  // Right value.
  #sidebar-second {
    width: 100% !important;
    max-width: 400px;
    margin: auto;
  }
  /*   #banner,
  //#pre-header,
  #pre-footer {

    #block-fs_helpers-ad_Footer_Small,
    #block-fs_helpers-ad_Header_Small {
      float: none;
      text-align: center;
      width: auto;
      background: none;
    }
  } */
  #menu-background,
  body.menu-blocked #menu-background {
    display: none;
  }
  /* #banner {
    display: none;
  } */
  //.prev-next-wrapper,
  //.block-prev-next,
  #block-context_ui-devel {
    display: none;
  }
  #admin-menu {
    display: none;
  }
  /* #block-fs_helpers-freestar_InContent,
  #block-fs_helpers-ad_Post_Square {
    margin: 0;
    float: none;
    clear: both;
    width: 100%;
  } */
  .one-sidebar.sidebar-first {
    #content-header,
    #content-area {
      //padding-left: 5px !important;
      //padding-right: 5px !important;
    }
  }
  .no-sidebars,
  .sidebar-second {
    #content-header,
    #content-area {
      //padding-right: 5px;
      //padding-left: 5px;
      //padding-top: 5px;
    }
  }
  .region-sidebar-second {
    //padding: 5px;
  }
  ul.tabs.secondary {
    width: 100%;

    &.tabs-2 li {
      width: 50%;
    }

    &.tabs-3 li {
      width: 33.333%;
    }

    &.tabs-4 li {
      width: 25%;
    }

    &.tabs-5 li {
      width: 20%;
    }

    li a {
      display: block;
      float: none;
      font-size: 11px;
      line-height: 24px;
      padding: 5px;
    }
  }

  div.messages {
    margin: 5px 0;
  }
  .mobile-hide {
    display: none !important;
  }
  // @todo - indropdown css?
  .jq-dropdown-menu .flag-text,
  .jq-dropdown-menu .mobile-hide {
    display: initial !important;
  }
  #page {
    position: relative;
  }
  .main-stage-wrapper {
    position: relative;
    margin-top: 43px;
    clear: both;
    width: 100%;
    overflow: hidden;
  }
  .no-header {
    .main-stage-wrapper {
      margin-top: 0;
    }
  }
  .main-stage {
  }
  .mobile-panel {
    display: none;

    &.open {
      position: relative; //top: 0;
      //bottom: 0;
      //left: 0;
      //right: 0;
      background: #fff; //z-index: 1;
      display: block;
      clear: both;
    }
  }
  .breadcrumb {
    //margin-left: 7px; //10px;
  }
  /* .follow-fstoppers {
    display: none;
  } */

  ul.action-links {
    padding-top: 5px;

    li {
      a {
        font-size: 11px;
      }
    }

    .label {
      display: none;
    }
  }

  h1.title {
    font-size: 2em;
  }
  .node-type-article {
    h1.title {
      font-size: 2.1em;
    }
  }

  /* .node-type-product h1.title,
  .node-type-article h1.title {
    font-size: 2.2em;
  } */
  .original-article {
    bottom: 0;
  }
  .node {
    .helpers-share {
      .share-item a {
        padding: 0 5px !important;
      }

      .share-item:last-child {
        margin-right: 0;
      }
    }
  }
  .flag-text {
    display: none;
  }
  .full {
    .field-name-field-lead-video {
      padding-bottom: 0;
    }

    header {
      .sharing-wrapper {
        .liking {
          margin-left: 0;
          float: right;
          clear: both;
          padding: 5px 0;
          text-align: center;
          height: 46px;

          .icon {
            //line-height: 28px;
          }
        }

        .total-shares-wrapper {
          display: none;

          .total-shares {
            font-size: 13px;
          }
        }
      }

      .submitted {
        font-size: 14px;
        border-bottom: none;
        margin-bottom: 5px;
        padding-bottom: 0; //line-height: 1.25em;
      }

      .rate-up-down {
        line-height: 22px !important;
      }
    }

    .field-name-field-lead-image {
      margin: 0 calc(($padding / 2) * -1);
      padding: 10px 0 0 0;
    }

    .view-related-products.view-display-id-article_block {
      //padding-top: 3em;
      // clear: both;
      .views-row {
        width: 100%;
        float: left;
        padding: 5px;

        s
        .node-product.teaser {
          //max-width: 100%;
        }

        .price-wrapper {
          //display: none;
        }

        .content {
          margin: 0;
          border: none;
        }

        .catalog-image {
          width: 125px;
          float: left;
        }

        .product-copy {
          margin-left: 125px;
          display: block;
          float: none;
          padding: 5px 15px;
          width: auto;
        }

        .title-wrapper {
          width: 100%;
          padding: 0;
          letter-spacing: normal;

          .title {
            //font-family: $header-family;
            font-size: 18px;
          }

          .subtitle {
            font-size: 14px;
            letter-spacing: normal;
          }
        }

        //}
      }

      .view-header {
        font-size: 16px;
        padding-bottom: 10px;
        line-height: 1.2em;
      }

      .view-content {
        margin: 0;
      }
    }

    /*.field-name-field-related-articles,
    .related-articles {
      .view-content {
        gap: 0;
        display: block;
      }
    }*/

    /* .field-name-field-related-articles,
    .related-articles {
      .view-content {
        margin: auto;
      }
      .field-items {
        margin: 0;
      }
      .field-items>.field-item,
      .field-items>.field-item:first-child,
      .views-row:first-child,
      .views-row {
        width: 100% !important;
        padding: 5px;
        .inner {
          .title {
            //font-size: 16px;
            line-height: normal;
            clear: none;
          }
          .category {
            //display: none;
          }
          .content {
            border-bottom: none;
            margin-left: 125px;
            padding: 5px 15px;
            .meta {
              line-height: 15px;
              margin-top: -3px;
            }
          }
          .field-name-field-lead-image {
            float: left;
            width: 125px;
          }
          .title-wrapper {
            padding: 0;
            font-size: 16px;
            line-height: 1.2em;
            border-bottom: none;
            clear: none;
          }
        }
      } */
    //}
    .content {
      border-bottom: solid 1px #ddd;
      margin-bottom: 10px;
    }

    &.float-sharing {
      .content-wrapper {
        margin-left: 0;
        margin-right: 0;
      }

      .liking {
        float: none !important;
        width: 100%;
        text-align: left;
        border-bottom: solid 1px #eee !important;

        .icon {
          margin-right: 0 !important;
          width: 37px;
        }

        .item {
          margin: 0 2px 0 0;
          width: auto;
        }

        .helpers-share {
          width: auto;
          text-align: right;
          padding-top: 0;
        }

        .share-item {
          display: inline-block;
          margin: 0 0 0 2px;
        }

        /* .jssocials-share {
          display: inline-block;
          margin: 0 0 0 2px;
        }
        */
        .form-item {
          width: auto;
          float: left;
          margin-right: 5px;
        }

        div.rate-up-down {
          margin: 0;

          .rate-button {
            margin: 0 !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }

          ul {
            float: none;
            white-space: nowrap;
          }

          li {
            width: auto;
            float: none;
            margin-bottom: 0 !important;

            a {
              line-height: 34px;
              padding: 0 10px;
            }
          }

          .count,
          i {
            display: inline-block !important;
            width: auto;
          }
        }
      }
    }
  }
  a.comment-link-trigger {
    display: block;
  }
  .comments-forbidden {
    font-size: 24px;
    padding: 3em 1em;
  }
  .comment {
    //padding: 0.5em 0;

    .user-picture {
      width: 35px;
    }

    .content {
      // margin-left: 40px;
      border-bottom: none; //none !important;
      font-size: 16px !important;
      padding-bottom: 0;
      margin-bottom: 0;

      p {
        margin: 0 0 0.4em 0;
      }
    }

    ul.links {
      li i {
        //font-size: 20px;
        //display: none;
      }
    }
  }
  .comments {
    .indented {
      padding-left: 0px;
      //border-left: 2px solid #eee;
      margin-left: 5px;
      //margin-top: 5px;
      //margin-bottom: 5px;

      .comment {
        //margin-left: 40px;
        //overflow: hidden;
        .content {
          //margin-left: 35px;
        }

        .user-picture {
          width: 30px;
          margin-left: 0;
        }
      }
    }

    div.links .links {
      //float: right;
      font-size: 14px;

      li {
        //float: right;
        //font-size: 12px;
        //padding: 0 7px;
        //max-height: 25px;
      }

      /*.comment-delete {
        float: right;
      }*/

      /*.comment-reply{
        float: left;
      }*/
    }
  }
  .ajax-comments-reply-cancel:link,
  .ajax-comments-edit-cancel:link {
    clear: both;
    font-size: 16px;
    text-align: center;
    display: block;
    padding: 1em;
    margin: 5px 0;
    background: #fff;
    float: none;
    border: solid 1px #ddd;
    width: auto;
    text-decoration: none;
  }
  /*.comment-delete{
   display: inherit;
   position: inherit;
   top: inherit;
    right: inherit;
}
.comment .comment-delete{
   display: inherit;
  font-size: inherit;
}*/
  .comment .commentaccess-delete,
  .comment .comment-delete {
    display: inline;
    position: relative;
    top: inherit;
    right: inherit;
  }
  .comment:hover .commentaccess-delete,
  .comment:hover .comment-delete {
    display: inline;
  }
  #comment-count {
    font-size: 1.3em;
  }
  .helpers-share {
    .share-item {
      font-size: 1.3em;

      a {
        //padding: 7px;
      }
    }
  }
  .node-contest .field-name-body,
  .node-article .field-name-body {
    //font-size: 1em;
    //line-height: 1.5em;
    clear: both;
  }
  .node.node-teaser {
    margin: 1em 0;
    padding: 0.5em 0;

    .field-name-field-results-body,
    .field-name-body {
      /*display: initial;
      //-webkit-line-clamp: $lines;
      overflow: initial;
      text-overflow: initial;
      white-space: initial;*/

    }

    h2 {
      //font-size: 2em;
      //padding: 0 0 5px 3px;
      //line-height: 1em;
    }

    .lead-image {
      max-width: inherit;
      width: auto;
      float: none;
      margin: 0 calc(($padding / 2) * -1); //margin-bottom: 5px;
    }

    .field-name-field-lead-image {
      max-width: inherit;
      width: 100%;
      clear: both;
      float: none;
      padding: 0;
      margin: 0;
      border: none;

      img {
        width: 100%;
        max-width: inherit;
      }
    }

    &.node-contest .content,
    &.node-article .content {
      float: left;
      width: 100%;
      clear: both;
      font-size: 16px;
      line-height: 1.5em;
      margin: 0;
      padding: calc($padding / 2) 0;

      .submission-count,
      .article-comments {
        float: right;
        font-size: 14px; //font-size: 18px;
        a {
          vertical-align: middle;
        }

        strong {
          //font-size: 16px;
        }
      }
    }

    .submitted {
      //margin: 7px 0 5px 0;
      font-size: 13px; //padding: 0;
      .user-picture {
        width: 30px;
      }

      /* .date {
        font-size: 11px;
      } */
    }

    .comments-label {
      display: none;
    }

    .field-name-body {
      //font-size: 1em;
      //line-height: 1.5em;
    }

    .meta {
      .read-more {
        a {
          font-size: 16px;
        }
      }
    }
  }

  /*   #homepage-fold {
    #layerslider-container {
      //display: none;
      width: 100%;
      float: none;
      clear: both;
      display: block;

      #promo_slides img {
        width: 100%;
        height: auto;
      }
    }

    .slide-headline {
      font-size: 18px;
    }

    .popular-photos,
    .popular-discussions {
      clear: both;
      width: 100%;
      padding: 0;
      margin: 1em 0;
      display: block;
      float: left;
      font-size: 11px;
      line-height: normal;

      .block {
        padding: 0;
      }
    }

    .view-popular-profiles {
      .name {
        font-size: 11px;
      }
    }
  } */
  #slider-thumbnails {
    margin-left: -2px;
    margin-right: -2px;

    a {
      padding: 0 2px;
    }
  }
  .sub-navigation {
    a {
      padding: 0 10px;

      &.active {
        background-position: center bottom -7px;
      }

      i {
        margin-right: 3px;
      }
    }

    .item-list {
      ul {
        li {
          font-size: 14px;
          line-height: 40px;
        }
      }
    }

    a.add-contest-results {
      display: none;
    }
  }
  #node-header {
    .userinfo {
      .user-picture {
        width: 50px;
      }

      h1 {
        font-size: 1.8em;
      }

      .user-details {
        margin-left: 60px;
      }

      /*       .flag-wrapper{
        float: right;
      } */
    }


    .share-buttons {
      padding-bottom: 10px;
    }

    .field-name-field-photo {
      padding: 0 0 5px 0;
    }
  }
  /*   a.top-scroll {
    display: none;
    bottom: 70px;
    font-size: 9px;
    right: 1em;
    z-index: 5;
  } */
  #footer {
    .footer-col {
      clear: both;
      display: block;
      float: none;
      margin-right: 0;
      padding-left: 0;
      text-align: center;

      h4 {
        font-size: 1.9em;
        padding: 10px 0 5px 0; //border-top: solid 1px #333;
      }

      ul.menu {
        float: none;
        margin: 0;
        padding: 0 0 10px;
        text-align: center;
        width: 100%;
      }

      li {
        font-size: 1.6em;
        margin: 0;
        padding: 7px 0;
      }

      &.last {
        clear: both;
        float: none;
       // text-align: center;
        margin: 30px auto;
        padding: 0;
        text-align: center; //width: 160px;
        .social div {
          margin: 5px;
        }

        .follow {
          width: 100%;
          max-width: 100%;

          .icon-wrapper {
            //font-size: 46px;
          }
        }
      }

      .socicons {
        float: none;
        font-size: 20px;
      }
    }
  }

  .region-fold {
    padding: 0 5px;
  }
  .node-group .editor-buttons {
    display: block;
  }
  .comment.user-comment {
    .user-picture {
      display: none;
    }

    .content {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .view-user-comments {
    h3 {
      font-size: 14px;
    }


    .rate-widget {
      display: none;
    }
  }

  .user-comment {
    .submitted {
      //font-size: 11px;
    }
  }

  html.js div.contextual-links-wrapper {
    display: none;
  }
  #block-fs_helpers-homepage_articles {
    padding-top: 15px;
  }
  .node-list.node-teaser {
    margin: 0 4px 4px 0 !important;
  }

  .view-profile-photos:not(.gridzy-view) {
    .views-row {
      width: 33.3% !important;
      border: none !important;
    }
  }
  .gridzy-view {
    .views-row {
      width: inherit;
      border: none !important;
    }
  }
  // No rollovers on mobile.
  .gallery_item_uncropped,
  .gallery_item {
    &:hover {
      .meta,
      .manage,
      .item-details {
        display: none !important;
      }
    }
  }
  form#user-pass-reset,
  form#user-pass,
  .login-panel {
    padding: 0;
    border: none;

    h1 {
      font-size: 1.8em;
    }

    .intro {
      font-size: 1.2em;
      padding: 0;
    }

    .edit-actions,
    #edit-actions {
      //text-align: center;
      padding: 0;
    }
  }
  .register-alt-links {
    font-size: 1.1em;
    line-height: 1.5em;
    text-align: center;
  }
  .form-item-fs-tos-agree label.option {
    font-size: 1.1em !important;
  }
  #user-register-form {
    .form-item-fs-tos-agree,
    .captcha {
      margin-left: 0 !important;
      padding-left: 0 !important;
      font-size: 0.85em;
    }
  }
  div.messages {
    //font-size: 12px;
    //line-height: normal;
    //padding: 10px;
  }
  #content-header {
    div.messages {
      margin-left: 0;
      margin-right: 0;
    }

    #tab-trigger {
      margin: 5px 5px 5px 0;
    }
  }
  #block-views-potd_pager-freepager_1,
  #block-views-portfolio_pager-freepager_1 {
    .freepager-previous,
    .freepager-next {
      font-size: 20px;
      line-height: 30px;
      height: 30px;
    }

    .freepager-previous {
      left: 1px;
    }

    .freepager-next {
      right: 1px;
    }
  }
  .potd-badge {
    position: absolute;
    text-align: left;
    bottom: initial;
    height: auto !important;
    width: auto;
    background: none;
    top: 5px;
    left: 5px;

    .trophy {
      display: inline-block !important;
      float: none;
      vertical-align: middle; //border-right: solid 1px #333;
      //margin-right: 5px;
      padding-right: 5px;
      width: 40px;
      height: 40px !important;

      i.fa {
        font-size: 25px;
      }

      .potd-label {
        font-size: 8px;
        display: none !important;
      }
    }

    .badge-date {
      display: inline-block !important;
      float: none;
      vertical-align: middle;
      font-size: 12px;
      line-height: 11px;
      margin-top: 0;
      padding: 0;
      position: relative;
      right: 0;
      width: auto;
      text-align: left;
      margin-right: 0;

      .year {
        font-size: 9px;
      }
    }
  }
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    margin: 0;
    width: 100%;
    border: 1px solid #bbb;
  }
  select {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
    //line-height: 40px;
    //font-size: 17px;
    border: 1px solid #bbb;
  }
  input[type=text],
  input[type=url],
  input[type=email],
  input[type=password],
  input[type=tel] {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    margin: 0;
    width: 100%; //height: 40px;
    //line-height: 40px;
    font-size: 16px;
    border: 1px solid #bbb;
  }
  input[type=checkbox] {
    width: 24px;
    height: 24px; //-webkit-border-radius: 22px;
    //-moz-border-radius: 22px;
    //border-radius: 22px;
    border: 1px solid #bbb;
  }
  button[type=submit] {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    margin: 1.5em 0;
    font-size: 1em;
    line-height: 2.5em;
    color: #333;
    font-weight: bold;
    height: 2.5em;
    width: 100%;
    background: #fdfdfd;
    background: -moz-linear-gradient(top, #fdfdfd 0%, #bebebe 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fdfdfd), color-stop(100%, #bebebe));
    background: -webkit-linear-gradient(top, #fdfdfd 0%, #bebebe 100%);
    background: -o-linear-gradient(top, #fdfdfd 0%, #bebebe 100%);
    background: -ms-linear-gradient(top, #fdfdfd 0%, #bebebe 100%);
    background: linear-gradient(to bottom, #fdfdfd 0%, #bebebe 100%);
    border: 1px solid #bbb;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .form-actions input.form-submit,
  .edit-actions input.form-submit,
  #edit-actions input.form-submit {
    font-size: 20px;
    margin: 0.3em 0;
    width: 100%;
    padding: 0;
  }

  #edit-cancel,
  a.cancel-link {
    clear: both;
    float: none;
    margin: 10px 0;
    font-size: 1.5em;
    padding: 0.5em;
    display: block;
    background: #eee;
    @include border-radius(2px);
    text-decoration: none;
    text-align: center;
  }


  .node-product.teaser {
    margin: 0;
    padding: 0;
    max-width: 100%;
    /* margin: 0; */
    .catalog-image {
      /* float: none;
      margin-right: 0;
      width: 100%;
      margin-bottom: 0; */
      .sale-tag {
        background-image: url("../images/sale-tag-small.png");
        font-size: 13px;

        .label {
          line-height: 2em;
          padding: 0 15px 0 10px;
          display: block;
        }
      }
    }

    /* .product-copy{
      display: block;
      margin-left: 50%;
      width: auto;
      padding: 0 1em;
    }
    .title-wrapper,
    .price-wrapper{
      display: inline-block;
      float: none;
      width: 100%;
      text-align: left;
    }
    .price{
      font-size: 18px;
    }
    .price-wrapper{
      margin-top: 1em;
    }
    .title {
      font-size: 18px;
    } */
  }


  .view-featured-photos {
    .view-content {
      .views-row {
        width: 33.3%;

        &:hover {
          .views-field-title {
            display: none;
          }
        }
      }
    }
  }

  // @TODO - GOTTA BE A BETTER SELECTOR?
  #content-after #block-views-related_products-block h2.block-title,
  .node .content h3.section-header,
  #comment-count {
    margin-bottom: 0.5em;
    font-size: 20px;
  }
  .node-product.full,
  .node-article.full {
    .node-content {
      //font-size: 1.3em;
      //line-height: 1.7em;
    }
  }


  /*.view-popular-profiles,
  .profiles-view{
    .views-row,
    .row {
      width: 100%;
      border-bottom: solid 1px #eee;
      padding-right: 0;
    }
  }*/

  // @TODO NEEDS TO BE ONLY FOR GUIDE
  #block-profile_extras-profile_extras_profile_guide {
    .block-inner {
      padding: 0.5em;
      font-size: 1.1em;
      margin: 0.5em 0;
      line-height: 1.3em;
      padding-right: 20px;
    }

    .dismiss {
      font-size: 13px;

      .label {
        display: none;
      }
    }
  }
  #block-views-user_image-block {
    display: none;
  }
  #block-helpers-helpers_myaccount_menu {
    display: none;
  }
  ul.tabs {
    display: none;
  }
  div.tabs {
    margin: 0 !important;
  }
  a.fancy-trigger {
    display: block;
    margin-top: 0;
  }
  #edit-field-photo {
    margin: 0;
  }
  .image-preview {
    float: left;
    width: 100%;
    clear: block;
    padding: 5px;

    img {
      width: 100%;
      height: auto;
    }
  }
  .node.full {
    .portfolio-thumbs {
      li {
        padding: 1px;
        width: 25%;
      }

      li:last-child {
        display: none;
      }
    }

    .field-name-field-caption {
      font-size: 0.75em;
      line-height: normal;
    }
  }
  .node-photo.full {
    .field-name-body {
      font-size: 1em; //padding-top: 0.4em;
    }
  }


  .followers-popup {
    min-width: inherit;
    overflow: hidden;

    .view-users-following {
      td.views-row {
        float: left;
        width: 100%;
        min-width: 250px;
        padding: 5px;
        display: block;
        clear: both;
      }

      table.views-view-grid {
        width: 100%;
      }
    }
  }
  .view-popular-photos {
    .views-row {
      width: 33.33%;

      .meta {
        display: none;
      }

      .gallery_item {
        padding: 1px;

        div.title a {
          font-size: 11px;
        }

        div.title,
        div.submitted {
          display: none;
        }
      }
    }

    // @todo - take another look at this,
    /* popular lists page */
    &.lists,
    &.view-display-id-page_3 {
      .views-row {
        width: 50%;
        /*.node{
          padding: 5px 0.2em 0 0;
          margin: 0 !important;
          .content{
            padding: 0 3px;
          }
          .submitted{
            padding: 0;
            margin: 0;
          }
        }*/
      }
    }
  }

  /*   .fstoppers-original #content-header {
    background-size: 70px auto; //padding-right: 70px;
    margin-right: 5px;
    min-height: auto;
    background-position: right center;
  } */
  .view-users-following,
  .view-subscribe-user {
    table {
      border: none;
    }

    td {
      display: block;
      padding: 0;
      border: none;
      min-height: 80px;
      width: 100% !important;
    }
  }
  .view-content-following {
    .views-field-comment-count {
      display: none;
    }

    .flag-subscribe-node {
      white-space: nowrap;
    }
  }
  //.view-profile-search,

  /* .view-profile-search {
     .views-row {
       width: 100%;
       padding-bottom: 5px;
     }
   }
   .node-list.node-teaser {
     .content {
       line-height: normal;

       .title {
         font-size: 12px;
       }
     }
   }*/
  .manage {
    a {
      font-size: 21px;
    }

    .label {
      display: none;
    }
  }
  .pm-title {
    font-size: 1.4em;
    font-weight: bold;
  }
  .pm-thread {
    .privatemsg-message-participants {
      font-size: 11px;
    }
  }
  .privatemsg-message {
    line-height: normal;

    .privatemsg-author-avatar {
      .user-picture {
        width: 40px;
      }
    }

    .privatemsg-message-column {
      margin-left: 45px;
    }
  }
  .fancybox-title-inside-wrap {
    display: none;
  }
  .view-authors {
    .view-content {
      .views-row {
        margin: 5px 0;
        width: 100%;
      }
    }
  }
  .infinite-scroll {
    ul.pager {
      li {
        a {
          width: 100%;
          padding: 0;
        }
      }
    }
  }


  #comments a.morecomments-button {
    width: 100%;
  }

  .author-block {
    .flag-subscribe-user {
      display: block;
      width: 100%;
      margin: 10px 0 0;
    }

    .bio {
      font-size: 14px;
    }
  }
  //  @todo - takeover should not be here, rigght?
  body.takeover {
    padding-top: 0;

    a#takeOver {
      background-size: 150% !important;
    }
  }
  .drop-footer,
  .drop-content {
    background: #fff;
  }
  .menu-dropdown {
    max-width: 100%;
    width: 100%;
    height: auto;
    position: relative;
    left: 0;
    right: 0; //bottom: 0;
    top: 0;
    background: #fff;
    @include box-shadow(0,
      2px,
      3px,
      0,
      rgba(0, 0, 0, 0.1));
    border: none;
    display: none;

    &.open {
      display: block;
    }

    .view-activity-notifications {
      line-height: normal;
      height: auto;
      overflow: visible;
      color: #363636;

      a,
      a:link,
      a:visited {
        color: $off-black;
        font-weight: bold;
      }

      .views-row {
        padding: 0;
      }
    }

    .more-link {
      display: none;
    }

    .drop-footer {
      height: 30px;
      border-top: solid 1px #ccc;
      line-height: normal;
      text-align: center;
      font-weight: bold;
      padding-top: 5px;

      a {
        color: $bright-blue;
        line-height: normal;
      }
    }

    .drop-header {
      height: 30px;
      border-bottom: solid 1px #ccc;
      line-height: 30px;
      padding-left: 5px;
      font-size: 13px;
      color: #fff;
      background-color: $ui-dark;
      font-weight: bold;

      .links {
        float: right;
        font-weight: normal;

        a {
          color: #fff !important;
        }
      }
    }
  }
  .view-activity-notifications {
    .entity-message {
      //padding: 5px;
      //border-bottom: solid 1px #eee;
      .preview-image {
        img {
          max-width: 60px;
        }
      }

      a.message-dropdown-trigger {
        font-size: 20px;
        padding: 7px 0px 7px 5px;
      }

      .message-link {
        margin-right: 30px;
      }

      /* .content{
        margin-right: 30px;
      } */
    }
  }
  ul.popular-tabs,
  ul.quicktabs-tabs {
    float: left;
    margin: 0 -5px 5px 0;
    width: 100%;
    overflow: auto;
    white-space: nowrap;

    li {
      font-size: 11px;
      margin-right: 3px;
      float: none;
      display: inline-block;
    }
  }

  #hero {
    .page-header-block {
      .block-inner {
        padding: 20px 10px;

        .block-title {
          letter-spacing: normal;
          text-transform: uppercase;
          font-size: 18px;
          font-weight: bold; //color: $bright-blue;
        }

        .content {
          font-size: 16px;
          width: 100%;
        }
      }
    }
  }
  .sidebar-menu-trigger {
    position: relative;
    padding: 5px 0 $padding 0;
    display: block; //top: 60px;
    //left: -1px;
    //z-index: 9999;
    a {
      display: inline-block;
      font-size: 14px;
      background-color: #fcfcfc;
      text-decoration: none;
      padding: 10px;
      border-radius: 3px;
      border: solid 1px #eee;
      color: #666; //width: 40px;
      text-align: center;

      span {
        font-size: 9px;
        text-transform: uppercase;
        vertical-align: middle;
        display: inline-block;
      }
    }
  }


  .node.header {
    min-height: 175px;
    padding-left: 0;

    div.content {
      padding: 0; // 0.4em;
      margin: 0 5px;

      h2 {
        line-height: 1em;
        font-size: 1.4em;
        padding-top: 5px; //font-weight: 300;
        margin-right: 0;
      }

      .small-title {
        font-size: 13px;
      }

    }
  }

  .node.full {
    /*h1 {
      font-size: 1.8em;
      letter-spacing: normal;
    }*/

    /*header {
      clear: both;

      .user-picture {
        width: 35px;
      }

      .submitted {
        margin-left: 42px;
        border: none;
        padding: 0.2em 0;

        .username {
          font-size: inherit;
          padding: 0.2em 0;
        }

        .date {
          font-size: 12px;
          margin-bottom: none;
        }
      }
    }*/

    .content-col,
    .comments-col {
      margin: 0;
      width: 100%;
      float: none;
      padding: 0;
    }

    .comments-col .comment-wrapper {
      margin-left: 0;
      padding: 0;
      border: none;
    }
  }


  div.vertical-tabs .vertical-tabs-panes {
    margin: 0px 0px 0px 0px !important;
    padding: 0px !important;
    display: block !important;
    width: 100% !important;
    float: none !important;
    max-width: none !important;
  }
  div.vertical-tabs .vertical-tabs-list {
    width: auto !important;
    margin: 0px;
    border: none !important;
    display: block !important;
    float: none !important;
  }
  div.vertical-tabs ul.vertical-tabs-list li {
    border-left: none !important;
    border-right: none !important;
  }
  div.vertical-tabs-panes fieldset {
    width: 100% !important;
    max-width: none !important;
  }
  div.vertical-tabs-panes .fieldset-wrapper {
    max-width: none !important;
    width: 100% !important;
  }
  div.vertical-tabs {
    margin: 0px;
    background: none !important;
  }


  .moderate-abuse-link,
  .report-abuse-link {
    padding: 0;
  }

  .view-empty,
  .views-empty {
    font-size: 1.7em;
    padding: 2em 1em;
  }


  #content-after {

    padding: calc($padding / 2);

    // background: #fff;
    .inner {
      padding: 0;
      //padding-bottom: 1em;
    }

    .block-inner {
      padding: 0 5px;
    }

    .view-related-media,
    .view-editors-favorites {
      padding: 0;

      .row,
      .views-row {
        width: 33.33%;

        .views-field {
          padding: 1px 1px 0 1px;
        }
      }
    }

    h2.block-title {
      margin: 0;
      padding: 0 0 3px 1px;
      font-size: 11px;
    }
  }


  /*.profile-lists .views-row {
    width: 100%;
  }*/
  .nsfw-warning {
    font-size: 16px;
    padding: 2em 1em;
  }
  .nsfw-overlay {
    font-size: 11px; //padding-top: 30%;
  }
  span.play-icon {
    font-size: 20px;
  }
  .popular-items {
    h1.title {
      font-size: 25px;
    }
  }
  .region-hero {
    #node-header {
      padding-top: 0;

      .photo {
        height: auto; //padding: 10px 0;
      }

      .video-padding {
        //padding: 25px 50px 0 50px;
      }

      .video-wrapper,
      .photo-wrapper {
        border-bottom: none;
        padding: 0;
        position: relative;

        img {
          width: 100% !important;
          height: auto !important;
          @include center-vertical-reset();
        }
      }

      .video-wrapper {
        //visibility: hidden;
        overflow: hidden;

        div {
        }
      }
    }
  }
  #node-header {
    .port-item-wrapper {
      height: auto !important;
    }

    .freepager-next,
    .freepager-previous {
      font-size: 30px;
      position: absolute;
      top: 50%;
      bottom: 50%;
      height: auto;

      a {
        max-width: 40px;
        height: auto;

        span {
          line-height: 30px;
          padding: 5px 7px;
        }
      }
    }

    .freepager-next {
      right: 0;

      a {
        span {
          @include border-radius(3px,
            0,
            0,
            3px);
        }
      }
    }

    .freepager-previous {
      left: 0;

      a {
        span {
          @include border-radius(0,
            3px,
            3px,
            0);
        }
      }
    }

    .actions-wrapper {
      .fivestar-container {
        margin-top: -55px;
      }
    }
  }
  .actions-wrapper {
    position: relative;

    .count {
      font-size: 14px;
      margin-left: 2px;
    }

    .action-item {
      line-height: 45px; //padding: 15px 5px;
      margin: 5px 0; //background-color: transparent;
      z-index: 3;
      position: relative;

      i {
        font-size: 19px;
      }

      .vote-help {
        //margin-left: 15px;
        margin-top: 2px;

        i {
          font-size: 16px;
        }
      }
    }

    .rate-fivestar-btn-filled,
    .rate-fivestar-btn-empty {
      //width: auto;
      i {
        font-size: 100%;
      }
    }

    .fivestar-container {
      width: 100% !important;

      &.action-item {
        padding: 0; //margin: 0;
        border: none;
        margin: 0;
        z-index: 1;
      }
    }

    .vote_count {
      padding: 8px 10px;
      float: left;
      margin-left: 0;

      .count {
        font-size: 21px;
      }
    }

    .fivestar-wrapper {
      float: left;
      padding: 5px 0 5px 7px;

      &.community-show {
        //padding: 0;
        margin: 0;
        border: none;
        padding-top: 0;
      }
    }

    .rate-widget {
      width: 100% !important;
    }

    .vote-wrapper {
      &.results {
        clear: both;
        display: inline-block;
        margin-top: 2px;
        padding-top: 5px;
        border-top: solid 1px #222;
        width: 100%;
      }

      &.voting {
        //margin-top: -40px;
      }

      .rate-another {
        float: right;
        padding: 5px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .action-item.is-author {
      .vote-help {
        display: none;
      }

      .vote-wrapper.results {
        clear: none;
        width: auto;
        border: none;
      }
    }
  }
  .portfolio-item.full {
    .node-content-wrapper,
    .content .node-content,
    .content .comment-col,
    .comment-col .comment-wrapper {
      float: none;
      margin: 0;
      padding: 0;
      width: auto;
      border: none;
    }

    .userinfo {
      position: relative;

      h1.title {
        font-size: 22px !important;
        margin-left: -55px;
        padding: 5px 0;
      }

      .username {
        line-height: 1em;
        padding-top: 3px;

        .username {
          @include oneline();
          padding-right: 30px;
          width: 100%;
          line-height: 1em;
        }
      }

      .user-picture {
        width: 50px;
      }

      .user-details {
        margin-left: 55px;
      }

      .flag-wrapper {
        width: 30px;
        margin-left: 0;
        float: right;
        line-height: 25px;
        position: absolute;
        top: 0;
        right: 0;

        .text {
          display: none;
        }

        .follow-label,
        .following-label,
        .unfollow-label {
          min-width: 10px;
        }

        a:hover {
          .unfollow-label {
            display: none;
          }

          .follow-label,
          .following-label {
            display: block;
          }
        }

        i {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }
  .desktop-hide {
    display: block;
  }
  a.tab-trigger {
    //position: relative;
  }
  a.comment-link-trigger,
  a.tab-trigger {
    top: 5px;
    right: 5px;
  }
  .node-list.node-teaser {
    .node-inner {
      margin: 0 10px 10px 0; //position: relative;
    }
  }
  .view-photo-lists {
    .views-row {
      width: 50%;
    }
  }
  .node.full {
    .content-block {
      margin: 1em 0;
      width: 100%;
      float: none;
      display: block;
    }
  }
  /*   .node.ajax.featherlight-inner {
    padding: 10px;
  }
 */
  .backLink {
    display: none;
  }
  .view-id-popular_profiles2 {
    .views-row {
      width: 100%;
    }
  }
  .pagination {
    .item-list {
      ul.pager {
        li > a,
        li > span {
          padding: 0 13px;
          font-size: 12px;
        }

        li {
          &.pager-ellipsis,
          &.pager-first,
          &.pager-last {
            display: none;
          }

          &.pager-next a,
          &.pager-next span {
            border-right-width: 1px;
          }
        }
      }
    }
  }

  .view-user-comments .view-content {
    margin: 0;
  }
  .view-potm-achievement {
    .views-row {
      display: inline-block;
      vertical-align: top;
      width: auto;
      padding: 1em;
      margin: 0 auto;
    }

    .view-content {
      text-align: center;
    }

    /*     .view-content{
      h3{
        text-align: center;
        font-size: 4em;
        border-bottom: solid 1px #eee;
        margin: 1em 0 0.3em 0;
      }
      .views-field-field-achievement-month{
        text-align: center;
        text-transform: uppercase;
        font-size: 13px;
        color: $bright-blue;
        font-family: $header-family;
        font-weight: bold;
        padding: 5px;
      }
    } */
  }
  // @todo: should page error all be inline? or its own css?
  .page-error {
    &.no-header .logo {
      margin-top: 0;
      padding: 30px;
      max-width: 300px;
    }

    #content {
      padding: 0 2.5em;

      h1 {
        font-size: 28px;
      }

      h3 {
        font-size: 20px;
      }
    }
  }

  .node.node-contest {
    &.node-teaser {
      .content {
        margin-left: 0;
        float: left;
        width: 100%;
        clear: both;
        font-size: 15px;
        line-height: 1.3em;
        margin: 0;
        padding: 5px;
      }
    }

    &.full {
      .add-entry {
        a {
          padding: 0 2em;
        }
      }
    }

    &.node-header {
      height: auto;

      .node-inner {
        height: auto;
      }

      div.content {
        padding: 0;
        margin: 0;
        height: auto;
        position: relative;
        width: 100%;
        float: none;
      }

      .header-image {
        float: none;
        width: 100%;
        margin: 0;
        height: 200px;
      }

      .content-inner {
        padding: 10px;
        position: relative;

        h1 {
          font-size: 22px;
          padding: 0;
        }

        .field-name-field-dek {
          font-size: 16px;
          padding-top: 10px;
        }

        .field-name-field-contest-type {
          font-size: 13px;
        }

        .field-name-field-guest {
        }
      }

      .contest-info-status {
        display: none;
      }
    }
  }

  // @todo - what is sliding popup?
  #sliding-popup {
    .popup-content {
      font-size: 16px;
      padding-top: 1em;

      #popup-text {
        max-width: 100%; //padding-bottom: 1em;
        //ont-size: 12px;
      }

      #popup-buttons {
        clear: none;
        float: left;
        padding-top: 1em;

        a {
        }
      }
    }

    button {
      font-size: 18px;
    }
  }


  #user-cancel-confirm-form {
    .form-actions {
      #edit-cancel,
      input.form-submit {
        width: 100%;
        padding: 0 2em;
        line-height: 3em;
        display: inline-block;
      }
    }
  }
  body.page-user-cancel {
    .region-content {
      background-color: transparent;
    }
  }


  .profile-lists {
    .views-row {
      width: 100%;
      border-bottom: solid 1px #eee;
    }
  }
  .has-mobile-sidebar {
    #sidebar-first {
      display: none;
    }
  }

  .section-title {
    padding-top: 0; //
  }
  .view-activity-feed,
  .view-aggregate-activity {
    .view-empty {
      padding: 1em !important;
    }

    .view-content {
      padding-top: 0;
    }
  }
  #block-views-activity_feed-block_1 {
    padding-top: 0;
  }
  .jq-dropdown .jq-dropdown-menu {
    ul {
      padding: 1em;
    }

    li {
      font-size: 15px;
    }
  }

  .view-categories {
    .view-content {
      ul {
        column-count: 2;
      }
    }
  }
  .original-article {
    width: 80px;
  }
  .node .content .toc-tree {
    width: 100%;
    float: none;
    margin: 1em 0;
    max-width: 100%;
    display: block;
  }
  .view-taxonomy-term.view-display-id-block_1 {
    .view-content {
      display: block;
    }

    .row {
      border: none;
      padding: 10px 0;
      display: flex;
      //flex-direction: column;
      img {
        background-color: #eee;
      }

      .views-field-field-lead-image {
        flex: 1;
      }

      .views-field-title {
        flex: 2;

        h3 {
          margin: 2px 10px;
          font-size: 18px;
        }
      }
    }
  }

  #block-views-contests_list-block_2,
  .block-views-instream_features {
    padding: calc($padding / 2);
    margin: $padding calc(($padding / 2) * -1);
    background: #f7f7f7;
    border: none;

    .block-title {
      padding: calc($padding / 2) 0;
    }

    .view-content {
      .views-row {
        padding: calc($padding / 2) 0;
      }
    }
  }

  #block-views-contests_list-block_2 {
    background-color: #fff;

    .node.node-teaser {
      margin: 0;
      padding: 0;

      .lead-image {
        margin: 0 0 0.5rem 0;
      }
    }

  }

  .red {
    red: 0dda9ad9fadfadf
  }

  //

  .node-group.teaser {
    .field-name-field-lead-image {
      width: 100px;
      clear: none;
      float: left;
    }

    .content {
      margin-left: 100px;
    }
  }

  .node-group.node-teaser {
    margin: 0;
    //padding: 0.5em 0;
    // border-top: solid 1px #eee;
    /*.content {
      margin-left: 130px;
    }

    .field-name-field-lead-image {
      width: 120px;
      height: auto;
      max-width: 120px;
      float: left; // clear: both;
      img {
        width: 100%;
        height: auto;
        margin: auto;
      }
    }*/
  }


}

/* /end only screen and (max-width: 481px), only screen and (max-device-width: 481px) */

// Landscape phones.
@media only screen and (min-width: 500px) and (max-width: $mobile-max-width) {
  /* .view-categories{
    .view-content{
      ul {
        column-count: 2;
      }
    }
  } */
  .node.node-teaser {

    .lead-image {
      width: 100%;
      max-width: 180px;
      float: left;
      margin: 0;
    }

    .submitted {
      //padding-top: 0;
      //margin-top: 0;
    }

    .field-name-field-lead-video,
    .field-name-field-lead-image,
    .field-name-field-photo {
      //max-width: inherit;
      //width: 100%;
      //height: 180px;
      //overflow: hidden;
      /* img {
        width: auto;
        height: 180px;
        margin: 0;
        max-width: inherit;
        margin-left: -50px;
      } */
    }

    // &.node-product,
    &.node-contest,
    &.node-article {
      .content {
        margin-left: 190px;
        clear: none;
        float: none;
        width: auto;
        //font-size: 13px; //line-height: 1.4em;
        padding-top: 0;

        .field-name-body {
          clear: none;
          //display: none;
        }
      }
    }

    /*&.node-group{
      .content {
        margin-left: 130px;
      }

      .field-name-field-lead-image {
        width: 120px;
        height: auto;
        max-width: 120px;
        float: left; // clear: both;
        img {
          width: 100%;
          height: auto;
          margin: auto;
        }
      }
    }*/


  }
  /* .pn-nav {
    display: none;
  } */


}

/**
 * ==============
 * / End landscape phones
 */

/* @media screen and (max-width: 1024px){

  ul.icon-menu li .icon.open-admin{
    display: block;
  }
}
 */
