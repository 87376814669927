
h1, h2, h3, h4, h5, h6 {
  line-height: 1.3em;
}


h1 {
  font-size: 2.2em;
  font-weight: 300;
  padding: 0 0 0.5em;
  margin: 0;
}

h2 {
  font-size: 1.8em;
  font-weight: 300;
  margin-bottom: 0.75em;
}

h3 {
  font-size: 1.4em;
  margin-bottom: 1em;
}

h4 {
  font-size: 1.2em;
  margin-bottom: 0.8em;
}

h5 {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 0;
}

h6 {
  font-size: 1em;
  font-weight: bold;
}

/* Paragraph styles. */
p {
  margin: 0 0 1em 0;
}

/* List styles. */
ul, ol {
  margin-left: 0;
  padding-left: 0;
}

/* Table styles.*/
table {
  width: 99%;
}

tr.even,
tr.odd {
  td {
    padding: 10px 5px;
  }
}

tr.odd {
  background-color: white;
}

/* Code.*/
pre, code, tt {
  font: 1em "andale mono", "lucida console", monospace;
  line-height: 1.5;
}

pre {
  background-color: #efefef;
  display: block;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #aaaaaa;
}

/* List styles.*/
ul {
  list-style-type: disc;

  ul {
    list-style-type: circle;

    ul {
      list-style-type: square;

      ul {
        list-style-type: circle;
      }
    }
  }
}

ol {
  /*list-style-type:decimal ol,
  list-style-type:lower-alpha ol {
    list-style-type: decimal;
  }*/
}

/* Abbreviation.*/
abbr {
  border-bottom: 1px dotted #666666;
  cursor: help;
  white-space: nowrap;
}

/* UI improvments.*/
#edit-title {
  font-size: 24px;
  width: 99%;
}

#system-themes-form img {
  width: 100px;
}

.form-item .description {
  font-style: italic;
  line-height: 1.2em;
  font-size: 0.85em;
  margin-top: 5px;
  color: #777777;
}

#edit-delete {
  color: #cc0000;
}

/* Console, style from slate theme.*/
//div.messages
/*  padding: 9px
  margin: 1em 0
  color: #003366
  background: #bbddff
  border: 1px solid #aaccee
  text-align: left

//div.warning
  color: #884400
  background: #ffee66
  border-color: #eedd55

//div.error
  color: white
  background: #ee6633
  border-color: #dd5522

//div.status
  color: #336600
  background: #ccff88
  border-color: #bbee77*/

/* Region Colors. delete when starting a new theme.*/
//#header, #footer,
//#sidebar-first,
//#sidebar-second,
//#navigation
//background: rgba(#aaa, 0.2)

// Import custom styles.

.display-hidden{
  display: none;
}

@import '_ads';

@import '_navigation';

@import '_custom';

@import '_wide';
@import '_narrow';
@import '_small';

// legacy styles from WP
@import '_legacy';

// dropdown styles
@import '_dropdowns';

// profiles
//@import _profiles.scss

// checkout
//@import _checkout.scss

// gridzy
@import '_gridzy';


