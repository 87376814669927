@import "_config.sass";

#header {
  background-color: #fff;
  position: relative;
  margin-top: 0; //35px;
  float: left;
  width: 100%;
  border-bottom: none;
  border: none;




  /* div.inner {
     padding: 0 20px;
   }*/
  #logo {
    width: 110px;
    display: inline-block;
    margin: 0;
    padding: 0;
    flex: 0 0 auto;

    img {
      height: auto;
      max-width: 100%;
    }

    svg {
      vertical-align: middle;
    }
  }

  /*#header-region {
    width: 65%;
  }*/

  /*#right-header-top {
    display: none;
  }*/

  .jq-dropdown .jq-dropdown-menu,
  .jq-dropdown .jq-dropdown-panel {
    // border-radius: 1px;
  }

  #dropdown-cart_drop .jq-dropdown-panel {
    padding-bottom: 0;
  }

  #search-form {
    position: relative;
    margin-bottom: 1em;
  }

  .search-close{
    display: none;
  }

  .search-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #ccc;

    i {
      //vertical-align: bottom;
    }

    .fa-sync,
    .fa-sync-alt,
    .fa-refresh {
      display: none;
    }
  }

  .search {
    .search-submit {
      display: none;
    }

    #edit-t {
      margin: 0;
      vertical-align: middle;
      width: 100%;
      /* font-size: 16px; */
      padding: 0 35px 0 40px !important;
      line-height: 3em;
      height: 3em;
      border: 1px solid #eee;
      margin-top: 1px;
      border-radius: 100px;

      &:focus,
      &:active {
        border: solid 1px #ccc;
        background: #fff;
      }
    }

    /*.open {
      #edit-t {
        @include border-radius(3px, 3px, 0, 0); //border: solid 1px #eee;
      }
    }*/
  }
}


/* navigation
========================
*/

.main-menu-wrapper {
  flex: auto;
  z-index: 99999;
  //display: none;
  justify-content: flex-end;
  top: $nav-height;
  display: block;
  position: fixed;
  background-color: #fff;
  bottom: 0;
  overflow: auto;
  left: -100%;
  width: 100%;
  margin-top: 1px;
  @include transition(left .15s ease-out);
  padding: 20px 10px;
  //gap: 10px;
  //display: flex;
  //flex-direction: column-reverse;
  ul.menu {
    font-size: 20px;
    font-weight: bold;

    li {
      margin: 0;
    }

    ul.menu {
      font-size: 16px;
      font-weight: normal;

      li {
        margin: 0 0 0 1em;
      }
    }
  }
}

.main-menu-open {
  .main-menu-wrapper {
    left: 0;
  }
}

#navigation {
  // div.inner {
  //    display: block !important;
  // }
  /*#right-header {
    float: right;
  }*/

  li {
    list-style-type: none;
    vertical-align: middle;
  }

  width: 100%;
  z-index: 99; // @include box-shadow(0, 1px, 2px, 0px, rgba(0,0,0,0.4));
  position: relative;
  background: $header-bg-color; //$ui-dark;
  border-bottom: solid 1px #eee;
}

.navigation-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  height: $nav-height;
  padding: 0;
  gap: 0;
  max-width: 1600px;
}

#primary {
  a {
    //line-height: $nav-height;
    text-decoration: none;
    color: #111;
    display: block;
    // float: left;
    padding: 0.75em;

    &.active-trail,
    &.active,
    &:hover {
      //background: $ui-light;
      //background: #6E7880;
      color: $bright-blue;
    }
  }
}

.nav-container {
  width: 100%;
  height: $nav-height;
}

/*.sticky .nav-container nav,
.small-nav .nav-container nav {
  height: $nav-height;

  .inner {
    padding-top: 0;
  }
}*/


.small-nav,
.sticky {
  .navigation-inner {
    height: $nav-height;
  }

  #header {

    #logo {
      width: 110px;
    }
  }
  #navigation{
    @include box-shadow(0, -7px, 7px, 8px, rgba(0, 0, 0, 0.1));
    .user-menu {
      .user-picture {
        width: 35px;
      }
    }
  }
}


.sticky {
  #navigation {
    position: fixed;
    top: 0;
    z-index: 99999;


  }
}


//stickySecondary is the nav used for anchors on full products pages
.stickySecondary .fs-tabs-nav {
  position: fixed;
  top: 48px;
  z-index: 99998;
  border: none;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.9);
  left: 0;
  right: 0;
  width: 100%;

  ul {
    li {
      font-size: 16px;
    }

    li.active {
      a {
        background-position: center bottom -8px;
      }
    }
  }
}

/* myaccount
=======================
*/

#dropdown-user_menu_drop {
  ul.jq-dropdown-menu {
    min-width: 200px;
    font-size: 16px;
    padding: 1em;
  }

  .username {
    padding: 10px;
    font-weight: 500;
    font-size: 1.2em;
  }
}

ul.jq-dropdown-menu {
  position: relative;
  right: auto;
  top: auto;

  a.active {
    //display: none !important;
    font-weight: bold !important;
  }

  .icon {
    width: auto;
    height: inherit;
  }

  li i {
    width: 20px;
    text-align: center;
  }

  .flag-throbber {
    display: none;
  }
}


.jq-dropdown .worksafe-wrapper {
  padding: 0.5em;
  margin: 0;
  white-space: nowrap;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;

  form {
    display: inline-block;
  }

  span {
    font-weight: 500;
    margin-right: 1em;
  }
}

#navigation {
  .user-menu {
    padding: 0 0 0 10px;

    a.profile-link {
      height: 100%;
      //display: block;
      color: #999;
      text-decoration: none;
      font-weight: normal;
      //text-decoration: none;
      font-size: 12px;
      outline: none;
      display: inline-block;
      vertical-align: middle;

      &:hover,
      &.jq-dropdown-open {
        color: $light-grey;
      }

      .name-wrapper {
        display: none;
      }
    }

    .user-picture {
      width: 35px;
    }

    ul.jq-dropdown-menu {
      li {
        display: block;
      }
    }
  }
}

.login-links {
  color: #aaa;
  float: right;
  line-height: 40px;
  font-size: 12px;
  white-space: nowrap;
  padding-left: 10px;
  .login {
    padding-right: 10px;
    padding-left: 5px;
  }

  a,
  a:link {
    color: #666;
  }

  a.button {
    color: #fff;
    font-size: 1em;
    border-radius: 100px;
    font-weight: 500;
    text-transform: none;
  }
}


#user-menu {
  display: flex;
  align-items: center;
  flex: 1;
}

#navigation {
  .menu-trigger-wrapper {

    text-align: center;
    //padding: 0 7px 0 0;
    //border-right: solid 1px #ddd;
    //margin-right: 10px;
    height: 100%;

    a.icon {

      display: inline-block;
      float: left;
      font-size: 11px;
      color: #111;
      height: 100%;
      line-height: 48px;
      width: auto;
      padding-right: 5px;
    }
  }
}

#menu-trigger {
  .open {
    display: inline-block;
  }

  .close {
    display: none;
  }
}

.main-menu-open {
  #menu-trigger {
    .open {
      display: none;
    }

    .close {
      display: inline-block;
    }

    i {
      background-color: #666;
      text-shadow: none;
    }
  }
}

#navigation {
  a.icon {
    background-image: none;
    color: #ccc;
    display: block;
    height: 35px;
    line-height: 35px;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    width: 35px;
    font-size: 12px;

    &.open,
    &:hover {

      i {
        background-color: #666;
        text-shadow: none;
      }

      color: #666; //background-color: transparent;
    }

    &.jq-dropdown-open,
    &:hover.jq-dropdown-open,
    &:hover.open,
    &.open {
      i {
        background-color: $ui-dark;
        text-shadow: none;
      }

      color: $ui-dark; //background-color: #fff;
    }

    i {
      vertical-align: middle;
      background-color: #aaa;
      color: transparent;
      text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.4);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
    }

    /* i {
        line-height: 35px;
      } */
  }


}

#navigation #search-trigger {
  display: none;
}

ul.icon-menu {
  /*  float: left;
  display: block;
  vertical-align: middle;
  line-height: 40px; //$nav-height;
  color: $light-grey;*/
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  gap: 3px;

  /*a {
    color: #fff;
  }*/

  li {
    margin: 0;
    padding: 0;
    line-height: $nav-height;
    font-size: 11px;
    position: relative;

    a {
      color: #fff;
      text-decoration: none;

      &:active,
      &:focus {
        outline: none;
      }
    }


    .open-admin {
      display: none;
    }

    .my-pm,
    .my-alerts {
      text-indent: inherit;
      position: relative;
    }

    .unread {
      background: #ff0000;
      padding: 0 3px;
      line-height: 1.3em;
      font-weight: bold;
      position: absolute;
      top: 2px;
      right: 0;
      color: #fff;
      /* border-radius: 20px;
        -moz-border-radius: 2px 2px 2px 2px; */
      /* -webkit-border-top-left-radius: 2px; */
      /* -webkit-border-top-right-radius: 2px; */
      /* -webkit-border-bottom-right-radius: 2px; */
      /* -webkit-border-bottom-left-radius: 2px; */
      font-size: 11px;
      text-align: center;
      min-width: 15px;
      @include border-radius(20px);
    }
  }

  .drop-contents {
    //width: 100%;
    width: 420px;
    line-height: normal;
    font-size: 12px;
    max-height: 500px;
    overflow-y: auto;
    position: relative; //z-index: 99999;
  }

  .dropdown-header {
    text-align: left;
    padding: 0 10px;
    font-size: 14px;
    line-height: 25px;
    border-bottom: solid 1px #eee;
    color: #111;

    .links {
      float: right;
      font-weight: normal;
      font-size: 12px;

      a {
        color: $bright-blue !important;
        text-decoration: underline !important;
      }
    }
  }

  .jq-dropdown .jq-dropdown-panel {
    //padding: 4px 0;
    //max-width: 450px;
  }
}

ul.icon-menu li .dropdown-footer {
  text-align: center;
  border-top: solid 1px #eee;
  padding: 10px;
  font-size: 14px;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 30px;

  a {
    color: $bright-blue; // !important;
    font-weight: bold;
    letter-spacing: normal;
  }

  .button-wrapper {
    float: left;
    width: 50%;
    text-align: center;
    padding: 0 5px 0 0;

    &.button-wrapper-2 {
      padding: 0 0 0 5px;
    }

    &.button-wrapper-full {
      width: 100%;
      padding: 0 5px 0 5px;
    }

    a {
      background-color: $bright-blue;
      color: #fff; // !important;
      display: block !important;
      text-decoration: none;
      font-weight: 600;
      font-family: $header-family;
      padding: 10px 10px;
      border: solid 1px $bright-blue;
      border-radius: 3px;
      text-transform: uppercase;
      font-size: 16px;

      &.cart {
        background-color: $pale-blue; //#fff;
        color: $bright-blue; // !important;
        &:hover {
          background-color: $bright-blue;
          color: #fff;
        }
      }

      &.checkout,
      &.payment {
        border-color: $green;
        background-color: $green;
        color: #fff; // !important;
        &:hover {
          background-color: $dark-green;
        }
      }
    }
  }
}

.drop-contents {
  .empty-cart {
    text-align: center;
    font-size: 18px;
    padding: 40px 20px;
    color: #666;
  }

  table {
    @include no-rollovers();
  }

  .notifications-wrapper {
    line-height: normal; //height: 400px;
    //overflow: auto;
    color: #363636;

    a,
    a:link,
    a:visited {
      color: $off-black !important; //font-weight: bold;
    }

    .views-row {
      padding: 0; //5px;
    }

    b,
    strong {
      color: $off-black;
    }
  }

  .pm,
  .entity-message {
    padding: 7px 10px; //border-bottom: solid 1px #eee;
    font-size: 13px;
    line-height: 1.3em;

    .unread {
      left: 0 !important;
    }
  }

  .entity-message {
    padding: 0;

    .message-link {
      padding: 7px 10px;
    }
  }

  .more-link {
    display: none;
  }
}

.menu-dropdown {
  width: 400px;
  max-height: 700px;
  position: absolute;
  right: 50px;
  top: 35px;
  background: #fff;
  @include box-shadow(0, 2px, 3px, 0, rgba(0, 0, 0, 0.1));
  border: solid 1px #eee;
  border-top: none;
  display: none;

  .loading {
    text-align: center;
    display: block;
    margin: 1em auto;
  }

  &.open {
    display: block;
  }

  .notifications-wrapper {
    line-height: normal; //height: 400px;
    //overflow: auto;
    color: #363636;

    a,
    a:link,
    a:visited {
      //color: $off-black;
      //font-weight: bold;
    }

    .views-row {
      padding: 0;
    }

    b,
    strong {
      color: $off-black;
    }
  }

  .pm,
  .entity-message {
    padding: 5px;
    border-bottom: solid 1px #eee;

    .unread {
      left: 0 !important;
    }
  }

  .more-link {
    display: none;
  }

  .drop-footer {
    height: 30px;
    border-top: solid 1px #ccc;
    line-height: normal;
    text-align: center;
    font-weight: bold;
    padding-top: 5px;

    a {
      color: $bright-blue;
      line-height: normal;
    }
  }

  .drop-header {
    height: 25px;
    border-bottom: solid 1px #ccc;
    line-height: 25px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    color: $off-black;
    font-weight: bold;

    .links {
      float: right;
      font-weight: normal;
      color: #ccc;
    }
  }
}

// dropdown cart
ul.icon-menu li.ajax-shopping-cart-teaser {
  .drop-contents {
    //height: auto;
  }
}

.view-shopping-cart-summary2.view-display-id-dropdown {
  padding: 0 15px;

  .views-row {
    padding: 10px 0;
  }

  .view-footer {
    td.component-total {
      text-align: right;
    }
  }

  .views-field-commerce-total {
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
    float: right;
  }

  .views-field-quantity {
    padding-left: 0;
    padding-top: 2px;
  }

  .views-field-rendered-entity {
    float: left;
    display: block;
    width: 75%;

    .node {
      padding: 0;

      div.inner {
        min-width: 0 !important;
        padding-left: 0 !important;
      }
    }

    .node-product.preview {
      .title-wrapper {
        font-size: 11px;
        padding-left: 10px;

        .title {
          font-size: 15px;
        }

        .subtitle {
          font-size: 13px;
          color: #999;
        }

        .instructor {
          font-size: 11px;
          display: none;
        }
      }
    }
  }
}

/* #navigation .node-product.teaser {
  max-width: none !important;
}
 */

// @todo, indropdown?
.jq-dropdown.top-nav-dropdown {
  //top: 43px !important;
  position: relative; //absolute;
  bottom: 0;
  border: none;
  left: 0;
  right: 0;
  margin: 0;


  &.jq-dropdown-tip:after {
    display: none !important;
  }

  .jq-dropdown-menu,
  .jq-dropdown-panel {
//    margin-top: 1px;
    padding: 0;
    max-width: none;
    border: none;
    position: fixed;
    top: $nav-height;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    @include box-shadow(none);
    @include border-radius(0);
  }

  .jq-dropdown-menu {
    //padding: 1em;
    // font-size: 18px;
  }

  .dropdown-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .dropdown-header {
    height: 30px;
    font-size: 14px;
    text-align: left;
    background: #fff;
    padding: 0 0.5em;
    color: $off-black;
    border-bottom: solid 1px #eee;
    line-height: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;

    .links {
      float: none;
      font-weight: normal;
      width: 100%;
      margin-left: 40%;
      text-align: right;
      display: inline;
      position: absolute;
      right: 10px;
      font-size: 12px;

      a {
        height: auto;
        width: auto;
        display: inline; //color: #ddd !important;
      }
    }
  }

  .drop-contents {
    width: 100%;
    max-width: none;
    text-align: left;
    max-height: none;
    position: absolute;
    bottom: 0;
    height: auto; //overflow: visible;
    border-top: solid 30px #fff;
    border-bottom: solid 50px #fff;
    overflow-y: scroll;
    /*has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    top: 0;

    .notifications-wrapper {
      display: block;
    }
  }
}


#dropdown-cart_drop {
  .drop-contents {
    border-bottom: solid 73px #fff;

    .node-product.preview {
      .catalog-image {
        width: 60px;
      }

      .title-wrapper {
        margin-left: 60px;
      }
    }
  }
}


/*
// Landscape Phone
@media only screen and (min-width: 500px) and (max-width: $mobile-max-width) {
  .small-nav #navigation #logo,
  .sticky #navigation #logo {
    padding-left: 5px !important;
  }
}

*/

// Narrow (tablet)
@media only screen and (min-width: $mobile-max-width) {

  #header .search-close {
    display: block;
  }
  #navigation {
    a.icon.open-admin {
      display: none;
    }

    #search-trigger {
      display: block;
    }

    .search-close {
      position: absolute;
      right: 1em;
      line-height: 2.7em;
    }

    .main-menu-wrapper {
      .search {
        display: none;
        flex: auto;
        text-align: right;
      }

      .menu-element {
        display: block;
        flex: auto;
      }
    }

    &.search-open {
      #search-trigger {
        display: none;
      }

      .main-menu-wrapper {
        .search {
          display: block;
        }

        .menu-element {
          display: none;
        }
      }
    }


  }


  .main-menu-wrapper {
    display: flex;
    position: revert;
    background: none;
    flex: auto;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    padding: 0;
    padding-left: 0.5em;

    ul.menu {
      font-size: 14px;
    }
  }

  .small-nav,
  .sticky {

  }
  .menu-trigger-wrapper {
    display: none;
  }

  #header {
    #search-form {
      margin-bottom: 0;
      display: inline-block;
      width: 100%;
      max-width: 320px;
    }

    .search {
      #edit-t {
        font-size: 14px;
        line-height: 2.85em;
        height: 2.85em;
      }
    }
  }
  #navigation #primary.nested-dropdowns {
    > .menu {
      > li {
        .menu {
          display: none;
        }
      }
    }
  }
  #primary.nested-dropdowns {
    > .menu {
      display: block;

      > li {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
      }
    }
  }

  .jq-dropdown.jq-dropdown-tip:before {
    border-bottom-color: #fff;
  }
  .jq-dropdown.top-nav-dropdown {
    position: absolute;
    bottom: initial;
    border: none;
    left: initial;
    right: initial;
    margin: 0;
    top: initial;
    z-index: 99999;
    border: solid 1px #eee;
    margin-top: 9px;

    @include box-shadow(0, 20px, 50px, 0px, rgba(0, 0, 0, 0.3));

    .jq-dropdown-menu,
    .jq-dropdown-panel {
      position: relative;
      right: auto;
      left: auto;
      top: auto;
    }

    .drop-contents {
      width: 420px;
      line-height: normal;
      font-size: 12px;
      max-height: 500px;
      overflow-y: auto;
      position: relative;
      /*&::-webkit-scrollbar {
        display: block;
      }*/
    }

    .dropdown-footer {
      position: absolute;
    }
  }


  .sticky,
  .small-nav {
    .navigation-inner {
      height: $nav-height;
    }

    #primary {
      a {
        padding: 1em 0.5em;
        line-height: 1em;
      }
    }

    .nav-container {
      height: $nav-height;
    }

    .main-menu-wrapper {
      ul.menu {
        font-size: 14px;
      }
    }

  }
}

// Tablet 2
@media only screen and (min-width: $tablet-max-width) {

  .main-menu-wrapper {
    ul.menu {
      font-size: 16px;
    }
  }



  #navigation #primary.nested-dropdowns {


    > .menu {
      display: block;

      > li {
        .menu {
          position: absolute;
          text-align: left;
          background-color: #fff;
          padding: 15px;
          //margin-top: 2em;
          border: solid 1px #eee;
          //top: 100%;
          //right: -99999px;
          border-top: solid 1px transparent;
          font-size: 14px;
          display: none;
          box-shadow: 0 0 20px -10px rgba(0, 0, 0, .3);
          top: 100%;
          //opacity: 0;
          //@include box-shadow(0, 3px, 1px, 0px, rgba(0, 0, 0, 0.3));
          //@include transition(opacity .25s ease-out);
          &:before {
            position: absolute;
            top: -14px;
            left: 12px;
            content: "";
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid #fff;
            display: inline-block;
          }

          li {
            //font-size: 18px;
            display: block;
            float: none;
            margin: 0;
            padding: 0;

            a {
              line-height: normal;
              padding: 0.5em 0;
              float: none;
              display: block;
            }
          }
        }

        &:hover {

          .menu {
            display: block;
            //opacity: 1;
            right: auto;
            min-width: 200px;
          }
        }
      }

    }

  }

}

// Desktop
@media only screen and (min-width: $max-width) {
  .main-menu-wrapper {
    ul.menu {
      font-size: 18px;
    }
  }

  .navigation-inner {
    height: $nav-height-wide;
  }

  #primary {
    a {
      padding: 1.7em 0.5em;
      line-height: 1em;
    }
  }

  #navigation {
    .user-menu {
      .user-picture {
        width: 45px;
      }
    }

    .search {
      flex: auto;
      text-align: right;
    }

    .menu-element {
      padding-left: 20px;
    }
  }


  .nav-container {
    height: $nav-height-wide;
  }


  #header {
    #logo {
      width: 250px;
    }
  }
  .sticky,
  .small-nav {
    .main-menu-wrapper {
      ul.menu {
        font-size: 16px;
      }
    }

  }


}
// Wide
@media only screen and (min-width: $wide-min-width + 100px) {
  #primary {
    a {
      padding:2em .5em 1.5em;
      line-height: 1.6em;
    }
  }
  .main-menu-wrapper {
    ul.menu {
      font-size: 21px;
    }
  }

  #navigation{
    .search-close,
    #search-trigger{
      display: none !important;
    }
    .main-menu-wrapper{
      .search{
        display: block !important;
      }
      .menu-element{
        display: block !important;
      }

    }

  }
}
