.gridzy-container {
  clear: both;
  width: 100%;
  position: relative;
  &>* {
    position: relative;
  }
}
.gridzyItem .node{
  width: 100%;
}
/* .gridzy-container {
  display: block;
  will-change: width, height;
} */
/* Hint for browsers to make animations even smoother */

/* .gridzy-container .gridzyItem {
  will-change: left, top, width, height, opacity, transform;
} */
/* Prevent initial visibility of items */

/* .gridzy-view .views-row:not(.gridzyItem),
.gridzy-field>.field-item:not(.gridzyItem) {
  visibility: hidden;
} */

.gridzy {
  .photo-wrapper,
  .file {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: auto; //100%;
      max-height: 100%;
    }
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: #eee;
    }
  }
  .gallery_item_uncropped {
    margin: 0 !important;
  }
  .view-header {
    clear: both;
    padding: 10px 0;
  }
  .views-field {
    padding: 0;
  }
  .view-content {
    float: none;
  }
  .gridzyItem>div {
    height: 100%;
    display: block;
    float: none;
    img {
      vertical-align: middle;
    }
  }
}

.gridzy-view:not(.gridzy-loaded){
  .load-more-wrapper{
    visibility: hidden;
  }
}

/* .view-activity-feed .view-content .item-list,
.view-aggregate-activity .view-content .row,
.view-aggregate-activity .view-content .item-list */
